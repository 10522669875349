import { Component, OnInit } from '@angular/core';
import {flyInAnimation, flyOutAnimation, flyUpAnimation} from '@app/shared/animations/animation';

@Component({
  selector: 'app-news-and-activity',
  templateUrl: './news-and-activity.component.html',
  styleUrls: ['./news-and-activity.component.scss'],
  animations: [flyInAnimation]
})
export class NewsAndActivityComponent implements OnInit {

  title = 'ข่าวสารและกิจกรรม';

  constructor() { }

  ngOnInit(): void {

  }

}
