import { Observable } from 'rxjs/Observable';
import Swal from 'sweetalert2';
import {DatePipe} from '@angular/common';
import {environment} from '../../environments/environment';


export function composeDateTime(dateStr: string, minute: number): Date {
    // Format is 'yyyy-MM-ddTHH:mm:ss'
    var str = dateStr + "T" + minuteToTime(minute) + ":00";
    return new Date(str);
}

export function timeToMinute(time: string): number {
    // var hms = '02:04';   // your input string
    var a = time.split(':'); // split it at the colons
    // minutes are worth 60 seconds. Hours are worth 60 minutes.
    var seconds = (+a[0]) * 60 * 60 + (+a[1]) * 60;

    var minute = seconds / 60;
    return minute;
}

export function minuteToTime(min: number): string {
    var scrapMinute = min % 60;
    var hour = (min - scrapMinute) / 60;
    var minuteString = ""
    var hourString = ""
    if (scrapMinute < 10) {
        minuteString = "0" + scrapMinute.toString();
    }
    else {
        minuteString = scrapMinute.toString();
    }
    if (hour < 10) {
        hourString = "0" + hour.toString();
    }
    else {
        hourString = hour.toString();
    }
    return hourString + ":" + minuteString;
}

export function formatDate(dateStr: string) {
    let d = new Date(dateStr);
    let monthVal = (d.getMonth() + 1);
    let monthStr = (monthVal < 10 ? `0${monthVal}` : monthVal);
    return `${d.getDate()}/${monthStr}/${d.getFullYear()}`;
}

export function numberToTimeLabel(time: number): string {
   // ex. 600 to 10:00
   return null;
}

export function success(msg: string, title: string = "Success", confirmButtonText: string = "OK", confirmBlock: () => void = null, cancelBlock: () => void = null) {
    Swal.fire({
        title : title,
        html : msg,
        icon : "success",
        didClose : confirmBlock
    });
    // (<any>swal)({
    //     title: title,
    //     text: msg,
    //     type: "success",
    //     buttons: (confirmBlock == null ? {
    //         confirm: {
    //             text: confirmButtonText,
    //             value: "confirm"
    //         }
    //     } : true),
    //     confirmButtonText: confirmButtonText
    // })
    // .then((value: string) => {
    //     if (value == "confirm") {
    //         if (confirmBlock != null) {
    //             confirmBlock();
    //         }
    //     } else {
    //         if (cancelBlock != null) {
    //             cancelBlock();
    //         }
    //     }
    // });
}

export function successWithCompleteBlock(msg: string, completedBlock: () => void = null) {
    (<any>swal)({
        title: "Success",
        text: msg,
        type: "success",
        buttons: {
            confirm: {
                text: "OK",
                value: "confirm"
            }
        },
        confirmButtonText: "OK"
    })
    .then((value: string) => {
        if (completedBlock != null) {
            completedBlock();
        }
    });
}

export function error(msg: string, title: string = "Warning!",confirmBlock: () => void = null) {


    Swal.fire({
        title : title,
        html : msg,
        icon : "error",
        didClose : confirmBlock
    });
    // (<any>swal)({
    //     title: title,
    //     text: msg,
    //     type: "warning",
    //     buttons: (confirmBlock == null ? {
    //         confirm: {
    //             text: confirmButtonText,
    //             value: "confirm"
    //         }
    //     } : true),
    //     confirmButtonText: confirmButtonText
    // })
    // .then((value: string) => {
    //     if (value == "confirm") {
    //         if (confirmBlock != null) {
    //             confirmBlock();
    //         }
    //     } else {
    //         if (cancelBlock != null) {
    //             cancelBlock();
    //         }
    //     }
    // });
}

export function warningWithCompleteBlock(msg: string, completedBlock: () => void = null) {
    (<any>swal)({
        title: "Warning",
        text: msg,
        type: "warning",
        buttons: {
            confirm: {
                text: "OK",
                value: "confirm"
            }
        },
        confirmButtonText: "OK"
    })
    .then((value: string) => {
        if (completedBlock != null) {
            completedBlock();
        }
    });
}

export function info(msg: string, title: string = "Info", confirmButtonText: string = "OK", cancelButtonText: string = "Cancel", confirmBlock: () => void = null, cancelBlock: () => void = null) {


    Swal.fire(
        title,
        msg,
        'info'
    );


    // (<any>swal)({
    //     title: title,
    //     text: msg,
    //     type: "warning",
    //     buttons: {
    //         confirm: confirmButtonText,
    //         cancel: cancelButtonText
    //     }
    // })
    // .then((value: string) => {
    //     if (value == "confirm" || value) {
    //         if (confirmBlock != null) {
    //             confirmBlock();
    //         }
    //     } else {
    //         if (cancelBlock != null) {
    //             cancelBlock();
    //         }
    //     }
    // });
}



export function infoWithCallBack(msg: string, title: string = "Info", callback: () => void) {


    Swal.fire(
        title,
        msg,
        'info'
    ).then(value => {
        callback();
    });


    // (<any>swal)({
    //     title: title,
    //     text: msg,
    //     type: "warning",
    //     buttons: {
    //         confirm: confirmButtonText,
    //         cancel: cancelButtonText
    //     }
    // })
    // .then((value: string) => {
    //     if (value == "confirm" || value) {
    //         if (confirmBlock != null) {
    //             confirmBlock();
    //         }
    //     } else {
    //         if (cancelBlock != null) {
    //             cancelBlock();
    //         }
    //     }
    // });
}

export function confirmDeleteDialog(yourType: string, onDelete: () => void = null) {
    warningPosNeg(`Are you sure you want to delete ${yourType}?`, "Confirm deletion", onDelete, null, "Delete", "Cancel", "bg-danger", "bg-light-gray");
}


export function warningYesNo(msg: string,
                             positiveBlock: () => void = null, negativeBlock: () => void = null) {
    warningPosNeg(msg, "Warning!", positiveBlock, negativeBlock, "Yes", "No");
}

export function warningPosNeg(msg: string, title: string = "Warning!",
                              positiveBlock: () => void = null, negativeBlock: () => void = null,
                              positiveBtnText: string = "Yes", negativeBtnText: string = "No",
                              positiveClassName: string = "", negativeClassName: string = "bg-light-gray") {
    (<any>swal)({
        title: title,
        text: msg,
        type: "warning",
        buttons: {
            yes: {
                text: positiveBtnText,
                value: "confirm",
                className: positiveClassName
            },
            no: {
                text: negativeBtnText,
                value: "negative",
                className: negativeClassName
            }
        }
    })
        .then((value: string) => {
            if (value == "confirm") {
                if (positiveBlock != null) {
                    positiveBlock();
                }
            }
            else {
                if (negativeBlock != null) {
                    negativeBlock();
                }
            }
        });
}

export function formatMins(totalMins: number): string {
  let mins = totalMins % 60;
  let hours = (totalMins - mins) / 60;

  let hoursSuffix = (hours > 1 ? "Hours" : "Hour");
  let minsSuffix = (mins > 1 ? "Minutes" : "Minute");

  if (hours == 0 && mins == 0) {
      return "0 Hour";
  }
  else if (hours == 0 && mins > 0) {
      return mins + ` ${minsSuffix}`;
  }
  else {
    return (hours + ` ${hoursSuffix} `) + (mins > 0 ? (mins + ` ${minsSuffix}`) : "");
  }
}

export function beginOfDayForDateTime(d: Date = null) {
    if (d == null)
      d = new Date();

    d.setUTCHours(0, 0, 0, 0);
    return d;
}

export function sameDate(d1: Date, d2: Date): boolean {
    if (d1 == null || d2 == null) {
        return false;
    }

    if (d1.getUTCDate() == d2.getUTCDate() && d1.getUTCMonth() == d2.getUTCMonth() && d1.getUTCFullYear() == d2.getUTCFullYear()) {
        return true;
    }

    return false;
}

export function shuffleInPlace<T>(array: T[]): T[] {
    // if it's 1 or 0 items, just return
    if (array.length <= 1) return array;

    let aLength = array.length;

    // For each index in array
    for (let i = 0; i < aLength; i++) {

      // choose a random not-yet-placed item to place there
      // must be an item AFTER the current item, because the stuff
      // before has all already been placed
      // Random between [0, array.length - 1]
      const randomChoiceIndex = Math.floor(Math.random() * (array.length - 1));

      // place our random choice in the spot by swapping
      swap(array, i, randomChoiceIndex);
    }
    return array;
}

function swap<T>(array: T[], idx1: number, idx2: number) {
    if (idx1 == idx2) {
        return;
    }

    let tmp = array[idx1];
    array[idx1] = array[idx2];
    array[idx2] = tmp;
}



export function b64toBlob(b64Data: string, contentType = '', sliceSize = 512): Blob {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
}

export function fixCORSUrl(url: string): string {
    return url.replace(environment.proflinkUrl, environment.baseUrl);
}
