import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header-with-back',
  templateUrl: './header-with-back.component.html',
  styleUrls: ['./header-with-back.component.scss']
})
export class HeaderWithBackComponent implements OnInit {

  @Input() title: string;
  @Input() path: string;
  @Input() queryParams: string;

  constructor(private router: Router) { }

  ngOnInit(): void { }

  back(): void {
    if (this.queryParams) {
      this.router.navigate([this.path], { queryParams: JSON.parse(this.queryParams)});
    } else {
      this.router.navigate([this.path]);
    }
  }

}
