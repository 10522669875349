<div class="mb-3">
    <div class="d-flex justify-content-between align-items-center">
        <div class="pl-2 pl-lg-3">
            <label class="title-headline-box">ข่าวสารและกิจกรรม</label>
        </div>
        <!-- <a *ngIf="newsFeeds.length > 1" class="see-more-size" (click)="seeAllNewsHighlight()">ดูทั้งหมด ></a> -->
    </div>
    <div class="d-flex justify-content-center">
        <div *ngIf="newsFeeds.length == 0">
            <div class="card custom-card text-center">
                <div class="card-body"><label>ไม่มีข่าวสารและกิจกรรม</label></div>
            </div>
        </div>
        <div [ngClass]="{'card custom-card mb-3': newsFeeds.length > 0}">
        <ngb-carousel #carouselNewFeeds id="newFeesId">
                <ng-template ngbSlide *ngFor="let newFeed of newsFeeds">
                    <a class="news-container" target="_blank" [href]="newFeed.url">
                        <img class="news-image" [src]="getDownloadLink(newFeed)"
                            onerror="this.src='../../../../assets/image/bg-gray.png'" [alt]="newFeed.title">
                        <label class="news-capture">{{newFeed.title}}</label>
                    </a>
                </ng-template>
            </ngb-carousel>
        </div>
    
    </div>
</div>