<div class="modal-body">
    <div class="text-center pt-3">
        <img src="assets/image/thai_national_id_card.png" alt="BehindIDCard" class="mx-auto img-fluid" style="max-width: 50%;" >
        <div class="text-left pl-4">
            <p class="title-custom pt-2 pl-3">วิธีการกรอกชื่อกลาง</p>
            <div class=" detail-custom pl-4">
                1. ไม่ระบุชื่อเล่น
            </div>

            <div class="detail-custom pl-4">
                2. ระบุชื่อที่แจ้งตามบัตรประชาชน เช่น นาย สมชาย ใจดี มีสุข ระบุชื่อกลางคือ <b>ใจดี</b>
            </div>
        </div>
    </div>
    <div class="form-group pt-3 pb-3 text-center">
        <button type="button" class="btn btn-outline-primary btn-next" style="width: 100px;"
                (click)="activeModal.close('Close click')"><h4 class="m-0">ตกลง</h4>
        </button>
    </div>
</div>
