<div class="col-12 m-0 text-center">
    <label class="register-info">ระบบได้ส่งลิงค์ยืนยันอีเมลไปที่ {{email}}</label>
</div>

<div class="col-12 m-0 text-center pt-4">
    <div>
        <label class="register-normal">หากท่านยืนยันอีเมลเรียบร้อยแล้วให้กดปุ่ม "เข้าสู่ระบบ"</label>
    </div>
</div>

<div class="d-flex d-inline justify-content-center">
    <div class="p-2">
        <button (click)="redirectToLogin()" class="btn btn-primary btn-email pl-2 pr-2">เข้าสู่ระบบ</button>
    </div>
</div>
<br>
<br>
<div class="col-12 m-0 text-center pt-6">
    <div>
        <label class="register-normal">กรณีท่านยังไม่ได้รับอีเมล กรุณากดปุ่ม "ส่งอีเมลอีกครั้ง" </label>
    </div>
</div>
<div class="d-flex d-inline justify-content-center">
    <div class="p-2">
        <button (click)="resendEmail()" class="btn btn-primary btn-email pl-2 pr-2">ส่งอีเมลอีกครั้ง</button>
    </div>
</div>