<div class="modal-body">
  <div class="text-center pt-3">
      <img src="assets/icons/info-circle.svg" alt="info-circle" class="mx-auto img-fluid" style="max-width: 50%;" >
      <p class="my-3">e-learning ห้องเรียนผู้ประกอบวิชาชีพ เป็นช่องทางการเรียนรู้เพื่อเพิ่มพูนทักษะความรู้</p>
      <div class="text-danger font-weight-bold">“ไม่สามารถใช้นับชั่วโมงต่ออายุใบอนุญาตฯ ได้”</div>
  </div>
  <div class="form-group pt-3 pb-3 text-center">
      <button type="button" class="btn btn-outline-primary btn-next" style="width: 100px;"
          (click)="activeModal.close(true)"><h4 class="m-0">รับทราบ</h4>
      </button>
  </div>
</div>