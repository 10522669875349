import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import * as WSCall from '@app/utils/WSCall';
import {ConsentVersionPublicControllerService} from '@proflink/prof-link-web-ic-api-ts-angular/api/consentVersionPublicController.service';
import {b64toBlob} from '@app/utils/Utils';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {ConsentVersionModel} from '@proflink/prof-link-web-ic-api-ts-angular/model/consentVersionModel';


@Component({
  selector: 'app-share-register-consent',
  templateUrl: './share-register-consent.component.html',
  styleUrls: ['./share-register-consent.component.scss']
})


export class ShareRegisterConsentComponent implements OnInit,OnDestroy,AfterViewInit {

  iframe:any;
  iframeId:string = 'iframePdf';
  defaultHeight:number = 100;

  @ViewChild('pdfViewerAutoLoad') pdfViewerAutoLoad: {
    pdfSrc: Blob;
    refresh: () => void;
    iframe : ElementRef;
  };

  intervalIframe:any;
  containerViewer:any;
  pdfUrl: SafeResourceUrl;
  consentNo:string = "";

  constructor(
      private sanitizer: DomSanitizer,
      private _consentVersionPublicControllerService: ConsentVersionPublicControllerService,

  ) {


  }

  ngOnInit(): void {


    this.containerViewer = document.getElementById("");




  }


  ngAfterViewInit() {

    this.containerViewer = document.getElementById("containerView");
    this.loadPdf();
    this.loadConsentLatest();

    let _this = this;
    _this.intervalIframe = setInterval(async () => {

      this.detectContentPdf(_this);

    }, 1000);

  }



  async loadConsentLatest(){

    let consentVersionModel:ConsentVersionModel = await this.getLatestConsentVersionAppDescription();
    this.consentNo = consentVersionModel.consentVersionNo;
    console.log(this.consentNo);

  }


  async loadPdf(){
    let consentPdf:string = await this.getLatestConsentVersionAppBase64PDF();
    this.pdfUrl = this.toSecureURLPdfStr(consentPdf);
    // this.pdfUrl = this.toSecureURLPdfStr(this.data);
    this._consentVersionPublicControllerService.getLatestConsentVersionAppBase64PDFUsingGET().subscribe(data => {
      this.pdfViewerAutoLoad.pdfSrc = b64toBlob(data.data, 'application/pdf'); // pdfSrc can be Blob or Uint8Array
      this.pdfViewerAutoLoad.refresh();

      this.iframe = this.pdfViewerAutoLoad.iframe.nativeElement;
      this.iframe.id = this.iframeId;

    });
  }

  toSecureURLPdfStr(data: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(
        "data:application/pdf;base64," + data
    );
  }

  async getLatestConsentVersionAppDescription(): Promise<ConsentVersionModel> {
    return WSCall.wsCall(
        () => {
          return this._consentVersionPublicControllerService.getLatestConsentVersionAppDescriptionUsingGET()
        },
        respBody => {
          return respBody.data;
        }
    );
  }

  async getLatestConsentVersionAppBase64PDF(): Promise<string> {
    return WSCall.wsCall(
        () => {
          return this._consentVersionPublicControllerService.getLatestConsentVersionAppBase64PDFUsingGET();
        },
        respBody => {
          return respBody.data;
        }
    );
  }

  detectContentPdf(_this:any){


    let tempIframe:any = document.getElementById(_this.iframeId);
    if(tempIframe == null){
      return;
    }

    let innerDoc:any = tempIframe.contentDocument;
    if(innerDoc == null){
      return;
    }

    let viewer = innerDoc.getElementById("viewer");

    if(viewer == null){
      return;
    }

    _this.addCssPdf(innerDoc);

    let sizeViewer:number = viewer.childNodes.length;

    console.log("size viewer");
    console.log(sizeViewer);
    if(sizeViewer > 0){

      let childNode:any = viewer.childNodes[0];
      let strHeight = childNode.style.height;

      strHeight = strHeight.replace("px","");
      let pageHeight = parseInt(strHeight);
      let heightContainer:number = (pageHeight*sizeViewer)+200;

      _this.containerViewer.style.height = heightContainer+'px';

      clearInterval(_this.intervalIframe);
    }

  }

  addCssPdf(innerDoc:any){

    let tl:any = innerDoc.getElementsByClassName("toolbar");
    if(tl != null && tl.length > 0){
      tl[0].style.display = 'none';
    }


  }

  ngOnDestroy(): void {
    if (this.intervalIframe) {
      clearInterval(this.intervalIframe);
    }
  }


}
