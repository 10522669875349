<div class="row text-center custom-input pt-3 m-1">

    <div class="col-sm-12">
        <div class="form-group text-center">
            <div *ngIf="showVideo" class="text-center video-custom">
                <div id="player" class="embed-responsive embed-responsive-16by9"></div>
            </div>
        </div>
    </div>

    <div class="col-12 pt-4">
        <div class="form-group text-center">
            <button type="button" class="btn btn-block btn-primary btn-next" (click)="next()">
                ถัดไป
            </button>
        </div>
    </div>
</div>


