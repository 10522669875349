import { Component, Input, OnInit } from '@angular/core';
import {CourseResponse} from '@proflink/prof-link-web-ic-api-ts-angular/model/courseResponse';
import {IcProfileControllerService} from '@proflink/prof-link-web-ic-api-ts-angular/api/icProfileController.service';
import {IcLicenseSummaryV2Result} from '@proflink/prof-link-web-ic-api-ts-angular/model/icLicenseSummaryV2Result';
import * as WSCall from '@app/utils/WSCall';
import {OpenUrlSignResponse} from '@proflink/prof-link-web-ic-api-ts-angular/model/openUrlSignResponse';
import {ELearningComponent, ELearningListState} from '@protected/e-learning/e-learning.component';

@Component({
  selector: 'app-e-learning-list',
  templateUrl: './e-learning-list.component.html',
  styleUrls: ['./e-learning-list.component.scss']
})
export class ELearningListComponent implements OnInit {


  constructor(private _icProfileControllerService:IcProfileControllerService,
              private _eLearningComponent: ELearningComponent) { }

  title:string = '';
  courseByCategory: Array<CourseResponse> = [];


  @Input("courseByCategory")
  set setCourseByCategorySelected(courseByCategory:Array<CourseResponse>){

    if(courseByCategory != null && courseByCategory.length > 0){
      this.courseByCategory = courseByCategory;
      console.log(this.courseByCategory);
    }

  }

  ngOnInit() {}

  async viewDetail(courseResponse: CourseResponse) {

    let openUrlSignResponse: OpenUrlSignResponse = await this.viewLicenseDetail(courseResponse.id);


    var form = document.createElement("form");
    var elementInputData = document.createElement("input");
    var elementInputSignature = document.createElement("input");

    elementInputData.style.display = "none;";
    elementInputData.type = "hidden";

    elementInputSignature.style.display = "none;";
    elementInputSignature.type = "hidden";

    form.method = "POST";
    form.action = openUrlSignResponse.urlLogin;
    form.target = "_blank";
    //
    elementInputData.value = openUrlSignResponse.data;
    elementInputData.name = "data";
    form.appendChild(elementInputData);
    //
    elementInputSignature.value = openUrlSignResponse.signature;
    elementInputSignature.name = "signature";
    form.appendChild(elementInputSignature);
    //
    document.body.appendChild(form);

    form.submit();


  }

  async viewLicenseDetail(courseId:number): Promise<OpenUrlSignResponse> {
    return WSCall.wsCall(
        () => {

          return this._icProfileControllerService.viewCourseUsingGET(courseId);
        },
        respBody => {
          return respBody.data;
        }
    );
  }

  onBack(){

    this._eLearningComponent.eLearningListState = ELearningListState.SHOW_CATEGORY;

  }


}
