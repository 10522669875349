<div class="d-flex justify-content-center flex-column">
    <!-- http://stackoverflow.com/questions/793014/jquery-trigger-file-input -->
    <!-- input file with display none or visibility none cannot programmatically click. See above link. -->

    <div class="p-0 mr-2 ml-2">
        <img *ngIf="!buttonOnlyMode && imageUrl != null && previewImgData == null"
             [style.height]="height" [style.width]="width"
             [style.max-height]="maxHeight" [style.max-width]="maxWidth"
             src="{{imageUrl}}" />
        <img *ngIf="!buttonOnlyMode && previewImgData != null"
             [style.height]="height" [style.width]="width"
             [style.max-height]="maxHeight" [style.max-width]="maxWidth"
             src="{{previewImgData}}" />
    </div>

    <div class="p-0 d-flex flex-row justify-content-center">
        <a tabindex="-1" *ngIf="enable" (click)="browse($event)" class="btn btn-secondary sm upload"><i class="ft ft-upload button-ic"></i>&nbsp;{{buttonTitle}}</a>
        <input #fileInputElement style="display: none;" (change)="onFileInputChange()" name="file" type="file" accept="{{accept}}" />
    </div>

    <div class="p-0">
        <span *ngIf="!buttonOnlyMode && fileNameTransient == null && fileNameUploaded != null" class="uploaded-file">{{fileNameUploaded}}</span>
        <span *ngIf="!buttonOnlyMode && fileNameTransient != null" class="transient-file">{{fileNameTransient}}</span>
    </div>

    <div class="p-0">
        <a tabindex="-1" class="dz-remove" (click)="!buttonOnlyMode && enable && removeItem($event)"
           *ngIf="(_uploadedFile != null || previewImgData != null) && allowRemove && enable">
            Remove
        </a>
    </div>
</div>
