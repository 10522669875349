
<div *ngIf="summarySession != null" class="px-3 py-2 d-flex flex-column">

    <div class="p-0 m-0 flex-fill">
        <div class="card card-body card-custom p-0 mt-2 mb-2">
            <div class="pb-2 m-2">
                <label class="category-detail">{{summarySession.session.sessionName}}</label>
            </div>

            <div class="p-0 m-2">
                <label class="category-detail">{{displayTrainingDate}}</label>
            </div>

            <div class="pt-2 m-2 flex-fill flex-column">
                <div class="p-0 m-0 flex-fill">
                    <label class="p-0 m-0 category-title">การนับชั่วโมง</label>
                </div>

                <div class="p-0 d-flex flex-row justify-content-center">
                    <div class="p-0 flex-fill text-left">
                        <table style="min-width: 50%;max-width: 100%">
                            <thead>
                            <tr>
                                <th width="30%"></th>
                                <th width="8%"></th>
                                <th width="50%"></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>
                                    <label class="category-detail">ความรู้กฎระเบียบ</label>
                                </td>
                                <td>
                                    <label class="category-detail">{{summarySession.ethicTimeMins/60}}</label>
                                </td>
                                <td>
                                    <label class="category-detail">ชั่วโมง</label>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label class="category-detail">ความรู้ด้าน ESG</label>
                                </td>
                                <td>
                                    <label class="category-detail">{{summarySession.esgTimeMins/60}}</label>
                                </td>
                                <td>
                                    <label class="category-detail">ชั่วโมง</label>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label class="category-detail">ความรู้ด้านอื่นๆ</label>
                                </td>
                                <td>
                                    <label class="category-detail">{{summarySession.nonEthicTimeMins/60}}</label>
                                </td>
                                <td>
                                    <label class="category-detail">ชั่วโมง</label>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label class="category-detail">รวม</label>
                                </td>
                                <td>
                                    <label class="category-detail">{{totalHours}}</label>
                                </td>
                                <td>
                                    <label class="category-detail">ชั่วโมง</label>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="p-0 flex-fill">
        <div *ngFor="let cs of summarySession.courseSummaries;index as i" class="card card-body card-custom p-0 mt-2 mb-2">
            <div class="pb-4 pt-3 flex-column d-flex">

                <div class="p-0 m-2 d-inline-flex">
                    <label class="pr-2" style="background-color: #ed9700"> </label>
                    <label class="pl-3 pr-3" style="background-color:lightgrey">หลักสูตร {{i+1}}</label>
                </div>

                <div class="p-0 m-2">
                    <label class="text-course-summary-1">{{cs.courseCode+" - "+cs.courseName}}</label>
                </div>

                <div class="pt-4 pb-2 m-2 d-flex flex-column">
                    <div *ngFor="let tdTime of cs.trainingTimes" class="p-0">
                        <label>{{getDateTimeDisplay(tdTime)}}</label>
                    </div>
                </div>

                <div class="p-0 d-flex m-2 flex-column">
                    <div *ngFor="let courseCategory of cs.categories" class="pr-4 pt-2 pb-2 d-flex flex-row">
                        <div class="p-0 border-course-category text-center">
                            <label class="m-2">
                                {{getDisplayCourseCategory(courseCategory)}} ชั่วโมง
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="(summarySession.session.scheduleFiles != null && summarySession.session.scheduleFiles.length > 0)
            || (summarySession.session.posterFiles != null && summarySession.session.posterFiles > 0)"
         class="p-0 flex-fill">
        <div class="card card-body card-custom p-0 d-flex flex-column">

            <div class="p-0">
                <div class="pt-2 m-2">
                    <label class="schedule-title">กำหนดการ</label>
                </div>

                <div class="p-0 m-2 d-flex flex-row flex-wrap flex-fill">

                    <div *ngFor="let scheduleFile of summarySession.session.scheduleFiles"  class="p-0 card ml-2 mr-2 card-body card-poster-schedule ">
                        <a class="text-center" target="_blank" [href]="scheduleFile.downloadLink">
                            <div class="p-4 text-center">
                                <h2 class="text-info">{{getFileType(scheduleFile)}}</h2>
                            </div>

                            <div class="p-0 d-flex flex-column">
                                <div class="p-0 text-center">
                                    <p class="m-0 text-poster-schedule-1">{{scheduleFile.fileName}}</p>
                                </div>

                                <div class="p-0 text-center d-inline-flex justify-content-center">
                                    <i class="fa fa-clock pr-1"></i>
                                    <p class="text-poster-schedule-2">{{getDateTime(scheduleFile.updateDate)}}</p>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>

            <div *ngIf="summarySession.session.posterFiles != null && summarySession.session.posterFiles.length > 0" class="pb-3">
                <div class="pt-2 m-2">
                    <label class="schedule-title">โปสเตอร์</label>
                </div>

                <div class="p-0 m-2 d-flex flex-row flex-wrap flex-fill">
                    <div *ngFor="let posterFile of summarySession.session.posterFiles" class="p-0 card m-2 card-body card-poster-schedule ">
                        <div class="p-0">
                            <a class="text-center" target="_blank" [href]="posterFile.downloadLink">
                                <img width="100%" height="100px" [src]="posterFile.downloadLink">
                            </a>
                        </div>

                        <div class="p-0 d-flex flex-column bg-dark">
                            <div class="pt-2 text-center d-inline-flex justify-content-center ">
                                <i class="fa fa-clock pr-1 text-light"></i>
                                <p class="text-poster-schedule-2 text-light">{{getDateTime(posterFile.updateDate)}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
