import { Component, OnInit } from '@angular/core';
import {IcAgreeConsentLogSaveRequest} from '@proflink/prof-link-web-ic-api-ts-angular/model/icAgreeConsentLogSaveRequest';
import {APIResultboolean} from '@proflink/prof-link-web-ic-api-ts-angular/model/aPIResultboolean';
import * as WSCall from '@app/utils/WSCall';
import {ConsentVersionControllerService} from '@proflink/prof-link-web-ic-api-ts-angular/api/consentVersionController.service';
import {AuthProvider} from '@core/auth/auth-provider.service';
import {Router} from '@angular/router';
import {AppState} from '@core/app-state';

@Component({
  selector: 'app-login-consent',
  templateUrl: './login-consent.component.html',
  styleUrls: ['./login-consent.component.scss']
})
export class LoginConsentComponent implements OnInit {

  constructor(
      private _consentVersionControllerService:ConsentVersionControllerService,
      private _authProvider:AuthProvider,
      private _router:Router,
      private _appState:AppState
  ) { }

  ngOnInit(): void {

    this._appState.loadCurrentUser();
    if (this._appState.currentUser == null){
      this._router.navigate(["login"]);
    }

  }


  async onAgreeConsent(result: string) {

    let deviceToken = await this._authProvider.validateAndCreateDeviceToken();

    let icAgreeConsentLogSaveRequest: IcAgreeConsentLogSaveRequest = {
      agree: IcAgreeConsentLogSaveRequest.AgreeEnum.Y,
      consentVersionNo: result,
      uniqueToken: deviceToken

    };

    let resultAgree = await this.agreeByConsentNo(icAgreeConsentLogSaveRequest);
    this._router.navigate(["home"]);


  }

  async agreeByConsentNo(icAgreeConsentLogSaveRequest : IcAgreeConsentLogSaveRequest): Promise<APIResultboolean> {
    return WSCall.wsCall(
        () => {
          return this._consentVersionControllerService.agreeByConsentNoUsingPOST(icAgreeConsentLogSaveRequest)
        },
        respBody => {
          return respBody;
        }
    );
  }

}
