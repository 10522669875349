<div class="mb-3">
    <div class="d-flex justify-content-between align-items-center">
        <div class="pl-2 pl-lg-3">
            <label class="title-headline-box">หลักสูตรที่เปิดรับสมัคร</label>
        </div>
    </div>
    <div class="d-flex justify-content-center">
        <div *ngIf="broadcastResults.length == 0" class="card custom-card text-center">
            <div class="card-body"><label>ไม่มีหลักสูตรที่เปิดรับสมัครในขณะนี้</label></div>
        </div>
        <div [ngClass]="{'card custom-card mb-3': broadcastResults.length > 0}">
            <ngb-carousel #carouselBroadcasts id="broadcastId">
                <ng-template ngbSlide *ngFor="let course of broadcastResults">
                    <div class="custom-card-body row no-gutters cursor-pointer" (click)="getDetail(course)">
                        <div class="col-3 text-center">
                            <div class="start-course-container">
                                เริ่ม
                                <div class="number">{{getDisplayDay(course.startTrainingDate)}}</div>
                                {{getDisplayMonth(course.startTrainingDate)}}
                            </div>
                        </div>
                        <div class="col-9 pt-2 px-2 px-lg-0">
                            <div>
                                <label class="badge rounded-pill bg-light text-dark"
                                    *ngFor="let b of course.categories">
                                    <span>{{b.categoryName}}</span>
                                    <span class="badge badge-pill hour-course-container">
                                        <span>{{b.periodsMin/60}}</span>
                                        <br><small>HR</small></span>
                                </label>
                                <label *ngIf="course.nonEthicTimeMins>0" class="badge rounded-pill bg-light text-dark">
                                    <span>ความรู้ด้านอื่นๆ</span>
                                    <span class="badge badge-pill hour-course-container">
                                        <span>{{course.nonEthicTimeMins/60}}</span>
                                        <br><small>HR</small></span>
                                </label>
                                <label *ngIf="course.ethicTimeMins>0"
                                    class="badge rounded-pill bg-light text-dark"><span>กฏระเบียบฯ</span>
                                    <span class="badge badge-pill hour-course-container">
                                        <span>{{course.ethicTimeMins/60}}</span>
                                        <br> <small>HR</small></span></label>
                                <label *ngIf="course.esg>0" class="badge rounded-pill bg-light text-dark"><span>Default
                                        ESG</span>
                                    <span class="badge badge-pill hour-course-container"> <span>{{course.esg/60}}</span>
                                        <br> <small>HR</small></span></label>
                            </div>
                            <div><span><i class="pi pi-clock primng-icon-size"></i>
                                    {{getPeriodTrainingTimeDisplay(course)}} </span></div>
                            <div><span><i class="pi pi-map-marker primng-icon-size"></i>
                                    {{course.provinceName}} </span></div>
                            <div class="title-topic text-truncate">
                                {{course.sessionName}}
                            </div>
                        </div>
                    </div>
                </ng-template>
            </ngb-carousel>
        </div>
    </div>
</div>