import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SessionModel} from '@proflink/prof-link-web-ic-api-ts-angular/model/sessionModel';

@Component({
  selector: 'app-my-course-detail-banner',
  templateUrl: './my-course-detail-banner.component.html',
  styleUrls: ['./my-course-detail-banner.component.scss']
})
export class MyCourseDetailBannerComponent implements OnInit {

  constructor() { }

  public color:string;

  @Output('backComplete')
  backComplete: EventEmitter<Boolean> = new EventEmitter();

  @Input("status")
  status:string;

  @Input("displayTrainingDate")
  displayTrainingDate:string;

  @Input("location")
  location:string;

  @Input('session') session: SessionModel;

  holdResult:Boolean;

  @Input("holdResult")
  set setHoldResult(holdResult:Boolean){

    if(holdResult != null){
      this.holdResult = holdResult;

      console.log(this.holdResult);

      if(!this.holdResult){
        this.status = 'ลงทะเบียนสำเร็จ';
        this.color = 'success';
      }else{
        this.status = 'ระงับผลการอบรม (กรุณาส่งเอกสารยืนยันตัวตน)';
        this.color = 'hold-result';
      }
    }
  }

  ngOnInit() {

  }


  backToList(){
    this.backComplete.emit(true);
  }

}
