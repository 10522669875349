import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {SessionModel} from "@proflink/prof-link-web-ic-api-ts-angular/model/sessionModel";
import {TrainingProviderContactModel} from "@proflink/prof-link-web-ic-api-ts-angular/model/trainingProviderContactModel";
import {TrainingLocationModel} from "@proflink/prof-link-web-ic-api-ts-angular/model/trainingLocationModel";
import {TrainingDateModel} from "@proflink/prof-link-web-ic-api-ts-angular/model/trainingDateModel";
import {newArray} from "@angular/compiler/src/util";
import {SessionTrainingTimeSummaryModel} from "@proflink/prof-link-web-ic-api-ts-angular/model/sessionTrainingTimeSummaryModel";
import {DatePipe} from "@angular/common";

@Component({
  selector: 'app-tab-course-location-detail',
  templateUrl: './tab-course-location-detail.component.html',
  styleUrls: ['./tab-course-location-detail.component.scss']
})
export class TabCourseLocationDetailComponent implements OnInit {

  constructor(
      private _datePipe: DatePipe
  ) { }

  sessionModel:SessionModel;
  trainingProviderContact:TrainingProviderContactModel;

  lattitude:number = 13.7524911;
  lontitude:number = 100.5374645;

  @ViewChild('gmap') gmapElement: any;
  map: google.maps.Map;

  mapTrainingLocation:Map<number,Array<TrainingDateModel>> = new Map<number, Array<TrainingDateModel>>();

  firstTrainingDate:TrainingDateModel=null;

  @Input("session")
  set setSessionModel(sessionModel:SessionModel){

    if(sessionModel != null){
      this.sessionModel = sessionModel;
      this.trainingProviderContact = sessionModel.trainingProviderContact;
      this.loadData();
    }

  }




  ngOnInit(){



  }

  ngAfterViewInit(){

    this.loadMap();

  }

  loadMap(){
    if(this.firstTrainingDate != null){

      let myLatLng = new google.maps.LatLng(this.firstTrainingDate.trainingLocation.latitude, this.firstTrainingDate.trainingLocation.longitude);
      let mapProp = {
        center: myLatLng,
        zoom: 15,
        mapTypeId: google.maps.MapTypeId.ROADMAP
      };

      this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProp);
      let marker = new google.maps.Marker({
        position: myLatLng
      });

      marker.setMap(this.map);
    }
  }

  loadData(){

    this.sessionModel.trainingDates.forEach(td => {
      let mapTrainingDate = this.mapTrainingLocation.get(td.trainingLocation.trainingLocationId);
      if(mapTrainingDate == null){

        if(this.firstTrainingDate == null){
          this.firstTrainingDate = td;
        }
        mapTrainingDate = [];
        mapTrainingDate.push(td);
      }else{
        mapTrainingDate.push(td);
      }
      this.mapTrainingLocation.set(td.trainingLocation.trainingLocationId,mapTrainingDate);
    });




  }

  getDateTimeDisplay(dateString:string){

    let date = new Date(dateString);
    let birthDaySubmit = this._datePipe.transform(date, 'dd MMM');
    let yearTraining = Number(this._datePipe.transform(date, 'yyyy'));

    yearTraining = yearTraining +543;
    let displayTrainingDate = birthDaySubmit+ ' '+yearTraining;

    return displayTrainingDate;

  }

}
