<div [@flyInAnimation]="'in'">
    <app-header-with-back class="header" [title]="title" [path]="'home'"></app-header-with-back>
    <div class="box-container">
        <div class="box">
            <section class="pt-3 px-5 text-center container" style="font-size: 1.2rem;">
                <p>แจ้งยกเลิกการใช้งานระบบ Prof. Link และการใช้งานข้อมูลส่วนบุคคล</p>
            </section>
            <div class="d-flex flex-column">
                <div class="bloghtml" [innerHTML]="textHtml"></div>
                <div class="p-2 flex-fill text-muted">
                    <div class="form-check">
                        <input class="form-check-input" id="confrimCheck" type="checkbox" value=""
                            [(ngModel)]="confrimCheck" [disabled]="!haveCancelConsent">
                        <label class="form-check-label" for="confrimCheck">
                            ผู้ใช้งานได้อ่านรายละเอียดและรับทราบผลกระทบจากการยกเลิกการใช้งานระบบ Prof. Link
                            ตามข้างต้นเป็นอย่างดีแล้ว และยืนยันยกเลิกการใช้งานระบบ Prof. Link
                        </label>
                    </div>
                </div>
                <div class="pt-3 px-3 pb-0 flex-fill">
                    <p> ติดต่อ SET Contact Center : <a
                            href="https://www.set.or.th/set/contactcenter.do">www.set.or.th/set/contactcenter.do</a>
                        โทร. 02 009 9999</p>
                </div>
                <div class="d-flex justify-content-around">
                    <div class="p-2 flex-fill">
                        <button type="button" class="btn btn-block submit bg-primary" (click)="cancel()">ยกเลิก</button>
                    </div>
                    <div class="p-2 flex-fill">
                        <button type="button" class="btn btn-block un-submit bg-primary" (click)="confirm()"
                            [disabled]="!confrimCheck">ยืนยัน</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>