import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SessionModel} from '@proflink/prof-link-web-ic-api-ts-angular/model/sessionModel';
import {IcProfileModel} from '@proflink/prof-link-web-ic-api-ts-angular/model/icProfileModel';

@Component({
  selector: 'app-course-detail-banner',
  templateUrl: './course-detail-banner.component.html',
  styleUrls: ['./course-detail-banner.component.scss']
})
export class CourseDetailBannerComponent implements OnInit {

  constructor() { }


  @Output('backPageCallback')
  backPageCallback: EventEmitter<Boolean> = new EventEmitter();

  @Input("displayTrainingDate")
  displayTrainingDate:string='';

  @Input("location")
  location:string='';

  session: SessionModel;

  @Input("session")
  set setSession(session:SessionModel){
    if(session != null){
      this.session = session;
      console.log(session);
    }
  }


  @Input('holdResult')
  holdResult:Boolean;

  @Input("status")
  status:string;

  @Input("platform")
  platform:string;

  bannerUrl:string="";


  @Input("showDataCheckInCheckout")
  showDataCheckInCheckout:boolean=false;

  ngOnInit() {



  }


  backToList(){

    this.backPageCallback.emit(true);

  }

}
