export namespace Session {
    export enum Status {
        NEW_DRAFT, DRAFT, PREPARED, IN_TRAINING, POST_TRAINING, CLOSED, CANCELLED
    }

    export enum Direction {
        Left = "Left",
        Right = "Right"
    }

    // type number Satisfaction
    export const SatisfactionTypes = [1,2,6,9,10,11,13];
    export const SurveyTypes = [5,12];


  export function toStatus(status: string): Status {
        if (status === '1D') return Status.DRAFT;
        else if (status === '2P') return Status.PREPARED;
        else if (status === '3T') return Status.IN_TRAINING;
        else if (status === '4A') return Status.POST_TRAINING;
        else if (status === '5C') return Status.CLOSED;
        else if (status === '6L') return Status.CANCELLED;
        else if (status === '7N') return Status.NEW_DRAFT;
        else throw new Error(`Invalid status code - ${status}`);
    }

    export function toCode(status: Status): string {
        if (status === Status.DRAFT) return '1D';
        else if (status === Status.PREPARED) return '2P';
        else if (status === Status.IN_TRAINING) return '3T';
        else if (status === Status.POST_TRAINING) return '4A';
        else if (status === Status.CLOSED) return '5C';
        else if (status === Status.CANCELLED) return '6L';
        else if (status === Status.NEW_DRAFT) return '7N';
        else throw new Error(`Invalid status code - ${status}`);
    }

    export function toString(status: Status): string {
        if (status === Status.DRAFT) return 'Draft';
        else if (status === Status.PREPARED) return 'Preparing';
        else if (status === Status.IN_TRAINING) return 'In-training';
        else if (status === Status.POST_TRAINING) return 'Post-training';
        else if (status === Status.CLOSED) return 'Closed';
        else if (status === Status.CANCELLED) return 'Cancelled';
        else if (status === Status.NEW_DRAFT) return 'New draft';
        else return `Invalid status code - ${status}`;
    }

    export function isBeforeOrDraft(status: Status): boolean {
        if (status == null) return false;
        if (status === Status.NEW_DRAFT ||
            status === Status.DRAFT)
            return true;
        return false;
    }

    export function isBeforeOrPrepared(status: Status): boolean {
        if (status == null) return false;
        if (status === Status.NEW_DRAFT ||
            status === Status.DRAFT ||
            status === Status.PREPARED)
            return true;
        return false;
    }

  export function isAfterInTraining(status: Status): boolean {
    if (status == null) return false;
    if (status === Status.IN_TRAINING ||
      status === Status.POST_TRAINING ||
      status === Status.CLOSED)
      return true;
    return false;
  }

    export function isBeforeOrInTraining(status: Status): boolean {
        if (status == null) return false;
        if (status === Status.NEW_DRAFT ||
            status === Status.DRAFT ||
            status === Status.PREPARED ||
            status === Status.IN_TRAINING)
            return true;
        return false;
    }

    export function isBeforeOrPostTraining(status: Status): boolean {
        if (status == null) return false;
        if (status === Status.NEW_DRAFT ||
            status === Status.DRAFT ||
            status === Status.PREPARED ||
            status === Status.IN_TRAINING ||
            status === Status.POST_TRAINING)
            return true;
        return false;
    }

    export function isClosed(status: Status): boolean {
        if (status == null) return false;
        if (status === Status.CLOSED)
            return true;
        return false;
    }

    export function isCancelled(status: Status): boolean {
        if (status == null) return false;
        if (status === Status.CANCELLED)
            return true;
        return false;
    }

    export function isClosedOrCancelled(status: Status): boolean {
        if (status == null) return false;
        if (status === Status.CLOSED || status === Status.CANCELLED)
            return true;
        return false;
    }
}

export namespace StaffUserProfile {
    export enum ProfileType {
        TP, HR, TSI, SEC
    }

    export function toProfileType(typeCode: number): ProfileType {
        if (typeCode == 1) return ProfileType.TP;
        else if (typeCode == 2) return ProfileType.HR;
        else if (typeCode == 3) return ProfileType.TSI;
        else if (typeCode == 4) return ProfileType.SEC;
        else throw new Error(`Invalid typeCode - ${typeCode}`);
    }

    export function toCode(type: ProfileType): number {
        if (type === ProfileType.TP) return 1;
        else if (type === ProfileType.HR) return 2;
        else if (type === ProfileType.TSI) return 3;
        else if (type === ProfileType.SEC) return 4;
        else throw new Error(`Invalid type - ${type}`);
    }

    export function toString(type: ProfileType): string {
        if (type === ProfileType.TP) return 'TP';
        else if (type === ProfileType.HR) return 'HR';
        else if (type === ProfileType.TSI) return 'TSI';
        else if (type === ProfileType.SEC) return 'SEC';
        else return `Invalid type - ${type}`;
    }
}
