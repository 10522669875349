import { Component, OnInit } from '@angular/core';
import {SearchModel} from "@proflink/prof-link-web-ic-api-ts-angular/model/searchModel";
import {ResultSessionModel} from "@proflink/prof-link-web-ic-api-ts-angular/model/resultSessionModel";
import * as WSCall from "@app/utils/WSCall";
import {SessionControllerService} from "@proflink/prof-link-web-ic-api-ts-angular/api/sessionController.service";
import {TrainingDateModel} from "@proflink/prof-link-web-ic-api-ts-angular/model/trainingDateModel";
import {DatePipe} from "@angular/common";
import {FileStoreModel} from "@proflink/prof-link-web-ic-api-ts-angular/model/fileStoreModel";

@Component({
  selector: 'app-upcoming-course-search',
  templateUrl: './upcoming-course-search.component.html',
  styleUrls: ['./upcoming-course-search.component.scss']
})
export class UpcomingCourseSearchComponent implements OnInit {

  title='ผลการค้นหา';

  constructor(
      private _sessionControllerService : SessionControllerService,
      private _datePipe: DatePipe
  ) { }


  resultSessionSelected:ResultSessionModel = null;
  selected:boolean= false;


  upcomingCourses:Array<ResultSessionModel> = [];

  ngOnInit() {

  this.loadData();


  }

  async loadData(){

    let icId = +(localStorage.getItem("ic_id"));
    let  searchModel:SearchModel = {
      id : icId
    };

    this.upcomingCourses = [];
    // this.upcomingCourses = await this.getSessionNewBroadCastByCondition(searchModel);

    console.log(this.upcomingCourses.length);

  }


  async getSessionNewBroadCastByCondition(searchModel:SearchModel) : Promise<Array<ResultSessionModel>> {
    return WSCall.wsCall(
        () => {
          return this._sessionControllerService.getSessionNewBroadCastByConditionUsingPOST1(searchModel);
        },
        respBody => {
          return respBody.data;
        }
    );
  }

  getPeriodTrainingDayDisplay(trainingdays:Array<TrainingDateModel>){

    let displayDate:string = '';
    if(trainingdays.length <= 1){

      let trainingDate:Date = new Date(trainingdays[0].trainingDate);
      let birthDaySubmit = this._datePipe.transform(trainingDate, 'dd MMM');
      let yearTraining = Number(this._datePipe.transform(trainingDate, 'yyyy'));

      yearTraining = yearTraining +543;
      displayDate = birthDaySubmit+ ' '+yearTraining;

    }else {

      let firstTrainingDate:Date = new Date(trainingdays[0].trainingDate);
      let lastTrainingDate:Date = new Date(trainingdays[trainingdays.length-1].trainingDate);

      let firstDayDisplay = this._datePipe.transform(firstTrainingDate, 'dd MMM');
      let lastDayDisplay = this._datePipe.transform(lastTrainingDate, 'dd MMM');

      let yearTraining = Number(this._datePipe.transform(firstTrainingDate, 'yyyy'));

      yearTraining =yearTraining +543;
      displayDate = firstDayDisplay +' - '+lastDayDisplay+ ' '+yearTraining;
    }

    return displayDate;
  }

  getFile(file:FileStoreModel){

    if(file ==null){
      return '';
    }else{
      return file.downloadLink;
    }
  }

  getCourseDetail(resultSessionModel : ResultSessionModel){

    this.resultSessionSelected = resultSessionModel;
    this.selected = true;

  }

  backPageCallBack(result:boolean){

    this.resultSessionSelected = null;
    this.selected = false;

  }

}
