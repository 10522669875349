<div class="col-sm-12">
    <div class="d-flex text-muted border-bottom w-100" *ngFor="let newModel of news">
        <div class="p-2">
            <img width="150px" height="100px" [src]="getDownloadLink(newModel)"
                onerror="this.src='../../../../assets/image/bg-gray.png'" (click)="openUrl(newModel.url)">
        </div>
        <div class="p-2 justify-content-start mb-0 small lh-sm">
          <div class="d-flex">
            <h6 class="text-gray-dark">{{getDisplayDate(newModel.publishedDate)}} : {{newModel.title}}</h6>
          </div>
          <div class="d-flex">
            <span class="text-muted">{{newModel.detail}}</span>
          </div>
          <div class="d-flex">
            {{getDisplayTag(newModel.newsTags)}}
          </div>
        </div>
      </div>
</div>