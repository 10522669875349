import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header-with-close',
  templateUrl: './header-with-close.component.html',
  styleUrls: ['./header-with-close.component.scss']
})
export class HeaderWithCloseComponent implements OnInit {

  @Input() title: string;

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  close() {
    this.router.navigate(['..']);
  }

}
