import { HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { EventEmitter, Inject, Injectable, Optional } from '@angular/core';
import * as WSCall from '@app/utils/WSCall';
import {
    BASE_PATH,
    Configuration,
    IcProfileModel,
    UserProfileControllerService
} from '@proflink/prof-link-web-ic-api-ts-angular';
import { LoggedInUserInfoResult } from '@proflink/prof-link-web-ic-api-ts-angular/model/loggedInUserInfoResult';
import { StaffUserProfileModel } from '@proflink/prof-link-web-ic-api-ts-angular/model/staffUserProfileModel';
import { OAuth2AccessToken, TokenEndpointService } from '@proflink/prof-link-springsec-web-ic-api-ts-angular';
import { Observable } from 'rxjs';
import { CustomHttpUrlEncodingCodec } from '@proflink/prof-link-springsec-web-ic-api-ts-angular/encoder';
import { environment } from '../../environments/environment';
import { switchMap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AppState {
    private _mobileMode: boolean = false;
    staffUserProfileId: number = 0;
    tabSelected: number = 0;

    currentUserEventEmitter: EventEmitter<StaffUserProfileModel | IcProfileModel> = new EventEmitter();


    currentUser: IcProfileModel = null;

    tempPassword: string = null;
    tempUser: string = null;

    appConfig: { [key: string]: string; } = {};


    protected basePath = 'https://dev.proflink.set.or.th';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(private _tokenService: TokenEndpointService,
        private _userProfileService: UserProfileControllerService,
        private _apiConfig: Configuration,
        protected httpClient: HttpClient,
        @Optional() @Inject(BASE_PATH) basePath: string,
        @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }


        //_tokenService.defaultHeaders = _tokenService.defaultHeaders.set('app-version','1.0.10');
        _tokenService.defaultHeaders = _tokenService.defaultHeaders.set('Authorization', 'cHJvZmxpbms6N2FCNDc4QUxRdTJrZlA=');

    }

    async login(username: string, password: string, deviceToken: string): Promise<IcProfileModel> {

        let p = new Promise((resolve, reject) => {
            var tsiResult$ = this.postAccessTokenUsingPOST(username, password, null, deviceToken)
                .subscribe((body) => {
                    var accessToken: string = (<any>body).access_token;
                    if (accessToken != null) {
                        var appUserModel: IcProfileModel = <IcProfileModel>(<any>body).data;
                        this._apiConfig.accessToken = accessToken;
                        this.setCurrentUser(appUserModel);

                        this.currentUserEventEmitter.emit(this.currentUser);
                        let refresh_token = (<any>body).refresh_token;

                        localStorage.setItem('access_token', accessToken);
                        localStorage.setItem('refresh_token', refresh_token);
                        localStorage.setItem('ic_id', '' + this.currentUser.icId);

                        this.getLoggedInUser(accessToken);
                        resolve(this.currentUser);
                    }
                    else {
                        var errorCode: string = (<any>body).error_code;
                        var appUserModel: IcProfileModel = <IcProfileModel>(<any>body).data;
                        this.currentUser = appUserModel;
                        if (errorCode == '2') {
                            // required otp
                            reject('2');
                        }
                        else if (errorCode == '3') {
                            // This is account lock code.
                            reject(new Error('There was too many incorrect login attempts. Your account has been locked.'));
                        }
                        else if (errorCode == '4') {
                            // This is account lock code.
                            reject(
                                new Error('This account has not been activated. The activation link has been provided in your mailbox.')
                            );
                        }
                        else {
                            // Most likely invalid user/pass
                            reject(new Error('Invalid login credential. Please try again.'));
                        }
                    }
                }, (error) => {
                    reject(error);
                });
        });

        return p;
    }

    loginWithRefreshToken(rToken: string, deviceToken: string): Observable<OAuth2AccessToken> {
        return this.postAccessTokenUsingPOST(null, null, rToken, deviceToken);
    }

    logout() {
        this._apiConfig.accessToken = null;
        this.currentUser = null;

        // Also, remove all cached in cookies
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('currentUser');
        // localStorage.clear();
    }

    setCurrentUser(appUserModel: IcProfileModel) {

        this.currentUser = appUserModel;
        let jsonCurrentUser = JSON.stringify(appUserModel);
        localStorage.setItem('currentUser', jsonCurrentUser);
    }

    loadDataWithRefreshToken(refreshToken : string,
                             _this : any,
                             callbackSuccess : any){

        this.postAccessTokenUsingPOST(null, null, refreshToken, null).subscribe(async out => {

            const accessToken: string = (<any>out).access_token;
            const refreshToken = (<any>out).refresh_token;
            const appUserModel: IcProfileModel = <IcProfileModel>(<any>out).data;
            this.setCurrentUser(appUserModel);
            localStorage.setItem('access_token', accessToken);
            localStorage.setItem('ic_id',appUserModel.icId.toString())
            localStorage.setItem('refresh_token', refreshToken);
            callbackSuccess(_this);

        });

    }

    loadCurrentUser() {

        let jsonCurrentUser: string = localStorage.getItem('currentUser');
        if (jsonCurrentUser == null || jsonCurrentUser == '') {
            return;
        }
        this.currentUser = JSON.parse(jsonCurrentUser);

    }

    async getLoggedInUser(token: string): Promise<IcProfileModel> {
        let p = new Promise<IcProfileModel>(async (resolve, reject) => {
            let userInfo = await this.getLoggedInUserWSCall();

            if (userInfo != null) {
                if (userInfo.staffUserProfile != null) {
                    this.currentUser = userInfo.staffUserProfile;
                }
                else {
                    this.currentUser = userInfo.icProfile;
                }
            }
            this.currentUserEventEmitter.emit(this.currentUser);
            resolve(this.currentUser);
        });

        return p;
    }

    async getLoggedInUserWSCall(): Promise<LoggedInUserInfoResult> {
        return WSCall.wsCall(() => {
            return this._userProfileService.getLoggedInUserUsingGET();
        }, (respBody) => {
            return respBody.data;
        });
    }

    public postAccessTokenUsingPOST(username: string, password: string, refreshToken: string, deviceToken: string, observe?: 'body', reportProgress?: boolean): Observable<OAuth2AccessToken>;
    public postAccessTokenUsingPOST(username: string, password: string, refreshToken: string, deviceToken: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OAuth2AccessToken>>;
    public postAccessTokenUsingPOST(username: string, password: string, refreshToken: string, deviceToken: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OAuth2AccessToken>>;
    public postAccessTokenUsingPOST(username: string, password: string, refreshToken: string, deviceToken: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (username != null && password != null) {
            queryParameters = queryParameters.set('username', username);
            queryParameters = queryParameters.set('password', password);
            queryParameters = queryParameters.set('grant_type', 'password');
        }
        else if (refreshToken != null) {
            // Using refresh token
            queryParameters = queryParameters.set('refresh_token', refreshToken);
            queryParameters = queryParameters.set('grant_type', 'refresh_token');
        }
        else {
            throw Error('Invalid request to post access. Either using user/pass or refreshToken');
        }
        queryParameters = queryParameters.set('devicetoken', deviceToken);

        let headers = this.defaultHeaders;
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        headers = headers.set('Authorization', 'Basic ' + environment.basicAuthenticationCode);
        headers = headers.set('app-version', environment.appVersion);

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<OAuth2AccessToken>(`${this.basePath}/oauth/token`,
            queryParameters,
            {
                // params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
