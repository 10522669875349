import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {RegisterICTemporaryAccountModel} from '@proflink/prof-link-web-ic-api-ts-angular/model/registerICTemporaryAccountModel';
import {LicenseCurrentPeriodResult} from '@proflink/prof-link-web-ic-api-ts-angular';
import * as WSCall from '@app/utils/WSCall';
import {IcProfileControllerService} from '@proflink/prof-link-web-ic-api-ts-angular/api/icProfileController.service';
import {ReSendEmailRequest} from '@proflink/prof-link-web-ic-api-ts-angular/model/reSendEmailRequest';
import {APIResultstring} from '@proflink/prof-link-web-ic-api-ts-angular/model/aPIResultstring';
import {IcProfileModel} from '@proflink/prof-link-web-ic-api-ts-angular/model/icProfileModel';
import {AppState} from '@core/app-state';
import { Router } from '@angular/router';
import * as Utils from '@app/utils/Utils';

@Component({
  selector: 'app-email-validate-form',
  templateUrl: './email-validate-form.component.html',
  styleUrls: ['./email-validate-form.component.scss']
})
export class EmailValidateFormComponent implements OnInit {

  constructor(
    private _router: Router,
  ) { }

  @Output('completeState')
  completeState: EventEmitter<ReSendEmailRequest> = new EventEmitter();

  @Input("email")
  email:string = '';

  ngOnInit(): void {

  }

  confirmEmail(){

    const regex = new RegExp("^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$");

    let passValidate:boolean = regex.test(this.email);
    if(!passValidate){
      Utils.error("กรุณากรออก อีเมล ให้ถูกต้อง");
      return;
    }


    if(this.email == ''){
      Utils.error("กรุณากรอก อีเมล");
      return;
    }

    let request:ReSendEmailRequest = {
        email : this.email
    };

    this.completeState.emit(request);
  }

  redirectToLogin(){
    this._router.navigate(["login"]);
  }



}
