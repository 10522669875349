import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from "@angular/router";
import { ResultOTPModel } from "@proflink/prof-link-web-ic-api-ts-angular/model/resultOTPModel";
import { RegisterICTemporaryAccountModel } from "@proflink/prof-link-web-ic-api-ts-angular/model/registerICTemporaryAccountModel";
import * as WSCall from "@app/utils/WSCall";
import { AccountControllerService } from "@proflink/prof-link-web-ic-api-ts-angular/api/accountController.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { APIResultResultOTPModel } from "@proflink/prof-link-web-ic-api-ts-angular/model/aPIResultResultOTPModel";
import * as Utils from "@app/utils/Utils";
import { RegisterStateEnum } from "@public/register/register.component";
import { IcProfileModel } from "@proflink/prof-link-web-ic-api-ts-angular/model/icProfileModel";
import { NgbDate } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-regiseter-form-create',
  templateUrl: './register-form-create.component.html',
  styleUrls: ['./register-form-create.component.scss']
})
export class RegisterFormCreateComponent implements OnInit {

  constructor(
    private _accountControllerService: AccountControllerService,
    private _router: Router,
    private fb: FormBuilder
  ) { }

  resultOTPModel: ResultOTPModel
  token: string = '';
  verifySuccess = false;
  submitForm = false;
  title: string = 'สร้างบัญชี';

  public registerMobileForm: FormGroup;

  @Output('completeState')
  completeState: EventEmitter<RegisterICTemporaryAccountModel> = new EventEmitter();

  @Input("icProfile")
  icProfile: IcProfileModel;

  canSubmit: boolean = false;


  get f() {
    return this.registerMobileForm.controls;
  }

  ngOnInit(): void {

    this.createForm();

  }

  createForm() {
    this.registerMobileForm = this.fb.group({
      mobileNumber: ['', Validators.compose([Validators.required, Validators.maxLength(10), Validators.pattern(/^\d{10}$/)])],
      mobileNumberConfirm: ['', Validators.compose([Validators.required, Validators.maxLength(10), Validators.pattern(/^\d{10}$/)])],
      email: ['',Validators.required],
      emailConfirm : ['',Validators.required]
    });

    this.registerMobileForm.valueChanges.subscribe((changeObject) => {
      this.canSubmit = this.registerMobileForm.valid;
    });

  }

  submitForRegister() {

    if (this.registerMobileForm.value.mobileNumber != this.registerMobileForm.value.mobileNumberConfirm) {

      Utils.error("การยืนยันเบอร์โทรศัพท์มือถือ ไม่ตรงกัน");
      return;
    }

    if(this.registerMobileForm.value.email != this.registerMobileForm.value.emailConfirm){
      Utils.error("การยืนยัน email ไม่ตรงกัน");
      return;
    }


    let registerICTemporaryAccountModel: RegisterICTemporaryAccountModel = {
      mobileNumber: this.registerMobileForm.value.mobileNumber,
      email: this.registerMobileForm.value.email,
      otpType: "RE",
      icId: this.icProfile.icId
    };

    this.completeState.emit(registerICTemporaryAccountModel);

  }
}
