import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Configuration, UserProfileControllerService } from '@proflink/prof-link-web-ic-api-ts-angular';
import {AppState} from '@app/core/app-state';



@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    private _apiConfiguration: Configuration,
    private _router: Router,
    private _appState: AppState
  ) {

  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    let p = new Promise<boolean>(async (resolve, reject) => {
      let token = route.queryParams['token'];
      if (token != null) {
        // If token in URL exist, it takes precedent over normal cookie. Custom token param in query path of URL.
        this._apiConfiguration.accessToken = token;
        await this._appState.getLoggedInUser(token);
        resolve(true);
      }
      else if (this._apiConfiguration.accessToken != null && this._appState.currentUser != null) {
        resolve(true);
      }
      else {
        if (this._apiConfiguration.accessToken != null) {
          // This may happen when browser contains cookie to keep accessToken but not the currentUser. In such case, we need to obtain currentUser again here.
          let token = <string>this._apiConfiguration.accessToken;
          await this._appState.getLoggedInUser(token);
          resolve(true);
        }
        else {
          this._router.navigate(["login"]);
          resolve(false);
        }
      }
    });

    return p;
  }
}
