<div class="box-container">
    <div class="box">
        <div class="d-flex flex-column">
            <div class="list-group">
                <div class="pl-2 m-2 flex-fill text-left">
                    <label class="title-headline-box">ค้นหาหลักสูตร e-Learning</label>
                </div>
                <div class="mx-3">
                    <div class="input-group">
                        <input placeholder="ระบุ รหัส,ชื่อหลักสูตร,keyword" class="form-control" [(ngModel)]="searchValue">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" type="button" style="width: 70px" (click)="search()">
                                <i class="fas fa-search"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="list-group">
                <div class="pl-2 m-2 flex-fill text-left">
                    <label class="title-headline-box">หมวดหมู่</label>
                </div>
                <a *ngIf="categoryResponse.length > 0" class="list-group-item list-group-item-action" (click)="onSelectedCategory()">
                    <span>ทั้งหมด
                        <i class="fas fa-chevron-right float-right"></i>
                    </span>
                </a>
                <a *ngFor="let o of categoryResponse" class="list-group-item list-group-item-action"
                    (click)="onSelectedCategory(o)">
                    <span>{{o.title}}
                        <i class="fas fa-chevron-right float-right"></i>
                    </span>
                </a>
            </div>

        </div>
    </div>
</div>