import { Component, OnInit } from '@angular/core';
import {ReSendEmailRequest} from '@proflink/prof-link-web-ic-api-ts-angular/model/reSendEmailRequest';
import {APIResultstring} from '@proflink/prof-link-web-ic-api-ts-angular/model/aPIResultstring';
import * as WSCall from '@app/utils/WSCall';
import {IcProfileControllerService} from '@proflink/prof-link-web-ic-api-ts-angular/api/icProfileController.service';
import * as Utils from '@app/utils/Utils';
import {AppState} from '@core/app-state';
import {IcProfileModel} from '@proflink/prof-link-web-ic-api-ts-angular/model/icProfileModel';

@Component({
  selector: 'app-email-validate',
  templateUrl: './email-validate.component.html',
  styleUrls: ['./email-validate.component.scss']
})
export class EmailValidateComponent implements OnInit {

  constructor(
      private _icProfileControllerService: IcProfileControllerService,
      private _appState: AppState,
  ) { }

  email:string = '';
  icProfileId: number;

  emailState:EmailValidateStateEnum = EmailValidateStateEnum.EMAIL_FORM;

  async ngOnInit() {

    if (this._appState.currentUser != null) {
      let icProfile: IcProfileModel = this._appState.currentUser;
      this.icProfileId = icProfile.icId;
      await this.loadIcProfileAndEmail(this.icProfileId);
    }


  }

  async loadIcProfileAndEmail(icProfileId: number) {

    let icProfile: IcProfileModel = await this.getIcProfileById(icProfileId);
    this.email = icProfile.email;

  }

  async onConfirmEmail(reSendEmailRequest: ReSendEmailRequest) {

    if (reSendEmailRequest.email.trim().length == 0) {
      Utils.error("กรุณากรอก อีเมล");
      return;
    }
    this.email = reSendEmailRequest.email;
    await this.prepareAndSendConfirmEmail(this.email);

    this.emailState = EmailValidateStateEnum.EMAIL_CONFIRM;

  }

  async prepareAndSendConfirmEmail(email: string) {

    if (email.trim().length == 0) {
      Utils.error("กรุณากรอก อีเมล");
      this.emailState = EmailValidateStateEnum.EMAIL_FORM;
      return;
    }

    let resendEmailRequest: ReSendEmailRequest = {
      email: email
    }

    let result: APIResultstring = await this.resendConfirmEmail(resendEmailRequest);
    if(result.resultCode == 'SUCCESS'){
      Utils.info("ระบบได้ทำการส่งอีเมลไปยังอีเมลที่ท่านให้ข้อมูลไว้กับระบบแล้ว กรุณาคลิกลิงค์ยืนยันที่อีเมลของท่าน หากยังไม่ได้ยืนยันอีเมล ท่านจะไม่สามารถเข้าสู่ระบบ Prof. Link ได้");
    }else{
      Utils.error("ส่งยืนยันอีเมลไม่สำเร็จ "+result.errorMessage);
    }

  }

  async resendConfirmEmail( reSendEmailRequest : ReSendEmailRequest): Promise<APIResultstring> {
    return WSCall.wsCall(
        () => {
          return this._icProfileControllerService.resendConfirmEmailUsingPOST(reSendEmailRequest);
        },
        respBody => {
          return respBody;
        }
    );
  }

  async getIcProfileById(icProfileId:number) : Promise<IcProfileModel> {

    return WSCall.wsCall(
        () => {
          return this._icProfileControllerService.getIcProfileByIdUsingGET(icProfileId);
        },
        respBody => {
          return respBody.data;
        }
    );
  }


}
export type EmailValidateStateEnum = 'EMAIL_FORM' | 'EMAIL_CONFIRM';
export const EmailValidateStateEnum = {
  EMAIL_FORM : 'EMAIL_FORM' as EmailValidateStateEnum,
  EMAIL_CONFIRM : 'EMAIL_CONFIRM' as EmailValidateStateEnum

}
