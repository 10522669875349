import {EventEmitter, Output} from '@angular/core';
import { Component, OnInit } from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {APIResultstring} from "@proflink/prof-link-web-ic-api-ts-angular/model/aPIResultstring";
import * as WSCall from "@app/utils/WSCall";
import {PublicMiscControllerService} from "@proflink/prof-link-web-ic-api-ts-angular/api/publicMiscController.service";

@Component({
  selector: 'app-instructional-share',
  templateUrl: './instructional-share.component.html',
  styleUrls: ['./instructional-share.component.scss']
})

export class InstructionalShareComponent implements OnInit {


  constructor(private _sanitizer: DomSanitizer,
              private _publicMiscControllerService: PublicMiscControllerService) { }


  @Output('completeState')
  completeState: EventEmitter<Boolean> = new EventEmitter();


  init() {

    if (window['YT']) {
      this.startVideo();
      return;
    }

    var tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    var firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    window['onYouTubeIframeAPIReady'] = () => this.startVideo();

  }

  async ngOnInit() {
    this.video = await this.getYoutubeVideo();
    this.init();

  }

  title = 'dummyApp-YTIFrameAPI';
  showVideo = true;

  public YT: any;
  public video: any;
  public player: any;
  public refFramed: Boolean = false;
  isRestricted = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  next(){

    this.completeState.emit(true);

  }

  async getYoutubeVideo(){

    let result:APIResultstring = await this.getVdoInstructionYoutubeId();
    if(result.resultCode == 'SUCCESS'){
      return result.data;
    }else{
      return '0I_3Fl62T54';
    }
  }

  startVideo() {
    this.refFramed = false;
    this.player = new window['YT'].Player('player', {
      videoId: this.video,
      playerVars: {
        autoplay: 1,
        modestbranding: 1,
        controls: 1,
        disablekb: 1,
        rel: 0,
        showinfo: 0,
        fs: 0,
        playsinline: 1

      },
      events: {
        'onStateChange': this.onPlayerStateChange.bind(this),
        'onError': this.onPlayerError.bind(this),
        'onReady': this.onPlayerReady.bind(this),
      }
    });
  }

  onPlayerReady(event : any) {
    if (this.isRestricted) {
      event.target.mute();
      event.target.playVideo();
    } else {
      event.target.playVideo();
    }
  }

  onPlayerStateChange(event:any) {
    console.log(event)
    switch (event.data) {
      case window['YT'].PlayerState.PLAYING:
        if (this.cleanTime() == 0) {
          console.log('started ' + this.cleanTime());
        } else {
          console.log('playing ' + this.cleanTime())
        };
        break;
      case window['YT'].PlayerState.PAUSED:
        if (this.player.getDuration() - this.player.getCurrentTime() != 0) {
          console.log('paused' + ' @ ' + this.cleanTime());
        };
        break;
      case window['YT'].PlayerState.ENDED:
        console.log('ended ');
        break;
    };
  };

  cleanTime() {
    return Math.round(this.player.getCurrentTime())
  };

  onPlayerError(event:any) {
    switch (event.data) {
      case 2:
        console.log('' + this.video)
        break;
      case 100:
        break;
      case 101 || 150:
        break;
    };
  };

  async getVdoInstructionYoutubeId(): Promise<APIResultstring> {
    return WSCall.wsCall(
        () => {
          return this._publicMiscControllerService.getLoggedInVdoInstructionCheckLicenseYoutubeIdUsingGET1()
        },
        respBody => {
          return respBody;
        }
    );
  }

}
