
<meta name="viewport" content="width=device-width, initial-scale=1.0, user-scalable=yes, minimal-ui">
<div class="container text-center">
    <div id="containerView" class="embed-responsive embed-responsive-16by9 text-center">
    <ng2-pdfjs-viewer #pdfViewerAutoLoad [openFile]="false"
                      [fullScreen]="true"
                      [viewBookmark]="false"
                      [lastPage]="false"
                      style="display: block;">
    </ng2-pdfjs-viewer>
    </div>
</div>

