import { Component, OnInit, ViewChild } from '@angular/core';
import { RegisterICTemporaryAccountModel } from "@proflink/prof-link-web-ic-api-ts-angular/model/registerICTemporaryAccountModel";
import { APIResultMapstringstring } from "@proflink/prof-link-web-ic-api-ts-angular/model/aPIResultMapstringstring";
import * as WSCall from "@app/utils/WSCall";
import { AccountControllerService } from "@proflink/prof-link-web-ic-api-ts-angular/api/accountController.service";
import { PublicMiscControllerService } from "@proflink/prof-link-web-ic-api-ts-angular/api/publicMiscController.service";
import { ProvinceModel } from "@proflink/prof-link-web-ic-api-ts-angular/model/provinceModel";
import { SessionControllerService } from "@proflink/prof-link-web-ic-api-ts-angular/api/sessionController.service";
import { SessionModel } from "@proflink/prof-link-web-ic-api-ts-angular/model/sessionModel";
import { AppState } from "@core/app-state";
import { IcProfileModel } from "@proflink/prof-link-web-ic-api-ts-angular/model/icProfileModel";
import { ApplicationConfigModel } from "@proflink/prof-link-web-ic-api-ts-angular/model/applicationConfigModel";
import { ApplicationConfigControllerService } from "@proflink/prof-link-web-ic-api-ts-angular/api/applicationConfigController.service";
import { NgbCarousel } from '@ng-bootstrap/ng-bootstrap';
import { ResultSessionModel } from "@proflink/prof-link-web-ic-api-ts-angular/model/resultSessionModel";
import { Router } from "@angular/router";
import { TrainingDateModel } from "@proflink/prof-link-web-ic-api-ts-angular/model/trainingDateModel";
import { RegisterSessionControllerService } from "@proflink/prof-link-web-ic-api-ts-angular/api/registerSessionController.service";
import {APIResultboolean} from '@proflink/prof-link-web-ic-api-ts-angular/model/aPIResultboolean';
import {IcProfileControllerService} from '@proflink/prof-link-web-ic-api-ts-angular/api/icProfileController.service';
import {APIResultForceUpdateResponse} from '@proflink/prof-link-web-ic-api-ts-angular/model/aPIResultForceUpdateResponse';
import {ForceUpdateResponse} from '@proflink/prof-link-web-ic-api-ts-angular/model/forceUpdateResponse';



@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(
    private _publicMiscControllerService: PublicMiscControllerService,
    private _sessionControllerService: SessionControllerService,
    private _appState: AppState,
    private _router: Router,
    private _applicationConfigControllerService: ApplicationConfigControllerService,
    private _registerSessionController: RegisterSessionControllerService,
    private _icProfileControllerService: IcProfileControllerService,

  ) { }

  icProfileId: number;
  icProfile:IcProfileModel=null;
  forceUpdateEmail:boolean = false;

  appConfig: { [key: string]: string; } = {};

  homeState: HomeStateEnum = HomeStateEnum.HOME;
  currentDate: number = new Date().setHours(0, 0, 0, 0);
  
  resultSessionSelected: ResultSessionModel;


  async ngOnInit() {


    this._appState.loadCurrentUser();
    if (this._appState.currentUser == null || this._appState.currentUser.icId == null) {

      this._router.navigate(["login"]);
      return;
    }

    await this.loadData();


  }

  async loadData() {
    await this.loadConfig();
    await this.loadIcProfileId();
    await this.loadForceUpdateEmail();
    await this.validateOpenForceUpdateEmail(this.icProfile);

  }

  async loadIcProfileId() {
    if (this._appState.currentUser != null) {
      let icProfile: IcProfileModel = this._appState.currentUser;
      this.icProfileId = icProfile.icId;

    }

    if(this.icProfile == null){
        this.icProfile = await this.getIcProfileById(this.icProfileId);
    }




  }

  async loadConfig() {

    let applicationContain = await this.loadApplicationConfig();

    applicationContain.forEach(config => {
      this.appConfig[config.configType + '_' + config.configName] = config.configValue;
    });

  }

  async loadApplicationConfig(): Promise<Array<ApplicationConfigModel>> {

    return WSCall.wsCall(() => {
      return this._applicationConfigControllerService.loadDataUsingGET();
    }, (respBody) => {
      return respBody.data;
    });
  }

  onSelectedResult(resultSessionSelected: ResultSessionModel) {

    this.resultSessionSelected = resultSessionSelected;
    this.homeState = HomeStateEnum.COURSE_DETAIL;

  }

  onBackPage(result: boolean) {
    this.homeState = HomeStateEnum.HOME;
    this.resultSessionSelected = null;
  }

  async loadForceUpdateEmail() {
    let result: ForceUpdateResponse = await this.getForceUpdateEmail();
    if(result.forceUpdateEmail){
      this.forceUpdateEmail = result.forceUpdateEmail;
    }

  }

  async getForceUpdateEmail(): Promise<ForceUpdateResponse> {
    return WSCall.wsCall(
        () => {
          return this._publicMiscControllerService.getForceUpdateUsingGET();
        },
        respBody => {
          return respBody.data;
        }
    );
  }

  async getIcProfileById(icProfileId:number) : Promise<IcProfileModel> {

    return WSCall.wsCall(
        () => {
          return this._icProfileControllerService.getIcProfileByIdUsingGET(icProfileId);
        },
        respBody => {
          return respBody.data;
        }
    );
  }

  async validateOpenForceUpdateEmail(icProfile: IcProfileModel) {
    let canRedirect:boolean = true;
    if (this.forceUpdateEmail && icProfile.emailValidated == false) {
      await this._router.navigate(["email-validate"]);
      canRedirect = false;
    }
    return canRedirect;
  }


}

export type HomeStateEnum = 'COURSE_DETAIL' | 'HOME';
export const HomeStateEnum = {

  COURSE_DETAIL: 'COURSE_DETAIL' as HomeStateEnum,
  HOME: 'HOME' as HomeStateEnum

};
