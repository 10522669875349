import { stringify } from "querystring";

export class DateConstant {
    public static months = ['มกราคม', 'กุมภาพันธ์', 'มีนาคม', 'เมษายน', 'พฤษภาคม', 'มิถุนายน', 'กรกฎาคม', 'สิงหาคม', 'กันยายน', 'ตุลาคม', 'พฤศจิกายน', 'ธันวาคม'];
    public static getListYear(yearBefore: number, yearAfter: number): number[] {
        let result: number[] = [];
        let currentYear = new Date().getFullYear();
        for (let i = currentYear - yearBefore; i <= currentYear + yearAfter; i++) {
            result.push(i);
        }
        return result;
    }
    public static getDateYYYY_MM_DD(year: number, month: number, day: number): string {
        let monthString = month ? String(month).padStart(2, '0') : '01';
        let dayString = day ? String(day).padStart(2, '0') : '01';
        return year + "-" + monthString + "-" + dayString;
    }

    public static getDate_BE_YYYY_MM_DD(year: number, month: number, day: number): string {
        let monthString = month ? String(month).padStart(2, '0') : '00';
        let dayString = day ? String(day).padStart(2, '0') : '00';
        return (year+543) + "-" + monthString + "-" + dayString;
    }
}
