<div [@flyInAnimation]="'in'">
    <app-header-with-back class="header" [title]="title" [path]="'home'"></app-header-with-back>
    <div class="box-container">
        <div class="box">
            <section class="pt-3 px-5 text-center container" style="font-size: 1.2rem;">
                <p>สำหรับผู้ใช้งานประเภทที่ได้รับความเห็นชอบ</p>
                <p>(ผู้แนะนำการลงทุน (IC) ผู้วางแผนการลงทุน (IP) และนักวิเคราะห์การลงทุน)</p>
            </section>

            <div class="d-flex flex-column">
                <!-- <div class="embed-responsive embed-responsive-4by3"> -->
                    <div class="bloghtml" [innerHTML]="textHtml"></div>
                <!-- </div> -->
                <div class="p-2 flex-fill">
                    <label class="p-0">วันที่</label>
                    <label class="p-1">{{agreeConsentDateDisplay}}</label>
                </div>
                <div class="p-2 flex-fill">
                    <a href="https://www.set.or.th/th/privacy-notice" target="_blank">การคุ้มครองข้อมูลส่วนบุคคล
                        ของกลุ่มตลาดหลักทรัพย์แห่งประเทศไทย
                        <div>
                            <ul>
                                <li>คำประกาศเกี่ยวกับความเป็นส่วนตัว (Privacy Notice)</li>
                                <li>นโยบายการใช้คุกกี้ (Cookies Policy)</li>
                                <li>การใช้สิทธิของเจ้าของข้อมูลส่วนบุคคล</li>
                            </ul>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>