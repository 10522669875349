import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-behing-id-card-modal',
  templateUrl: './behing-id-card-modal.component.html',
  styleUrls: ['./behing-id-card-modal.component.scss']
})
export class BehingIdCardModalComponent implements OnInit {
  @Input() name:string;

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {
  }

}
