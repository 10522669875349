<div class="list-group">
  <a [routerLink]="'privacy-setting'" class="list-group-item list-group-item-action">
    <i class="fas fa-lock"></i>
    <span>&nbsp;&nbsp; ตั้งค่าความเป็นส่วนตัว<i class="fas fa-chevron-right float-right"></i></span>
  </a>
  <!-- <a [routerLink]="'language-setting'" class="list-group-item list-group-item-action">
    <i class="fas fa-language"></i>
    <span>&nbsp;&nbsp; ตั้งค่าภาษา <p class="float-right m-0">{{language}}</p></span>
  </a>-->
  <a [routerLink]="'contact-us'" class="list-group-item list-group-item-action">
    <i class="fas fa-envelope"></i>
    <span>&nbsp;&nbsp; ติดต่อเรา <i class="fas fa-chevron-right float-right"></i></span>
  </a>
  <a [routerLink]="'about-us'" class="list-group-item list-group-item-action">
    <i class="fas fa-info-circle"></i>
    <span>&nbsp;&nbsp; เกี่ยวกับเรา <i class="fas fa-chevron-right float-right"></i></span>
  </a> 
  <a [routerLink]="'condition-and-agreement'"
    class="list-group-item list-group-item-action">
    <i class="fas fa-exclamation-circle"></i>
    <span>&nbsp;&nbsp; เงื่อนไขข้อตกลงการใช้งานระบบ Prof. Link <i class="fas fa-chevron-right float-right"></i></span>
  </a>
  <br>
  <a class="list-group-item list-group-item-action text-center text-danger bg-light" (click)="logout()">ออกจากระบบ</a>
</div>

