import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { RegisterICTemporaryAccountModel } from "@proflink/prof-link-web-ic-api-ts-angular/model/registerICTemporaryAccountModel";
import { CreateICTemporaryAccountModel } from "@proflink/prof-link-web-ic-api-ts-angular/model/createICTemporaryAccountModel";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { IcProfileModel } from "@proflink/prof-link-web-ic-api-ts-angular/model/icProfileModel";
import { sha256, sha224 } from 'js-sha256';
import { AuthProvider } from "@core/auth/auth-provider.service";
import * as Utils from '@app/utils/Utils.ts';

@Component({
  selector: 'app-create-pin-share',
  templateUrl: './create-pin-share.component.html',
  styleUrls: ['./create-pin-share.component.scss']
})
export class CreatePinShareComponent implements OnInit {

  constructor(private fb: FormBuilder,
    private _authProvider: AuthProvider
  ) { }
  @ViewChild('password')
  password: ElementRef;

  @ViewChild('confirmPassword')
  confirmPassword: ElementRef;

  @Input("icProfile")
  icProfile: IcProfileModel;

  @Input("otpToken")
  otpToken: string;

  @Input("laserId")
  laserId: string;

  @Input("createType")
  createType: string;

  public createUserForm: FormGroup;

  canSubmit: boolean = false;

  get f() {
    return this.createUserForm.controls;
  }

  @Output('completeState')
  completeState: EventEmitter<CreateICTemporaryAccountModel> = new EventEmitter();

  ngOnInit() {
    this.createForm();
  }

  createForm() { 
    this.createUserForm = this.fb.group({
      password: ['', Validators.compose([Validators.required, Validators.minLength(6), Validators.pattern(/^\d{6}$/)])],
      confirmPassword: ['', Validators.compose([Validators.required, Validators.minLength(6), Validators.pattern(/^\d{6}$/)])],
      email: ['']
    });

    this.createUserForm.valueChanges.subscribe((changeObject) => {
      this.canSubmit = this.createUserForm.valid;
    });
  }

  async validatePIN() {
    let password = this.password.nativeElement.value;
    let confirmPassword = this.confirmPassword.nativeElement.value;

    if (password.trim() === '') {
      Utils.error('กรุณกรอก รหัส PIN', '', () => {
        this.password.nativeElement.focus();
      });
      return;
    }

    if (confirmPassword.trim() === '') {
      Utils.error('กรุณากรอก ยืนยันรหัส PIN', '', () => {
        this.confirmPassword.nativeElement.focus();
      });
      return;
    };

    if (password.trim() === confirmPassword.trim()) {

      let deviceToken = await this._authProvider.validateAndCreateDeviceToken();
      let pass = this.createUserForm.value.password + '';
      let createICTemporaryAccount: CreateICTemporaryAccountModel = {
        icId: this.icProfile.icId,
        otpToken: this.otpToken,
        password: sha256(pass),
        uniqueToken: deviceToken,
        laserId: this.laserId,
        createType: this.createType
      };

      this.completeState.emit(createICTemporaryAccount);
    } else {
      Utils.error('การยืนยันรหัส PIN ไม่ตรงกัน', '', () => {
        this.confirmPassword.nativeElement.focus();
      });
      return;
    }
  }

  async submitCreatePIN() {
    this.validatePIN();
    // let deviceToken = await this._authProvider.validateAndCreateDeviceToken();
    // let pass = this.createUserForm.value.password + '';
    // let createICTemporaryAccount: CreateICTemporaryAccountModel = {
    //   icId: this.icProfile.icId,
    //   otpToken: this.otpToken,
    //   password: sha256(pass),
    //   uniqueToken: deviceToken,
    //   laserId: this.laserId,
    //   createType: this.createType
    // }

    // this.completeState.emit(createICTemporaryAccount);


  }

}
