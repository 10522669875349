<div class="p-0">
    <div *ngIf="trainingProviderContact != null" class="card m-3">
        <h5 class="card-title pl-3 pt-3">ติดต่อสอบถาม</h5>
        <div class="card-body">
            <div class="flex-column d-flex">
                <div class="p-0">
                    {{trainingProviderContact.title}}
                </div>

                <div class="p-0">
                   {{trainingProviderContact.contact}}
                </div>
            </div>
        </div>
    </div>

    <div class="card m-3 p-0">
        <div class="card-body">
            <div class="flex-column d-flex">

                <div class="p-0">
                    <label class="location">สถานที่อบรม</label>
                </div>

                <div></div>

                <div *ngFor="let mpLocation of mapTrainingLocation | keyvalue" class="p-0 m-2">
                    <label>{{mpLocation.value[0].trainingLocation.name}}</label>
                    <ul class="m-0">
                        <li *ngFor="let td of mpLocation.value">
                            {{getDateTimeDisplay(td.trainingDate)}}
                        </li>
                    </ul>
                </div>

                <div class="pt-2 text-center">
                    <div #gmap style="width:100%;height:400px">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
