<div [@flyInAnimation]="'in'">
    <app-header-with-back class="header" [title]="title" [path]="'home'"></app-header-with-back>
    <div class="box-container">
        <div class="box">
        <div class="col-sm-12">
            <div class="text-center video-custom">
                <div id="player" class="embed-responsive embed-responsive-16by9">
                    <!-- <iframe  width="420" height="315" class="embed-responsive-item" [src]='videoUrl' frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe> -->
                    <iframe width="1280" height="720" [src]='videoUrl' title="YouTube video player" frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen></iframe>
                </div>
            </div>
            <div class="mt-2 title-logo">
                Prof.<span class="text-primary title-logo">Link</span>
            </div>
            <div class="d-flex flex-column flex-fill">
                <div class="ps-2 title-header">ตลาดหลักทรัพย์แห่งประเทศไทย</div>
                <div class="ps-2 ">อาคารตลาดหลักทรัพย์แห่งประเทศไทย</div>
                <div class="ps-2 ">93 ถนนรัชดาภิเษก เขตดินแดง กรุงเทพฯ 10400</div>
                <div class="ps-2 ">โทร: 0 2009 9999</div>
            </div>
            <p>
            </p>
            <div class="d-flex ps-2">
                <a (click)="redirectToSETContactCenter()" rel="noopener noreferrer">SET Contact Center</a>

            </div>
        </div>
    </div>
    </div>
</div>
