<app-course-detail-banner (backPageCallback)="onPageCallback($event)"
        [displayTrainingDate]="displayTrainingDate" [location]="location" [holdResult]="holdResult"
        [status]="status" [session]="session"
        [platform]="platform"
        [showDataCheckInCheckout]="showDataCheckInCheckout"></app-course-detail-banner>
<div class="box-container">
    <div class="box">
        <ul ngbNav #nameDetail="ngbNav" [activeId]="activeId" class="nav-tabs nav-pills nav-justified">
            <li [ngbNavItem]="1" *ngIf="showDataCheckInCheckout">
                <a ngbNavLink>การเข้าเรียน</a>
                <ng-template ngbNavContent>
                    <app-tab-course-checkin-checkout [session]="session"
                        [registerSession]="registerSession"></app-tab-course-checkin-checkout>
                </ng-template>
            </li>

            <li [ngbNavItem]="2">
                <a ngbNavLink>หลักสูตร</a>
                <ng-template ngbNavContent>
                    <app-tab-course-detail [summarySession]="summarySession"></app-tab-course-detail>
                </ng-template>
            </li>

            <li [ngbNavItem]="3">
                <a ngbNavLink>รายละเอียด</a>
                <ng-template ngbNavContent>
                    <app-tab-course-location-detail [session]="summarySession.session"></app-tab-course-location-detail>
                </ng-template>
            </li>
        </ul>
        <div [ngbNavOutlet]="nameDetail" class="mt-2"></div>
    </div>
</div>

