import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {flyInAnimation} from '@shared/animations/animation';
import {IcProfileModel} from '@proflink/prof-link-web-ic-api-ts-angular/model/icProfileModel';
import * as WSCall from '@app/utils/WSCall';
import {IcProfileControllerService} from '@proflink/prof-link-web-ic-api-ts-angular/api/icProfileController.service';
import {IcCompanySecRoleModel} from '@proflink/prof-link-web-ic-api-ts-angular/model/icCompanySecRoleModel';
import {IcCompanyModel} from '@proflink/prof-link-web-ic-api-ts-angular/model/icCompanyModel';
import {FormGroup} from '@angular/forms';
import {ResultOTPModel} from '@proflink/prof-link-web-ic-api-ts-angular/model/resultOTPModel';
import * as Utils from '@app/utils/Utils';
import {ActivatedRoute, Router} from '@angular/router';
import {NgbNav} from '@ng-bootstrap/ng-bootstrap';
import { AppState } from '@app/core/app-state';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-personal-profile',
  templateUrl: './personal-profile.component.html',
  styleUrls: ['./personal-profile.component.scss'],
  animations: [flyInAnimation]
})
export class PersonalProfileComponent implements AfterViewInit {


  activeId:any;
  profile: IcProfileModel;
  secRoleStaff: IcCompanySecRoleModel[] = [];
  companyStaff: IcCompanyModel[] = [];
  secRoleAgent: IcCompanySecRoleModel[] = [];
  companyAgent: IcCompanyModel[] = [];
  edit: string;
  contactForm: FormGroup;
  resultOTPModel: ResultOTPModel;

  constructor(private icProfileService: IcProfileControllerService,
              private activatedRoute: ActivatedRoute,
              private _appState: AppState,
              private _router: Router) {
  }


  ngOnInit(): void {
    if(this.activatedRoute.snapshot.params.tabSelectedId != null){

      let tabSelectedId:any  = this.activatedRoute.snapshot.params.tabSelectedId;
      this.activeId = +tabSelectedId;

    }
    else{
      this.activeId = 1;
    }

  }

  ngAfterViewInit(){

    this.loadData();


  }

  onChangeTab(tabIndex: number): void {
    this.activeId = tabIndex;
  }

  async loadData(): Promise<void> {
    const icId = +(localStorage.getItem('ic_id'));
    this.profile = await this.getIcProfileById(icId);
    if (this.profile) {
      this._appState.setCurrentUser(this.profile);
    }
    this.workStaff();
    this.workAgent();
  }

  workStaff(): void {
    const listIcCompanyStaff = this.profile.listIcCompanyStaff;
    this.secRoleStaff = [];
    this.companyStaff = [];
    if (listIcCompanyStaff != null && listIcCompanyStaff.length > 0) {
      listIcCompanyStaff.forEach(icCompany => {
        this.companyStaff.push(icCompany);
        icCompany.icCompanySecRoles.forEach(secRole => {
          this.secRoleStaff.push(secRole);
        });
      });
    }
  }
  workAgent(): void {
    const listIcCompanyAgent = this.profile.listIcCompanyAgent;
    this.secRoleAgent = [];
    this.companyAgent = [];
    if (listIcCompanyAgent != null && listIcCompanyAgent.length > 0) {
      listIcCompanyAgent.forEach(icCompany => {
        this.companyAgent.push(icCompany);
        icCompany.icCompanySecRoles.forEach(secRole => {
          this.secRoleAgent.push(secRole);
        });
      });
    }
  }

  editForm(form: string): void {
    this.edit = form;
  }
  editSuccess(reload: boolean): void {
    this.edit = null;
    if (reload) {
      Utils.success('สำเร็จ', 'ทำรายการสำเร็จ', '', () => {
        this.loadData();
      });
    }
  }

  requestMoveCompanySuccess(reload: boolean){
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-warning pl-3 pr-3 m-2",
        cancelButton: "btn btn-light pl-3 pr-3 m-2"
      },
      buttonsStyling: false
    });

    swalWithBootstrapButtons.fire({
      text: "ระบบได้แจ้งคำไปยังสังกัดที่ท่านระบุแล้ว กรุณาติดต่อความคืบหน้าผ่านทาง Notification",
      icon: "warning",
      showCancelButton: false,
      confirmButtonText: "รับทราบ"
    }).then(x => {
      this.loadData();
    });
  }


async getIcProfileById(icProfileId: number): Promise<IcProfileModel> {

    return WSCall.wsCall(
        () => {
          return this.icProfileService.getIcProfileByIdUsingGET(icProfileId);
        },
        respBody => {
          return respBody.data;
        }
    );
  }

  verifyOTP(contact: any): void {
    this.edit = 'requestOTP';
    this.contactForm = contact.form;
    this.resultOTPModel = contact.resultOTPModel;
  }

  onVerifyOTPSuccess(token: string): void {
    // this.edit = null;
    window.location.href = '/profile/personal/4';
    // this._router.navigate(["/profile/personal/1"]).then(r => console.log("redirect"));
  }
}
