import {HammerGestureConfig} from "@angular/platform-browser";
import * as Hammer from "hammerjs";

export class MyHammerConfig extends HammerGestureConfig {
    overrides = <any>{
        swipe: { direction: Hammer.DIRECTION_HORIZONTAL},
    };

    buildHammer(element: HTMLElement) {
        let mc = new Hammer(element, {
            touchAction: "pan-y",
        });
        return mc;
    }
}
