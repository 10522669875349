
<div class="box-container">
    <div class="box">
        <app-banner-profile></app-banner-profile>
        <app-license-info></app-license-info>
        <app-digital-passport-short></app-digital-passport-short>
        <app-news-feed></app-news-feed>
        <app-upcoming-course (selectedResult)="onSelectedResult($event)"></app-upcoming-course>
    </div>
</div>
