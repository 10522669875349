import { Component, OnInit } from '@angular/core';
import {flyInAnimation} from '@shared/animations/animation';
import {IcProfileModel} from '@proflink/prof-link-web-ic-api-ts-angular/model/icProfileModel';
import * as WSCall from '@app/utils/WSCall';
import {IcProfileControllerService} from '@proflink/prof-link-web-ic-api-ts-angular/api/icProfileController.service';
import {FileStoreModel} from '@proflink/prof-link-web-ic-api-ts-angular/model/fileStoreModel';
import {DatePipe} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import {ProtectedMiscControllerService} from '@proflink/prof-link-web-ic-api-ts-angular/api/protectedMiscController.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-digital-passport',
  templateUrl: './digital-passport.component.html',
  styleUrls: ['./digital-passport.component.scss'],
  animations: [flyInAnimation]
})
export class DigitalPassportComponent implements OnInit {

  public qrCodeIcProfile: string = "invalid";

  public bgColor:string = "#009FC2";

  intervalQrCodeId:any;

  disableQrCode:boolean = false;
  flagExpired:boolean = false;

  icProfile:IcProfileModel




  constructor(
      private _icProfileControllerService:IcProfileControllerService,
      private _datePipe: DatePipe,
      protected _httpClient: HttpClient,
      private _protMiscService: ProtectedMiscControllerService,
      private _modalService: NgbModal
  ){

  }

  ngOnInit () {

    this.loadData();

    this.intervalQrCodeId = setInterval(async () => {
      if (!this.disableQrCode) {
        this.qrCodeIcProfile = await this.generateDigitalPassportQrCode();
      }
    }, 270000);

  }

  async loadData() {

    let icId = +(localStorage.getItem("ic_id"));
    if (icId == 0) {
      return;
    }

    this.icProfile = await this.getIcProfileById(icId);

    if(this.icProfile == null){
      return;
    }

    let currentDate = new Date();
    let digitalPassportExpiredTime:Date = this.icProfile.digitalPassportExpiredDate;
    let digitalPassportExpired = new Date(digitalPassportExpiredTime);


    this.bgColor = this.icProfile.digitalPassportStatusHexColor;

    if(digitalPassportExpiredTime == null){
      this.disableQrCode = true;
    }
    else if(digitalPassportExpired != null && currentDate.getTime() > digitalPassportExpired.getTime()){
      this.disableQrCode = true;
      this.flagExpired = true;
    }

    if(this.icProfile.requireIdCard || this.icProfile.requireDoc){
      this.flagExpired = false;
    }

    if(!this.disableQrCode){
      this.qrCodeIcProfile = await this.generateDigitalPassportQrCode();
    }

  }


  async getIcProfileById(icProfileId:number) : Promise<IcProfileModel> {

    return WSCall.wsCall(
        () => {
          return this._icProfileControllerService.getIcProfileByIdUsingGET(icProfileId);
        },
        respBody => {
          return respBody.data;
        }
    );
  }

  async generateDigitalPassportQrCode() : Promise<string> {

    return WSCall.wsCall(
        () => {
          return this._icProfileControllerService.generateDigitalPassportQrCodeUsingGET();
        },
        respBody => {
          return respBody.data;
        }
    );
  }

  getFile(file:FileStoreModel){

    if(file ==null){
      return '';
    }else{
      return file.downloadLink;
    }
  }


  getDisplayDate(date:Date){

    if(date ==null){
      return null;
    }

    let dateMonthExpired = this._datePipe.transform(date, 'dd MMM');
    let yearExpired = Number(this._datePipe.transform(date, 'yyyy'));
    yearExpired = yearExpired +543;

    let displayDate = dateMonthExpired+" "+yearExpired;

    return displayDate;

  }

  open(content:any) {
    this._modalService.open(content);

  }

  ngOnDestroy() {
    if (this.intervalQrCodeId) {
      clearInterval(this.intervalQrCodeId);
    }
  }

}
