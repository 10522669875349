<app-header-with-back *ngIf="platform != 'mobile'" class="header" [title]="title"
    [path]="'profile'"></app-header-with-back>
<div class="box-container">
    <div class="box">
        <div [ngClass]="passLearningResultCss">{{passLearningResultLabel}}</div>
        <div class="text-center mt-2">
            <label *ngIf="licenseCurrentPeriodResult != null" class="title">ข้อมูลสำหรับรอบการอบรมต่ออายุใบอนุญาตฯ
                เฉพาะรอบล่าสุดเท่านั้น (ปี พ.ศ.
                {{licenseCurrentPeriodResult.startPeriod+543}} - {{licenseCurrentPeriodResult.endPeriod+543}})</label>
        </div>
        <div class="card m-2 p-0">
            <div id="chartdiv" [style.width.%]="100" [style.height.px]="500"></div>
        </div>
        <div *ngIf="icLicenseSummaryV2Result != null" class="m-2 pt-2 d-flex flex-column">

            <div class="d-flex flex-column justify-content-center">

                <div class="pt-0 d-flex flex-row justify-content-center">
                    <label class="title">การต่ออายุใบอนุญาตฯ ต้องอบรมครบตามเกณฑ์ทั้ง 3 เงื่อนไข</label>
                </div>
            </div>
            <div class="p-0 m-1 d-flex flex-row flex-fill justify-content-start">

                <div class="p-0 m-1">
                    <div class="line-condition">

                    </div>
                </div>

                <div class="p-1 flex-column flex-fill">
                    <label class="text-condition-1" style="width: 150px;"></label>
                </div>

                <div class="p-0 d-flex flex-fill justify-content-around">
                    จำนวนชั่วโมงสะสม
                </div>
            </div>
            <div class="p-0 m-1 d-flex flex-row flex-fill justify-content-start">

                <div class="p-0 m-1">
                    <div class="line-condition line-color-condition-1">

                    </div>
                </div>

                <div class="p-0 flex-column flex-fill">
                    <div class="p-0">
                        <label class="text-condition-1">เงื่อนไขที่ 1</label>
                    </div>

                    <div class="p-0">
                        <label class="text-condition-2">ความรู้กฏระเบียบ จรรยาบรรณฯ</label>
                    </div>

                    <div class="p-0">
                        <label class="text-condition-3">(ตามเกณฑ์บังคับอย่างน้อย
                            {{icLicenseSummaryV2Result.requiredEthicMinutes/60| number:'1.0-2'}} ชั่วโมง)</label>
                    </div>
                </div>

                <div class="p-0 d-flex flex-fill justify-content-center">

                    <div v class="p-0 m-0 box-hours"
                        [style]="'background-color :'+icLicenseSummaryV2Result.ethicStatusHexColor">
                        <label>{{icLicenseSummaryV2Result.profLinkTotalEthicMinutes/60| number:'1.0-2'}}</label>
                    </div>
                </div>
            </div>


            <div class="p-0 m-1 d-flex flex-row flex-fill justify-content-start">

                <div class="p-0 m-1">
                    <div class="line-condition line-color-condition-2">

                    </div>
                </div>

                <div class="p-0 flex-column flex-fill">
                    <div class="p-0">
                        <label class="text-condition-1">เงื่อนไขที่ 2</label>
                    </div>
                    <div class="p-0">
                        <label class="text-condition-1">ความรู้ด้าน ESG</label>
                    </div>

                    <div class="p-0">
                        <label class="text-condition-3">(ตามเกณฑ์บังคับอย่างน้อย
                            {{icLicenseSummaryV2Result.requiredEsgMinutes/60| number:'1.0-2'}} ชั่วโมง)</label>
                    </div>
                </div>

                <div class="p-0 d-flex flex-fill justify-content-center">
                    <div class="p-0 m-0 box-hours"
                        [style]="'background-color :'+icLicenseSummaryV2Result.esgStatusHexColor">
                        <label>{{icLicenseSummaryV2Result.profLinkTotalEsgMinutes/60| number:'1.0-2'}} </label>
                    </div>
                </div>
            </div>

            <div class="p-0 m-1 d-flex flex-row flex-fill justify-content-start">

                <div class="p-0 m-1">
                    <div class="line-condition">

                    </div>
                </div>

                <div class="p-0 flex-column flex-fill">
                    <div class="p-0">
                        <label class="text-condition-2">ความรู้ด้านอื่นๆ</label>
                    </div>

                    <div class="p-0">
                        <label class="text-condition-3">รายละเอียดการอบรมความรู้ด้านอื่น ๆ</label>
                    </div>
                </div>

                <div class="p-0 d-flex flex-fill justify-content-center">
                    <div class="p-0 m-0 box-hours"
                        [style]="'background-color :'+icLicenseSummaryV2Result.generalStatusHexColor">
                        <label>{{icLicenseSummaryV2Result.profLinkTotalGeneralMinutes/60| number:'1.0-2'}}</label>
                    </div>
                </div>
            </div>

            <div class="p-0 m-1 d-flex flex-row flex-fill justify-content-start">

                <div class="p-0 m-1">
                    <div class="line-condition line-color-condition-3">

                    </div>
                </div>

                <div class="p-0 flex-column flex-fill">
                    <div class="p-0">
                        <label class="text-condition-1">เงื่อนไขที่ 3</label>
                    </div>

                    <div class="p-0">
                        <label class="text-condition-2">ชั่วโมงสะสม</label>
                    </div>

                    <div class="p-0">
                        <label class="text-condition-3">ตามเกณฑ์บังคับอย่างน้อย
                            {{icLicenseSummaryV2Result.requiredMinutes/60|
                            number:'1.0-2'}}
                            ชั่วโมง</label>
                    </div>
                </div>

                <div class="p-0 d-flex flex-fill justify-content-center">
                    <div class="p-0 m-0 box-hours"
                        [style]="'background-color :'+icLicenseSummaryV2Result.acquiredStatusHexColor">
                        <label>{{icLicenseSummaryV2Result.profLinkTotalAcquiredMinutes/60| number:'1.0-2'}}</label>
                    </div>
                </div>
            </div>
            <div class="p-3 text-muted remark-text">
                *หมายเหตุ : ข้อมูลจำนวนชั่วโมงอบรมต่ออายุใบอนุญาตฯ ที่ปรากฎอยู่ใน Prof.Link นี้
                เป็นการเชื่อมโยงข้อมูลและประมวลผลเพื่อความสะดวกในการใช้ข้อมูลเท่านั้น
                กรณีที่ท่านต้องการข้อมูลรับรองอย่างเป็นทางการ กรุณาตรวจสอบเพิ่มเติมกับทางสำนักงาน ก.ล.ต.
            </div>
            <div class="mb-3 text-center">
                <a class="text-warning" [routerLink]="'/profile/license-status-detail'">ดูรายละเอียด</a>
            </div>
        </div>
    </div>
</div>