<div class="container">
    <div class="col-sm-12 pt-4 ">
        <div [formGroup]="icForm" class="form-group">

            <div class="col-sm-12 border-custom m-2">
                <div class="col-sm-6 offset-md-3 col-xs-12 pt-3">
                    <div class="col-xs-12">
                        <label class="change-mobile-info">เรื่อง : แจ้งเปลี่ยนแปลงเบอร์มือถือ</label>
                    </div>

                </div>
                <div class="col-sm-6 offset-md-3 col-xs-12">
                    <div class="col-xs-12">
                        <label class="change-mobile-info required">ชื่อ</label>
                    </div>

                    <div class="col-xs-12 text-left">
                        <input class="form-control" formControlName="firstName" />
                        <div *ngIf="f.firstName.touched && f.firstName.invalid">
                            <div class="text-danger" *ngIf="f.firstName.errors.required">กรุณากรอกชื่อ</div>
                        </div>
                    </div>
                </div>

                <div class="col-sm-6 offset-md-3 col-xs-12">
                    <div class="col-xs-12">
                        <label class="change-mobile-info">ชื่อกลาง</label>
                    </div>

                    <div class="col-xs-12 text-left">
                        <input class="form-control" formControlName="middleName" />
                    </div>
                </div>

                <div class="col-sm-6 offset-md-3 col-xs-12">
                    <div class="col-xs-12">
                        <label class="change-mobile-info required">นามสกุล</label>
                    </div>

                    <div class="col-xs-12 text-left">
                        <input class="form-control" formControlName="lastName" />
                        <div *ngIf="f.lastName.touched && f.lastName.invalid">
                            <div class="text-danger" *ngIf="f.lastName.errors.required">กรุณากรอกนามสกุล</div>
                        </div>
                    </div>
                </div>

                <div class="col-sm-6 offset-md-3 col-xs-12">
                    <div class="col-xs-12">
                        <label class="change-mobile-info required">เลขบัตรประจำตัวประชาชน</label>
                    </div>

                    <div class="col-xs-12 text-left">
                        <input class="form-control" formControlName="idCard" maxlength="13" />
                        <div *ngIf="f.idCard.touched && f.idCard.invalid">
                            <div class="text-danger" *ngIf="f.idCard.errors.required">กรุณากรอกเลขบัตรประจำตัวประชาชน</div>
                            <div class="text-danger" *ngIf="f.idCard.errors.pattern">กรุณากรอกตัวเลข 13 หลัก</div>
                            <div class="text-danger" *ngIf="f.idCard.errors.formatCorrect">เลขบัตรประจำตัวประชาชนไม่ถูกต้อง
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-sm-6 offset-md-3 col-xs-12">
                    <div class="col-xs-12">
                        <label class="change-mobile-info required">เบอร์มือถือเดิม</label>
                    </div>

                    <div class="col-xs-12 text-left">
                        <input type="text" class="form-control required" formControlName="mobilePhoneNumber"
                            maxlength="10">
                        <div *ngIf="f.mobilePhoneNumber.touched && f.mobilePhoneNumber.invalid">
                            <div class="text-danger" *ngIf="f.mobilePhoneNumber.errors.required">กรุณากรอก
                                เบอร์มือถือเดิม</div>
                            <div class="text-danger" *ngIf="f.mobilePhoneNumber.errors.pattern">กรุณากรอกเฉพาะตัวเลขให้ครบ 10 หลัก
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-sm-12 text-center pt-4 pb-4">
                    <button class="btn btn-primary pl-5 pr-5" [disabled]="!canSubmit"
                        (click)="submitForm()">ยืนยันข้อมูล</button>
                </div>
            </div>
        </div>
    </div>
</div>