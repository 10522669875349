import {Component, Input, OnInit} from '@angular/core';
import {NewsCategoryModel} from "@proflink/prof-link-web-ic-api-ts-angular/model/newsCategoryModel";
import * as WSCall from "@app/utils/WSCall";
import {PublicMiscControllerService} from "@proflink/prof-link-web-ic-api-ts-angular/api/publicMiscController.service";
import {NewsModel} from "@proflink/prof-link-web-ic-api-ts-angular/model/newsModel";
import {NewsTagModel} from "@proflink/prof-link-web-ic-api-ts-angular/model/newsTagModel";
import {LocalDatePipe} from "@app/utils/local-date-pipe";

@Component({
  selector: 'app-news-and-activity-by-category',
  templateUrl: './news-and-activity-by-category.component.html',
  styleUrls: ['./news-and-activity-by-category.component.scss']
})
export class NewsAndActivityByCategoryComponent implements OnInit {

  constructor(
      private _publicMiscControllerService: PublicMiscControllerService,
      private _localDate : LocalDatePipe

  ) { }


  @Input("categoryId")
  categoryId:number;

  //
  //
  // set setCategoryId(categoryId:number){
  //   if(categoryId != null){
  //     this.categoryId = categoryId;
  //   }
  // }

  news:Array<NewsModel> = [];

  ngOnInit() {

    this.loadData();

  }

  async loadData(){

    this.news = await this.getNewsByCategoryId(this.categoryId);

  }

  async getNewsByCategoryId(categoryId:number): Promise<Array<NewsModel>> {
    return WSCall.wsCall(
        () => {
          return this._publicMiscControllerService.getNewsByCategoryIdUsingGET(categoryId);
        },
        respBody => {
          return respBody.data;
        }
    );
  }

  getDisplayTag(newsTags:Array<NewsTagModel>){

    let display:string = '';
    newsTags.forEach(object => {

      if(display == ''){
        display = display + object.name;
      }else{
        display = display +','+ object.name;
      }

    });

    return display;

  }

  getDisplayDate(date:Date){


    return this._localDate.transform(date,"dd/MM/yyyy");
  }

  getDownloadLink(newModel:NewsModel){

    let downloadLink = '';
    if(newModel.cover != null && newModel.cover.downloadLink != null){
      downloadLink = newModel.cover.downloadLink;
    }


    return downloadLink;

  }

  openUrl(url:string){


    window.open(url);

  }

}
