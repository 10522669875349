<div *ngFor="let rs of resultSessionNonProflinkSortData" class="card">
    <div class="flex-row d-inline-flex">
        <div class="p-0 flex-column">
            <div class="pl-2 pt-3">
                <img width="128px" height="80px" src="../../../../assets/image/session_cover_placeholder.png" />
            </div>
        </div>

        <div class="p-2 flex-column flex-fill">
            <div class="p-0">
                <label>{{rs.course!=null?rs.course.courseName:'-'}}</label>
            </div>

            <div class="p-0 flex-row flex-fill d-inline-flex">
                <div class="p-0 flex-column">
                    <div class="p-0">
                        <i class="far fa-calendar-alt pr-2"></i>
                        <label class="text-left">{{getTrainingDateDisplay(rs.trainingDate)}}</label>
                    </div>

                    <div class="p-0">
                        <i class="fa fa-clock pr-2"></i>
                        <label class="text-left">{{rs.periodMins/60}} ชั่วโมง</label>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="p-2 flex-row d-inline-flex">
        <div class="pl-2 text-center" style="width: 128px;">
            <label>
                การอบรม
            </label>
        </div>

        <div class="p-0 flex-fill text-right">
            <label>{{rs.trainingProviderModel!=null?rs.trainingProviderModel.trainingProviderName:'-'}}</label>
        </div>
    </div>
  
</div>