
<div class="container col-sm-12 custom-container">
        <div class="col-sm-12 pt-4">
            <div class="col-sm-12 text-center">
                <label>ระบบได้ส่ง <b>SMS</b> ไปยังหมายเลข</label>
            </div>

            <div class="col-sm-12 text-center">
                <label>{{mobileNumber}}</label>
            </div>
            <div *ngIf="email" class="col-sm-12 text-center">
                <label>และอีเมล {{email}}</label>
            </div>
            <div class="col-sm-12 text-center">
                <label>กรุณากรอกหมายเลข OTP  4 หลัก</label>
            </div>
        </div>

        <div class="col-sm-12 pt-4 text-center">
            <div class="col-sm-12 col-xs-12 offset-md-3 col-md-6 offset-md-3">
                <input id="otpNumber" type="number" #otpInput (keyup)="validateInputAndSubmitOtp()">
            </div>
        </div>


        <div class="col-sm-12 pt-4">
            <div class="col-sm-12 text-center">
                <label>Ref Code : {{refCode}}</label>
            </div>

        </div>

        <div class="col-sm-12 text-center pt-4">
            <div class="col-sm-12 col-xs-12 offset-md-3 col-md-6 offset-md-3">
                <button type="button" class="btn btn-block btn-login" [disabled]="!canRequestOtp" (click)="requestOtp()">
                    <label style="color: black;">ขอ OTP ใหม่</label>
                </button>
            </div>
        </div>

        <div class="col-sm-12 text-center pt-3">
            <div class="col-sm-12 text-center">
                <label>OTP จะหมดอายุใน  {{counter | formatTime}} นาที</label>
            </div>
        </div>
    </div>
