
<div class="content-wrapper">
    <!--    <app-header-with-banner class="header" [title]="'test'"></app-header-with-banner>-->

    <app-header-with-back class="header"
                           [path]="'/home'"
                           [title]="title"></app-header-with-back>

    <div class="col-sm-12" style="max-height: 800px; overflow-y: scroll;" *ngIf="!selected">
        <div *ngFor="let rs of upcomingCourses" class="card flex-column" (click)="getCourseDetail(rs)">

        <div class="flex-row d-inline-flex">
            <div class="p-0 flex-column">
                <div class="pl-2 pt-3">
                    <img width="128px" height="80px" src="../../../../assets/image/session_cover_placeholder.png"/>
                </div>
            </div>

            <div class="p-2 flex-column flex-fill">
                <div class="p-0">
                    <label>{{rs.session.sessionName}}</label>
                </div>

                <div class="p-0 flex-row flex-fill d-inline-flex">
                    <div class="p-0 flex-column">
                        <div class="p-0">
                            <i class="far fa-calendar-alt pr-2"></i>
                            <label class="text-left">{{getPeriodTrainingDayDisplay(rs.session.trainingDates)}}</label>
                        </div>

                        <div class="p-0">
                            <i class="fa fa-map-marker-alt pr-2"></i>
                            <label class="text-left">{{rs.session.province.provinceTh}}</label>
                        </div>
                    </div>
                    <div class="p-0 flex-fill text-right pl-1">
                        <img width="50px" height="50px" [src]="getFile(rs.session.trainingProvider.logoFile)" onerror="this.src='../../../../assets/image/bg-gray.png'"/>
                    </div>
                </div>
            </div>
        </div>

        <div class="p-2 flex-row d-inline-flex">
            <div class="pl-2 text-center" style="width: 128px;">
                <label style="color: orange">
                    Free
                </label>
            </div>

            <div class="p-0 flex-fill text-right">
                <label>{{rs.session.trainingProvider.trainingProviderName}}</label>
            </div>
        </div>
    </div>
    </div>

    <app-my-course-detail-share *ngIf="selected"
            [resultSessionModel]="resultSessionSelected"
            (backPageCallback)="backPageCallBack($event)"></app-my-course-detail-share>



</div>
