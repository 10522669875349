<app-header-with-back class="header" [title]="'เอกสารยืนยันตัวตน'" [path]="'my-course-detail-share/' + sessionId +'/1/mycourse'" ></app-header-with-back>

<div class="box-container">
    <div class="box">
        <div class="content p-3">
            <p>กรุณาถ่ายภาพเอกสารยืนยันตัวตนของคุณเพื่อปลดล็อคผลการอบรม</p>
            <p>สำหรับชาวไทยกรุณาถ่ายภาพด้านหน้าของบัตรประชาชนให้ชัดเจน</p>
            <p>สำหรับชาวต่างชาติ กรุณาถ่ายภาพพาสปอร์ตให้ชัดเจน</p>
            <div class="title-topic">แนบเอกสาร</div>
            <div class="scrolling-wrapper" lazy-load-images>
                <div *ngFor="let item of proofOfIdentityFiles" class="item">
                    <div class="card attachment-item" [attr.data-background-src]="item.attachmentLink"
                        (click)="openFile(item)"></div>
                    <a class="btn btn-block" (click)="removeFile(item)"><i class="fas fa-trash-alt"></i></a>
                </div>
                <div class="item cursor-pointer">
                    <div class="card attachment-item attachment-upload" (click)="upload()">
                        <i class="fas fa-paperclip fa-2x"></i>
                        <br />
                        แนบรูปภาพ
                    </div>
                </div>
            </div>
        </div>

        <div class="sticky-bottom p-3">
            <button class="btn btn-primary btn-block" (click)="save()">บันทึก</button>
        </div>
    </div>
</div>


<app-file-upload style="display: none;" #attachmentDocument="fileUpload" (fileUpdate)="onBackgroundImageChange($event)"
    [maxWidth]="'400px'" [maxHeight]="'300px'" [accept]="'image/*'">
</app-file-upload>