import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IcProfileModel} from '@proflink/prof-link-web-ic-api-ts-angular/model/icProfileModel';
import {IcCompanySecRoleModel} from '@proflink/prof-link-web-ic-api-ts-angular/model/icCompanySecRoleModel';
import {IcCompanyModel} from '@proflink/prof-link-web-ic-api-ts-angular/model/icCompanyModel';
import {APIResultstring} from '@proflink/prof-link-web-ic-api-ts-angular/model/aPIResultstring';
import * as WSCall from '@app/utils/WSCall';
import {IcProfileControllerService} from '@proflink/prof-link-web-ic-api-ts-angular/api/icProfileController.service';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'app-profile-company',
  templateUrl: './profile-company.component.html',
  styleUrls: ['./profile-company.component.scss', '../personal-profile.component.scss']
})
export class ProfileCompanyComponent implements OnInit {
  @Input() profile: IcProfileModel;
  @Input() secRoleStaff: IcCompanySecRoleModel[] = [];
  @Input() companyStaff: IcCompanyModel[] = [];
  @Input() secRoleAgent: IcCompanySecRoleModel[] = [];
  @Input() companyAgent: IcCompanyModel[] = [];
  @Output('edit') editForm: EventEmitter<string> = new EventEmitter();
  constructor(
      private _icProfileControllerService: IcProfileControllerService,
      private _datePipe: DatePipe
  ) { }



  ngOnInit(): void {

    console.log(this.profile.listIcCompanyStaff);


  }
  async edit() {

    if (this.profile != null) {
      let lastSync = new Date(this.profile.lastSyncDataFromSec);
      let currentDate = new Date();

      let lastSyncStr = this._datePipe.transform(lastSync, "dd/MM/yyyy");
      let currentDateStr = this._datePipe.transform(currentDate, "dd/MM/yyyy");

      if (lastSyncStr != currentDateStr) {
        await this.reloadDataIcFromSec(this.profile.icId);
      }
      console.log(lastSyncStr);
      console.log(currentDateStr);
    }


    this.editForm.emit('company');
  }

  async reloadDataIcFromSec(icProfileId:number) : Promise<APIResultstring> {

    return WSCall.wsCall(
        () => {
          return this._icProfileControllerService.reloadDataIcFromSecUsingGET(icProfileId);
        },
        respBody => {
          return respBody;
        }
    );
  }



}
