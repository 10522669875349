import { Component, OnInit } from '@angular/core';
import {TrainingProviderModel} from '@proflink/prof-link-web-ic-api-ts-angular/model/trainingProviderModel';

@Component({
  selector: 'app-search-training-provider-list',
  templateUrl: './search-training-provider-list.component.html',
  styleUrls: ['./search-training-provider-list.component.scss']
})
export class SearchTrainingProviderListComponent implements OnInit {

  active:number = 1;

  constructor() { }

  trainingProviders:Array<TrainingProviderModel> = [];

  ngOnInit() {

  }




}
