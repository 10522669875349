import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ELearningConsentModalComponent } from './e-learning-consent-modal/e-learning-consent-modal.component';

@Component({
  selector: 'app-banner-e-learning',
  templateUrl: './banner-e-learning.component.html',
  styleUrls: ['./banner-e-learning.component.scss']
})
export class BannerELearningComponent implements OnInit {

  constructor(private _router: Router,
    private modalService: NgbModal) { }

  ngOnInit(): void {
  }

  gotoElearning(): void {
    const acceptELearningConsent = localStorage.getItem("acceptELearningConsent")
    if (acceptELearningConsent === "1") {
      this._router.navigate(["elearning"]);
    } else {
      this.modalService.open(ELearningConsentModalComponent, {
        size: 'sm',
        backdrop: 'static'
      }).result.then((result) => {
          if (result) {
            localStorage.setItem("acceptELearningConsent", "1");
            this._router.navigate(["elearning"]);
          }
        })
    }
  }
}
