import {
    trigger,
    state,
    animate,
    transition,
    style,
} from '@angular/animations';

export const flyInAnimation = trigger('flyInAnimation', [
    state("in", style({ transform: "translateX(0)" })),
    transition("void => *", [
        style({
            position: 'absolute',
            left: 0,
            width: '100%',
            opacity: 0,
            transform: 'translateX(100%)',
        }),
        animate(200)
    ]),
    transition("* => void", [
        animate(200, style({ opacity: 1, transform: 'translateX(0)' }))
    ]),
]);

export const flyOutAnimation = trigger('flyOutAnimation', [
    state("in", style({ transform: "translateX(-100)" })),
    transition("void => *", [
        style({
            position: 'absolute',
            left: -100,
            width: '100%',
            opacity: 0,
            transform: 'translateX(-100%)',
        }),
        animate(200)
    ]),
    transition("* => void", [
        animate(200, style({ opacity: 1, transform: 'translateX(0)' }))
    ]),
]);

export const flyUpAnimation = trigger('flyUpAnimation', [
    state("in", style({ transform: "translateY(0)" })),
    transition("void => *", [
        style({
            position: 'absolute',
            bottom: 0,
            height: '100%',
            opacity: 0,
            transform: 'translateY(100%)',
        }),
        animate(200)
    ]),
    transition("* => void", [
        animate(200, style({ opacity: 1, transform: 'translateY(100)' }))
    ]),
]);

export const flyDownAnimation = trigger('flyDownAnimation', [
    state("in", style({ transform: "translateY(100)" })),
    transition("void => *", [
        style({
            position: 'absolute',
            bottom: 100,
            height: '100%',
            opacity: 0,
            transform: 'translateY(-100%)',
        }),
        animate(200)
    ]),
    transition("* => void", [
        animate(200, style({ opacity: 1, transform: 'translateY(0)' }))
    ]),
]);