import { Component, OnInit } from '@angular/core';
import {AppState} from "@core/app-state";
import {Router} from "@angular/router";

@Component({
  selector: 'app-more-menu-list',
  templateUrl: './more-menu-list.component.html',
  styleUrls: ['./more-menu-list.component.scss']
})
export class MoreMenuListComponent implements OnInit {

  language = 'ไทย';

  constructor(
      private _appState:AppState,
      private _router: Router
  ) { }

  ngOnInit(): void {
  }

  logout(){

    this._appState.logout();
    this._router.navigate(["login"]);

  }

}
