import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IcProfileModel} from '@proflink/prof-link-web-ic-api-ts-angular/model/icProfileModel';
import {DatePipe} from '@angular/common';
import {IcProfileControllerService} from '@proflink/prof-link-web-ic-api-ts-angular/api/icProfileController.service';

import * as WSCall from "@app/utils/WSCall";
import {APIResultstring} from '@proflink/prof-link-web-ic-api-ts-angular/model/aPIResultstring';
@Component({
    selector: 'app-profile-information',
    templateUrl: './profile-information.component.html',
    styleUrls: ['./profile-information.component.scss', '../personal-profile.component.scss']
})
export class ProfileInformationComponent implements OnInit {

  constructor(
      private _datePipe: DatePipe
  ) {
  }

  @Input() profile: IcProfileModel;
  @Output('edit') editForm: EventEmitter<string> = new EventEmitter();

  firstName:string='';
  lastName:string='';
  middleName:string='';
  profileLink:string = null;


  canEdit:boolean=false;

  ngOnInit(): void {
      if(this.profile != null){
          if(this.profile.nationality != null && this.profile.nationality.nationalityId == 1){
              this.canEdit = true;
          }

          this.firstName = this.profile.firstnameTh;
          this.middleName = this.profile.middlenameTh;
          this.lastName = this.profile.lastnameTh;

          if(this.profile.firstnameTh == null || this.profile.firstnameTh.trim().length == 0){
              this.firstName = this.profile.firstnameEn;
          }

          if(this.profile.middlenameTh == null || this.profile.middlenameTh.trim().length == 0){
              this.middleName = this.profile.middlenameEn;
          }

          if(this.profile.lastnameTh == null || this.profile.lastnameTh.trim().length == 0){
              this.lastName = this.profile.lastnameEn;
          }

          if(this.profile.attachedProfileFile != null){
              this.profileLink = this.profile.attachedProfileFile.attachmentLink;
          }



      }

  }

  edit(): void {
    this.editForm.emit('information');
  }



}
