<div class="wrapper" [@flyInAnimation]="'in'">
    <app-header-with-back class="header" [title]="title" [path]="'more'"></app-header-with-back>
    <div class="content">
        <div class="list-group">
            <a [routerLink]="'/profile/personal/1'" class="list-group-item list-group-item-action">
                <span>เปลี่ยนชื่อ - นามสกุล​<i class="fas fa-chevron-right float-right"></i></span>
            </a>
            <a [routerLink]="'/profile/personal/4'" class="list-group-item list-group-item-action">
                <span>เปลี่ยนเบอร์มือถือ/อีเมล<i class="fas fa-chevron-right float-right"></i></span>
            </a>

            <a [routerLink]="'/profile/personal/3'" class="list-group-item list-group-item-action">
                <span>แจ้งเปลี่ยนสังกัด​​​<i class="fas fa-chevron-right float-right"></i></span>
            </a>

            <a href="https://www.set.or.th/contactcenter" target="_blank" class="list-group-item list-group-item-action">
                <span>แจ้งปัญหาข้อมูลและการใช้งาน​<i class="fas fa-chevron-right float-right"></i></span>
            </a>
        </div>
    </div>
</div>
