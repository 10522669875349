<app-header-with-back class="header" [path]="'/elearning'" [title]="title"></app-header-with-back>
<div class="box-container">
  <div class="box">
    <div *ngIf="courseByCategory.length > 0">
      <div class="mx-3 pt-3" *ngFor="let course of courseByCategory">
        <div (click)="viewDetail(course)">
          <div class="text-center">
            <img [src]="course.thumbnail" class="img-fluid" alt="elearning-image">
          </div>
          <div class="title-topic">{{course.title}}</div>
          <div class="text-muted">{{course.short_descripton}}</div>
        </div>
        <hr>
      </div>
    </div>
    <div *ngIf="courseByCategory.length == 0" class="text-center mt-3">
      ไม่มีข้อมูล
    </div>
    
  </div>
</div>