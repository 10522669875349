import { Component, OnInit } from '@angular/core';
import { flyInAnimation } from '@app/shared/animations/animation';

@Component({
  selector: 'app-language-setting',
  templateUrl: './language-setting.component.html',
  styleUrls: ['./language-setting.component.scss'],
  animations: [flyInAnimation]
})
export class LanguageSettingComponent implements OnInit {

  title = 'ตั้งค่าภาษา'

  constructor() { }

  ngOnInit(): void {
  }

}
