import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ResultSessionModel } from '@proflink/prof-link-web-ic-api-ts-angular/model/resultSessionModel';
import { RegisterSessionModel } from '@proflink/prof-link-web-ic-api-ts-angular/model/registerSessionModel';
import { TrainingDateModel } from '@proflink/prof-link-web-ic-api-ts-angular/model/trainingDateModel';
import { DatePipe } from '@angular/common';
import { FileStoreModel } from '@proflink/prof-link-web-ic-api-ts-angular/model/fileStoreModel';
import { ELearningComponent } from '@protected/e-learning/e-learning.component';
import { SearchComponent, SearchListState } from '@protected/search/search.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-search-list',
  templateUrl: './search-list.component.html',
  styleUrls: ['./search-list.component.scss']
})
export class SearchListComponent implements OnInit {


  @Input("resultSession")
  resultSession: Array<ResultSessionModel> = [];

  @Output('selectedResult')
  selectedResultEventEmitter: EventEmitter<ResultSessionModel> = new EventEmitter();


  constructor(
    private _searchComponent: SearchComponent,
    private _datePipe: DatePipe,
    private _router: Router,
  ) { }

  ngOnInit(): void {
  }


  getDisplaySessionStatus(registerSession: RegisterSessionModel) {

    if (registerSession.session != null && registerSession.session.sessionStatus == '6L') {
      return 'ยกเลิกการจัดอบรม';
    }
    else if (registerSession.holdResult) {
      return 'ระงับผลการอบรม';
    } else if (!registerSession.holdResult) {
      return 'ลงทะเบียนสำเร็จ';
    }

  }

  getColorDisplay(registerSession: RegisterSessionModel) {
    if (registerSession.session != null && registerSession.session.sessionStatus == '6L') {
      return ''
    }
    else if (registerSession.holdResult) {
      return 'color-red';

    } else if (!registerSession.holdResult) {
      return 'color-green';
    }
  }


  getPeriodTrainingDayDisplay(trainingdays: Array<TrainingDateModel>) {

    let displayDate: string = '';
    if (trainingdays.length <= 1) {

      let trainingDate: Date = new Date(trainingdays[0].trainingDate);
      let birthDaySubmit = this._datePipe.transform(trainingDate, 'dd MMM');
      let yearTraining = Number(this._datePipe.transform(trainingDate, 'yyyy'));

      yearTraining = yearTraining + 543;

      displayDate = birthDaySubmit + ' ' + yearTraining;


    } else {

      let firstTrainingDate: Date = new Date(trainingdays[0].trainingDate);
      let lastTrainingDate: Date = new Date(trainingdays[trainingdays.length - 1].trainingDate);

      let firstDayDisplay = this._datePipe.transform(firstTrainingDate, 'dd MMM');
      let lastDayDisplay = this._datePipe.transform(lastTrainingDate, 'dd MMM');

      let yearTraining = Number(this._datePipe.transform(firstTrainingDate, 'yyyy'));

      yearTraining = yearTraining + 543;
      displayDate = firstDayDisplay + ' - ' + lastDayDisplay + ' ' + yearTraining;
    }


    return displayDate;

  }

  getFile(file: FileStoreModel) {

    if (file == null) {
      return '';
    } else {
      return file.downloadLink;
    }
  }

  getCourseDetail(resultSessionSelect: ResultSessionModel) {

    let sessionId = resultSessionSelect.session.sessionId;
    let pathBack = 'search';


    this._router.navigate([`/my-course-detail-share/${sessionId}/0/${pathBack}`]);
  }

  onBack() {
    this._searchComponent.trainingProviderIdSelected = null;
    this._searchComponent.searchState = SearchListState.SHOW_CATEGORY;
    this._router.navigate(["search"]);
  }



}
