<div *ngFor="let rs of resultSession" class="card card-item" (click)="getCourseDetail(rs)">
    <div class="flex-row d-inline-flex">
        <div class="p-0 flex-column">
            <div class="pl-2 pt-3">
                <img width="100px" height="60px" src="../../../../assets/image/session_cover_placeholder.png" />
            </div>
        </div>

        <div class="p-2 flex-column flex-fill">
            <div class="p-0">
                <label>{{rs.session.sessionName}}</label>
            </div>

            <div class="p-0 flex-row flex-fill d-inline-flex">
                <div class="p-0 flex-column">
                    <div class="p-0">
                        <i class="far fa-calendar-alt pr-2"></i>
                        <label class="text-left">{{getPeriodTrainingDayDisplay(rs.session.trainingDates)}}</label>
                    </div>

                    <div class="p-0">
                        <i class="fa fa-map-marker-alt pr-2"></i>
                        <label class="text-left">{{rs.session.province.provinceTh}}</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="p-2 d-flex align-items-end flex-column" lazy-load-images>
            <div class="image" [attr.data-background-src]="getFile(rs.session.trainingProvider.logoFile)"></div>
        </div>
    </div>

    <div class="p-2 flex-row d-inline-flex">
        <div class="text-center" style="width: 100px;">
            <label [ngClass]="getColorDisplay(rs.registerSession)">
                {{getDisplaySessionStatus(rs.registerSession)}}
            </label>
        </div>

        <div class="p-0 flex-fill text-right">
            <label>{{rs.session.trainingProvider.trainingProviderName}}</label>
        </div>
    </div>
</div>


