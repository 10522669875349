import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Router} from "@angular/router";
import {RegisterStateEnum} from "@public/register/register.component";
import {IcProfileModel} from "@proflink/prof-link-web-ic-api-ts-angular/model/icProfileModel";
import {IcLicenseModel} from "@proflink/prof-link-web-ic-api-ts-angular/model/icLicenseModel";
import {DatePipe} from "@angular/common";
import {IcCompanyModel} from "@proflink/prof-link-web-ic-api-ts-angular/model/icCompanyModel";
import {SecCheckIcThaiQueryModel} from "@proflink/prof-link-web-ic-api-ts-angular/model/secCheckIcThaiQueryModel";
import {RegisterICProfileRequest} from "@proflink/prof-link-web-ic-api-ts-angular/model/registerICProfileRequest";

@Component({
  selector: 'app-register-form-result',
  templateUrl: './register-form-result.component.html',
  styleUrls: ['./register-form-result.component.scss']
})
export class RegisterFormResultComponent implements OnInit {

  constructor(
      private _router: Router,
      private _datePipe: DatePipe
  ) { }

  ngOnInit(): void {
  }

  icProfile:IcProfileModel;

  registerICProfileRequest:RegisterICProfileRequest = {
    idCardNumber : "",
    firstName : "",
    middleName : "",
    lastName : "",
    birthday : "",
    laserId : "",
    uniqueToken : "",
    idCardExpireDate : "",
    birthdateFormat : ""
  };

  @Input("requestRegister")
  set setRequestRegister(requestRegister:SecCheckIcThaiQueryModel){

    if(requestRegister != null){

      this.registerICProfileRequest.birthDate = requestRegister.birthDate;
      this.registerICProfileRequest.birthMonth = requestRegister.birthMonth;
      this.registerICProfileRequest.birthYear = requestRegister.birthYear;
      this.registerICProfileRequest.idCardNumber = requestRegister.idCardNumber;
      this.registerICProfileRequest.firstName = requestRegister.firstName;
      this.registerICProfileRequest.middleName = requestRegister.middleName;
      this.registerICProfileRequest.lastName = requestRegister.lastName;
      this.registerICProfileRequest.birthday = requestRegister.birthday;
      this.registerICProfileRequest.laserId = requestRegister.laserId;
      this.registerICProfileRequest.uniqueToken = requestRegister.uniqueToken;
      this.registerICProfileRequest.idCardExpireDate = requestRegister.idCardExpireDate;
      this.registerICProfileRequest.birthdateFormat = requestRegister.birthdateFormat;
    }
  }

  @Input("icProfile")
  set setIcProfile(icProfileModel:IcProfileModel){
    if(icProfileModel != null){
      this.icProfile = icProfileModel;
    }
  }

  displayCompany:string='';
  displayLicenseExpired:string='';

  @Output('completeState')
  completeState: EventEmitter<RegisterICProfileRequest> = new EventEmitter();



  submitCreate(){

    this.completeState.emit(this.registerICProfileRequest);
    // this.completeState.emit(RegisterStateEnum.CREATE_USER);
  }

  getDisplayLicenseExpired(icProfileModel:IcProfileModel){

    let icLicensees:Array<IcLicenseModel> = icProfileModel.icLicenses;
    if(icLicensees != null && icLicensees.length > 0){
      let icLicense = icLicensees[icLicensees.length-1];
      let expiredDate = icLicense.expireDate;
      this.displayLicenseExpired = expiredDate!= null ? this._datePipe.transform(expiredDate, 'dd MMMM yyyy') : "-";
    }

  }

  getDisplayCompany(icProfileModel:IcProfileModel){

    let icCompanies:Array<IcCompanyModel> = icProfileModel.companies
    if(icCompanies != null && icCompanies.length > 0){

      icCompanies.forEach((icCompany) => {
        this.displayCompany = this.displayCompany + icCompany.company.nameTh + "\n";
      });
    }
  }


}
