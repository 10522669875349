import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  Injectable,
  HostListener,
  Directive,
  NgModule,
} from '@angular/core';
//import { Router } from '@angular/router';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
  NgControl,
} from '@angular/forms';
import { SecCheckIcThaiQueryModel } from '@proflink/prof-link-web-ic-api-ts-angular/model/secCheckIcThaiQueryModel';
import { AuthProvider } from '@core/auth/auth-provider.service';
import {
  NgbDate,
  NgbDateStruct,
  NgbCalendar,
  NgbDatepickerI18n,
  NgbCalendarBuddhist,
  NgbDateParserFormatter,
  NgbModal,
} from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { AppState } from '@core/app-state';
import localeThai from '@angular/common/locales/th';
import { LocalDatePipe } from '@app/utils/local-date-pipe';
import {
  getLocaleDayNames,
  FormStyle,
  TranslationWidth,
  getLocaleMonthNames,
  formatDate,
  registerLocaleData,
} from '@angular/common';
import {
  checkIDCardValidator,
  checkLaserIDValidator,
} from '@app/utils/ValidatorCustom';
import { DateConstant } from '@app/utils/DateUtils';
import { BehingIdCardModalComponent } from '@app/shared/behing-id-card-modal/behing-id-card-modal.component';
import {MiddleNameModalComponent} from '@shared/middle-name-modal/middle-name-modal.component';

@Injectable()
export class NgbDatepickerI18nBuddhist extends NgbDatepickerI18n {
  private _locale = 'th';
  private _weekdaysShort: readonly string[];
  private _monthsShort: readonly string[];
  private _monthsFull: readonly string[];
  private _dayShortName: readonly string[];

  constructor() {
    super();

    registerLocaleData(localeThai);

    const weekdaysStartingOnSunday = getLocaleDayNames(
      this._locale,
      FormStyle.Standalone,
      TranslationWidth.Short
    );
    this._weekdaysShort = weekdaysStartingOnSunday.map(
      (day, index) => weekdaysStartingOnSunday[(index + 1) % 7]
    );

    this._monthsShort = getLocaleMonthNames(
      this._locale,
      FormStyle.Standalone,
      TranslationWidth.Abbreviated
    );
    this._monthsFull = getLocaleMonthNames(
      this._locale,
      FormStyle.Standalone,
      TranslationWidth.Wide
    );
    this._dayShortName = getLocaleDayNames(
      this._locale,
      FormStyle.Standalone,
      TranslationWidth.Abbreviated
    );
  }
  getWeekdayShortName(day: number): string {
    return this._dayShortName[day] || '';
  }

  getMonthShortName(month: number): string {
    return this._monthsShort[month - 1] || '';
  }

  getMonthFullName(month: number): string {
    return this._monthsFull[month - 1] || '';
  }

  getWeekdayLabel(weekday: number) {
    return this._weekdaysShort[weekday - 1] || '';
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    const jsDate = new Date(date.year, date.month - 1, date.day);
    return formatDate(jsDate, 'fullDate', this._locale);
  }

  getYearNumerals(year: number): string {
    return String(year);
  }
}

@Directive({
  selector: '[formControlName][laserMask]',
})
@Injectable()
export class NgbDateFRParserFormatter extends NgbDateParserFormatter {
  constructor(private _localDate: LocalDatePipe) {
    super();
  }
  parse(value: string): NgbDateStruct {
    //parse receive your string dd/mm/yyy
    return { year: 2564, month: 12, day: 31 };
  }

  format(date: NgbDateStruct): string {
    //receive a NgbDateStruct
    if (date != null) {
      let dayStr = new String(date.day);
      let monthStr = new String(date.month);
      let yearStr = new String(date.year);

      dayStr = dayStr.padStart(2, '0');
      monthStr = monthStr.padStart(2, '0');

      let birthDay = `${dayStr}-${monthStr}-${yearStr}`;

      return birthDay;
    } else {
      return '';
    }
  }
}

@Component({
  selector: 'app-register-form',
  templateUrl: './register-form.component.html',
  providers: [
    { provide: NgbCalendar, useClass: NgbCalendarBuddhist },
    { provide: NgbDatepickerI18n, useClass: NgbDatepickerI18nBuddhist },
    { provide: NgbDateParserFormatter, useClass: NgbDateFRParserFormatter },
  ],
  styleUrls: ['./register-form.component.scss'],
})
export class RegisterFormComponent implements OnInit {
  model: NgbDateStruct = this._calendar.getToday();
  date: { year: number; month: number };
  minDate: NgbDateStruct = {
    year: new Date().getFullYear() + 543,
    month: new Date().getMonth() + 1,
    day: new Date().getDate(),
  };
  maxDate: NgbDateStruct = {
    year: new Date().getFullYear() + 558, //add 15 year from current year
    month: 12,
    day: 31,
  };
  listMonth: string[] = DateConstant.months;
  listYear: number[] = DateConstant.getListYear(100, 0);
  currentYear: number;
  yearOfBirth: number;

  displayMiddleName:boolean = false;
  btnDisplayMiddleName:string = "คลิก";


  constructor(
    private _appState: AppState,
    private fb: FormBuilder,
    private _datePipe: DatePipe,
    private _authProvider: AuthProvider,
    private _calendar: NgbCalendar,
    private modalService: NgbModal
  ) {}

  canSubmit: boolean = false;
  requireIdCardExpireDate: boolean = false;
  isLifeTime: boolean = false;
  public selectedDate: NgbDate;
  public dateObject: Date;

  public registerForm: FormGroup;

  idCard: string;

  @ViewChild('idCardNumber')
  idCardNumber: ElementRef;

  @Input('consentVersionNo')
  consentVersionNo: string = '';

  @Output('requestRegisterEmit')
  requestRegisterEmit: EventEmitter<SecCheckIcThaiQueryModel> =
    new EventEmitter();

  ngOnInit() {
    this.createForm();
    this.f.birthDayType.valueChanges.subscribe((data) => {
      this.checkValidateBirthDay(data);
    });
  }

  ngAfterViewInit() {
    this.idCard = this._appState.tempUser;
    this.idCardNumber.nativeElement.value = this.idCard;
  }
  get f() {
    return this.registerForm.controls;
  }
  createForm() {
    this.registerForm = this.fb.group({
      idCardNumber: [
        this._appState.tempUser,
        Validators.compose([
          Validators.required,
          Validators.minLength(13),
          Validators.pattern(/^-?(0|[1-9]\d*)?$/),
          checkIDCardValidator(),
        ]),
      ],
      firstName: ['', Validators.required],
      middleName: [''],
      lastName: ['', Validators.required],
      birthday: [''],
      birthDayType: ['yyyyMMdd', Validators.required],
      year: [new Date().getFullYear()],
      month: [1],
      day: [1],
      idCardExpireDate: [null],
      confrimLifeTime: [false],
      laserId: [
        '',
        Validators.compose([
          Validators.required,
          Validators.maxLength(12),
          checkLaserIDValidator(),
        ]),
      ],
    });

    this.registerForm.valueChanges.subscribe((changeObject) => {
      this.canSubmit = this.registerForm.valid;
      console.log(this.registerForm.valid);
    });
  }
  checkValidateBirthDay(dateType: string) {
    if (dateType === 'yyyyMMdd') {
      this.f.year.setValidators(Validators.required);
      this.f.month.setValidators(Validators.required);
      this.f.day.setValidators(Validators.required);
      this.f.month.setValue(1);
      this.f.day.setValue(1);
    } else if (dateType === 'yyyyMM') {
      this.f.year.setValidators(Validators.required);
      this.f.month.setValidators(Validators.required);
      this.f.month.setValue(1);
      this.f.day.setValidators(null);
      this.f.day.reset(null);
    } else {
      this.f.year.setValidators(Validators.required);
      this.f.month.setValidators(null);
      this.f.month.reset(null);
      this.f.day.setValidators(null);
      this.f.day.reset(null);
    }
    this.f.year.updateValueAndValidity();
    this.f.month.updateValueAndValidity();
    this.f.day.updateValueAndValidity();
  }

  async submitNext() {
    console.log('submitnext_1');
    this.requireIdCardExpireDate = false;
    if (
      this.f.birthDayType.value !== 'yyyyMMdd' &&
      !this.f.confrimLifeTime.value &&
      this.f.idCardExpireDate.value == null
    ) {
      this.requireIdCardExpireDate = true;
      return;
    }
    let idCardLifeTimeExpireDate = '';
    if (this.isLifeTime) {
      //2565 - 2530 - 100 = 100
      const currentYear = new Date().getFullYear();
      const birthYear = this.f.year.value;
      const targetExpireYear = 100;
      console.log('currentYear',currentYear);
      console.log('birthYear',birthYear);
      idCardLifeTimeExpireDate = DateConstant.getDateYYYY_MM_DD(
        currentYear + Math.abs(currentYear - birthYear - targetExpireYear),
        12,
        31
      );
    }
    const deviceToken = await this._authProvider.validateAndCreateDeviceToken();
    const request: SecCheckIcThaiQueryModel = {
      birthDate: this.f.day.value,
      birthMonth: this.f.month.value,
      birthYear: this.f.year.value,
      idCardNumber: this.registerForm.value.idCardNumber,
      firstName: this.registerForm.value.firstName.trim(),
      middleName: this.registerForm.value.middleName.trim(),
      lastName: this.registerForm.value.lastName.trim(),
      birthday: DateConstant.getDateYYYY_MM_DD(
        this.f.year.value,
        this.f.month.value,
        this.f.day.value
      ),
      idCardExpireDate:
        this.f.confrimLifeTime.value === true
          ? idCardLifeTimeExpireDate
          : this.f.idCardExpireDate.value !== '' &&
            this.f.idCardExpireDate.value !== null
          ? DateConstant.getDateYYYY_MM_DD(
              this.f.idCardExpireDate.value.year - 543,
              this.f.idCardExpireDate.value.month,
              this.f.idCardExpireDate.value.day
            )
          : null,
      consentVersionNo: this.consentVersionNo,
      laserId: this.registerForm.value.laserId.replaceAll(/\s/g, ''),
      birthdateFormat: this.f.birthDayType.value,
      uniqueToken: deviceToken,
    };
    //console.log(idCardLifeTimeExpireDate);
    this.requestRegisterEmit.emit(request);
  }

  public get days() {
    const dayCount = this.getDaysInMonth(this.f.year.value, this.f.month.value);
    return Array(dayCount)
      .fill(0)
      .map((i, idx) => idx + 1);
  }
  public updateSelectedDate(date: NgbDate) {
    this.dateObject = new Date(date.year, date.month, date.day);
    this.requireIdCardExpireDate = false;
  }
  public getDaysInMonth(year: number, month: number) {
    return 32 - new Date(year, month - 1, 32).getDate();
  }
  public calculateLifeTime() {
    this.isLifeTime = false;
    if (this.f.birthDayType.value === 'yyyyMMdd') {
      return;
    }

    this.currentYear = new Date().getFullYear();
    this.yearOfBirth = this.f.year.value;
    const age = this.currentYear - this.yearOfBirth;
    if (age >= 60) {
      this.isLifeTime = true;
    } else {
      this.f.confrimLifeTime.setValue(false);
    }
    console.log(age);
  }
  showModalBehindIDCard() {
    const modalRef = this.modalService.open(BehingIdCardModalComponent, {
      size: 'md',
    });
    modalRef.componentInstance.name = 'World';
  }

  handleSelected($event: any) {
    this.f.confrimLifeTime.setValue($event.target.checked);
    console.log($event.target.checked);
  }

  handleSelectBirthDayType(event: Event) {
    const element = <HTMLInputElement>event.target;
    if (element.value === 'yyyyMMdd') {
      this.f.confrimLifeTime.setValue(false);
      console.log(this.f.confrimLifeTime.value);
    } else {
      this.currentYear = new Date().getFullYear();
      this.yearOfBirth = this.f.year.value;
      const age = this.currentYear - this.yearOfBirth;
      if (age >= 60) {
        this.isLifeTime = true;
      } else {
        this.isLifeTime = false;
      }
    }
  }

  hidMiddleName(event:Event,id:string){
    let inputMiddleName = document.getElementById(id);
    this.displayMiddleName = !this.displayMiddleName;
    if(this.displayMiddleName){
      this.btnDisplayMiddleName = "ซ่อน";
      inputMiddleName.style.display = "";
    }else{
      this.btnDisplayMiddleName = "คลิก";
      inputMiddleName.style.display = "none";
    }

  }

  showModalMiddleNameInfo() {
    const modalRef = this.modalService.open(MiddleNameModalComponent, {
      size: 'md',
    });
    modalRef.componentInstance.name = 'World';
  }

}
