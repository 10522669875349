import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import * as WSCall from "@app/utils/WSCall";
import {ConsentVersionControllerService} from "@proflink/prof-link-web-ic-api-ts-angular/api/consentVersionController.service";
import {APIResultboolean} from "@proflink/prof-link-web-ic-api-ts-angular/model/aPIResultboolean";
import {IcAgreeConsentLogSaveRequest} from "@proflink/prof-link-web-ic-api-ts-angular/model/icAgreeConsentLogSaveRequest";
import {AuthProvider} from "@core/auth/auth-provider.service";
import {ConsentVersionPublicControllerService} from "@proflink/prof-link-web-ic-api-ts-angular/api/consentVersionPublicController.service";
import {ConsentVersionModel} from "@proflink/prof-link-web-ic-api-ts-angular/model/consentVersionModel";
import {Router} from "@angular/router";
import { b64toBlob } from '@app/utils/Utils';

@Component({
  selector: 'app-ic-consent',
  templateUrl: './ic-consent.component.html',
  styleUrls: ['./ic-consent.component.scss']
})
export class IcConsentComponent implements OnInit {
  @ViewChild('pdfViewerAutoLoad') pdfViewerAutoLoad: { pdfSrc: Blob; refresh: () => void; };

  constructor(
      private sanitizer: DomSanitizer,
      private _router: Router,
      private _consentVersionControllerService: ConsentVersionControllerService,
      private _consentVersionPublicControllerService: ConsentVersionPublicControllerService,
      private _authProvider: AuthProvider,

  ) { }

  canSubmit = false;

  pdfUrl: SafeResourceUrl;
  consentNo:string = "";
  uniqueToken:string = "";

  radioConsent:string = "notAccept";
  textHtml:string = "";

  // @Output('completeState')
  // completeState: EventEmitter<string> = new EventEmitter();

  @Output('agreeConsent')
  agreeConsentEmit: EventEmitter<string> = new EventEmitter();


  async ngOnInit() {

    this.loadPdf();

    await this.loadConsentLatest();

  }

  data :string;

  async loadPdf(){
    // let consentPdf:string = await this.getLatestConsentVersionAppBase64PDF();
    // this.pdfUrl = this.toSecureURLPdfStr(consentPdf);
    // this.pdfUrl = this.toSecureURLPdfStr(this.data);
    let consentHtml:string = await this.getLatestConsentVersionAppHtml();
    this.textHtml = consentHtml;
    //  this._consentVersionPublicControllerService.getLatestConsentVersionAppBase64PDFUsingGET().subscribe(data => {
    //   this.pdfViewerAutoLoad.pdfSrc = b64toBlob(data.data, 'application/pdf'); // pdfSrc can be Blob or Uint8Array
    //   this.pdfViewerAutoLoad.refresh();
      
    // });
  }

  toSecureURLPdfStr(data: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(
        "data:application/pdf;base64," + data
    );
  }

  acceptConsent(isAccept: boolean) {
    this.canSubmit = isAccept;
  }

  // async submitConsent(){
  //
  //   let deviceToken = await this._authProvider.validateAndCreateDeviceToken();
  //   this.uniqueToken = deviceToken;
  //   this.agreeConsentEmit.emit(this.consentNo);


  // }

  // async unSubmitConsent(){
  //   this._router.navigate(["login"]);
  // }

  async loadConsentLatest(){

    let consentVersionModel:ConsentVersionModel = await this.getLatestConsentVersionAppDescription();
    this.consentNo = consentVersionModel.consentVersionNo;

  }

  async agreeByConsentNo(icAgreeConsentLogSaveRequest : IcAgreeConsentLogSaveRequest): Promise<APIResultboolean> {
    return WSCall.wsCall(
        () => {
          return this._consentVersionControllerService.agreeByConsentNoUsingPOST(icAgreeConsentLogSaveRequest)
        },
        respBody => {
          return respBody;
        }
    );
  }

  async getLatestConsentVersionAppDescription(): Promise<ConsentVersionModel> {
    return WSCall.wsCall(
        () => {
          return this._consentVersionPublicControllerService.getLatestConsentVersionAppDescriptionUsingGET()
        },
        respBody => {
          return respBody.data;
        }
    );
  }

  async getLatestConsentVersionAppBase64PDF(): Promise<string> {
    return WSCall.wsCall(
        () => {
          return this._consentVersionPublicControllerService.getLatestConsentVersionAppBase64PDFUsingGET();
        },
        respBody => {
          return respBody.data;
        }
    );
  }

  async getLatestConsentVersionAppHtml(): Promise<string> {
    return WSCall.wsCall(
        () => {
          return this._consentVersionPublicControllerService.getLatestConsentVersionAppHtmlUsingGET('SEC_CHECK_IC_FOR_IC');
        },
        respBody => {
          return respBody.data;
        }
    );
  }

  async onNext() {
    if (this.radioConsent == 'accept') {
      let deviceToken = await this._authProvider.validateAndCreateDeviceToken();
      this.uniqueToken = deviceToken;
      this.agreeConsentEmit.emit(this.consentNo);
    }
  }

  updateRadio(event:Event){

    let element = <HTMLInputElement>event.target;
    this.radioConsent = element.value;

  }





}
