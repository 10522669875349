import {Component, Input, OnInit} from '@angular/core';
import {ResultSessionModel} from "@proflink/prof-link-web-ic-api-ts-angular/model/resultSessionModel";
import {ResultSessionNonProflinkModel} from "@proflink/prof-link-web-ic-api-ts-angular/model/resultSessionNonProflinkModel";
import {FileStoreModel} from "@proflink/prof-link-web-ic-api-ts-angular/model/fileStoreModel";
import {TrainingDateModel} from "@proflink/prof-link-web-ic-api-ts-angular/model/trainingDateModel";
import {DatePipe} from "@angular/common";

@Component({
  selector: 'app-my-course-list-non-prof-link',
  templateUrl: './my-course-list-non-prof-link.component.html',
  styleUrls: ['./my-course-list-non-prof-link.component.scss']
})
export class MyCourseListNonProfLinkComponent implements OnInit {

  constructor(
      private _datePipe:DatePipe
  ) { }

  @Input("resultSessionNonProflink")
  resultSessionNonProflink:Array<ResultSessionNonProflinkModel> = [];

  ngOnInit() {
    console.log(this.resultSessionNonProflink);
  }

  getFile(file:FileStoreModel){

    if(file ==null){
      return '';
    }else{
      return file.downloadLink;
    }
  }
  get resultSessionNonProflinkSortData() {
    return this.resultSessionNonProflink.sort((a, b) => {
      return <any>new Date(b.trainingDate) - <any>new Date(a.trainingDate);
    });
  }
  getPeriodTrainingDayDisplay(trainingdays:Array<TrainingDateModel>){

    let displayDate:string = '';
    if(trainingdays.length <= 1){

      let trainingDate:Date = new Date(trainingdays[0].trainingDate);
      let birthDaySubmit = this._datePipe.transform(trainingDate, 'dd MMM');
      let yearTraining = Number(this._datePipe.transform(trainingDate, 'yyyy'));

      yearTraining =yearTraining +543;

      displayDate = birthDaySubmit+ ' '+yearTraining;

    }else {

      let firstTrainingDate:Date = new Date(trainingdays[0].trainingDate);
      let lastTrainingDate:Date = new Date(trainingdays[trainingdays.length-1].trainingDate);

      let firstDayDisplay = this._datePipe.transform(firstTrainingDate, 'dd MMM');
      let lastDayDisplay = this._datePipe.transform(lastTrainingDate, 'dd MMM');

      let yearTraining = Number(this._datePipe.transform(firstTrainingDate, 'yyyy'));

      yearTraining =yearTraining +543;
      displayDate = firstDayDisplay +' - '+lastDayDisplay+ ' '+yearTraining;
    }

    return displayDate;

  }

  getTrainingDateDisplay(date:Date){

    let dateDisplay = this._datePipe.transform(date, 'dd MMM');
    let yearDisplay = Number(this._datePipe.transform(date, 'yyyy'));

    yearDisplay = yearDisplay +543;
    dateDisplay = dateDisplay+ ' '+yearDisplay;

    return dateDisplay;
  }


}
