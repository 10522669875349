<!--<div class="wrapper">-->
<!--    <app-header-with-banner class="header" [title]="'test'"></app-header-with-banner>-->
<!--    <div class="container offset-sm-3 col-sm-6 offset-sm-3 custom-container content">-->
<div class="mt-3 custom-input">

    <!--                    <div class="form-group text-center">-->
    <!--                        <label class="register-info">ผลการค้นหาเลขที่ใบอนุญาต</label>-->
    <!--                    </div>-->

    <div class="form-group m-0">
        <label class="register-info">ชื่อ</label>
    </div>

    <div class="form-group under-line">
        <label class="display-text register-info">{{icProfile.firstnameTh + ' '+icProfile.lastnameTh}}</label>
    </div>

    <div class="form-group m-0">
        <label class="register-info">เลขที่ใบอนุญาตฯ</label>
    </div>

    <div class="form-group under-line">
        <label class="display-text register-info">{{icProfile.icCode}}</label>
    </div>
    <!-- 
                    <div class="form-group m-0">
                        <label class="register-info">สังกัด</label>
                    </div>

                    <div class="form-group under-line">
                        <label class="display-text register-info">{{displayCompany}}</label>
                    </div>

                    <div class="form-group m-0">
                        <label class="register-info">วันที่หมดอายุ</label>
                    </div>

                    <div class="form-group under-line">
                        <label class="display-text">{{displayLicenseExpired}}</label>
                    </div> -->

    <div class="form-group pt-5">
        <button type="button" class="btn btn-block btn-primary btn-next" (click)="submitCreate()">ยืนยัน
        </button>
    </div>
</div>
<!--    </div>-->
<!--</div>-->