import { Component, OnInit } from '@angular/core';
import {RegisterICTemporaryAccountModel} from "@proflink/prof-link-web-ic-api-ts-angular/model/registerICTemporaryAccountModel";
import {APIResultMapstringstring} from "@proflink/prof-link-web-ic-api-ts-angular/model/aPIResultMapstringstring";
import * as WSCall from "@app/utils/WSCall";
import {PublicMiscControllerService} from "@proflink/prof-link-web-ic-api-ts-angular/api/publicMiscController.service";
import {NewsCategoryModel} from "@proflink/prof-link-web-ic-api-ts-angular/model/newsCategoryModel";

@Component({
  selector: 'app-news-and-activity-list',
  templateUrl: './news-and-activity-list.component.html',
  styleUrls: ['./news-and-activity-list.component.scss']
})
export class NewsAndActivityListComponent implements OnInit {

  constructor(private _publicMiscControllerService: PublicMiscControllerService
  ) { }

  active:number = 1;

  newsCategories:Array<NewsCategoryModel> = [];

  async ngOnInit(){

    this.loadData();

  }

  loadData(){

    this.getNewsCategoryList();

  }

  async getNewsCategoryList(){

    this.newsCategories = await this.getNewsCategoryListUsingGET();

  }

  async getNewsCategoryListUsingGET(): Promise<Array<NewsCategoryModel>> {
    return WSCall.wsCall(
        () => {
          return this._publicMiscControllerService.getNewsCategoryListUsingGET();
        },
        respBody => {
          return respBody.data;
        }
    );
  }


}
