import { Component, OnInit } from '@angular/core';
import { AmChartsService, AmChart } from '@amcharts/amcharts3-angular';
import { IcProfileV2ControllerService } from '@proflink/prof-link-web-ic-api-ts-angular/api/icProfileV2Controller.service';
import * as WSCall from '@app/utils/WSCall';
import { IcLicenseSummaryV2Result } from '@proflink/prof-link-web-ic-api-ts-angular/model/icLicenseSummaryV2Result';
import { IcProfileControllerService, IcProfileModel, LicenseCurrentPeriodResult, ResultLicenseInformationModel } from '@proflink/prof-link-web-ic-api-ts-angular';
import {ActivatedRoute, Router} from '@angular/router';
import {AppState} from '@core/app-state';


@Component({
    selector: 'app-license-status',
    templateUrl: './license-status.component.html',
    styleUrls: ['./license-status.component.scss']
})
export class LicenseStatusComponent implements OnInit {
    title = 'สถานะการอบรมต่ออายุใบอนุญาต*';

    platform:string ='';

    private chart: AmChart;
    constructor(
        private _appState: AppState,
        private _route: ActivatedRoute,
        private _router: Router,
        private AmCharts: AmChartsService,
        private _icProfileV2ControllerService: IcProfileV2ControllerService,
        private _icProfileControllerService: IcProfileControllerService
    ) { }

    icLicenseSummaryV2Result: IcLicenseSummaryV2Result;
    licenseCurrentPeriodResult: LicenseCurrentPeriodResult;
    public passLearningResultLabel: string = '';
    public passLearningResultCss: string = '';
    callBackLoadData(_this:any){
        _this.loadData();
    }

    ngOnInit() {

        let refresh_token = this._route.snapshot.queryParams['refresh_token'];
        this.platform = this._route.snapshot.queryParams['platform'];
        localStorage.setItem("platform",this.platform);

        if(refresh_token != null && refresh_token.length > 0){
            this._appState.loadDataWithRefreshToken(refresh_token,this,this.callBackLoadData);
        }else{
            this.loadData();
        }
    }

    async loadData() {
        let icLicenseSummaryV2Result: IcLicenseSummaryV2Result = await this.viewLicenseDetail();
        this.icLicenseSummaryV2Result = icLicenseSummaryV2Result;
        this.renderChart(icLicenseSummaryV2Result);
        let licenseCurrentPeriod : LicenseCurrentPeriodResult = await this.getLicenseCurrentPeriod();
        this.licenseCurrentPeriodResult = licenseCurrentPeriod;

        const icId = localStorage.getItem('ic_id');
        if (icId.length > 0 && !Number.isNaN(icId)) {
            const profile = await this.getIcProfileById(+icId);
            if (icLicenseSummaryV2Result.resultLearningStatus) {
                this.passLearningResultLabel = 'ท่านอบรมครบตามเกณฑ์แล้ว';
                this.passLearningResultCss = 'text-center bg-green-success';
            } else {
                this.passLearningResultLabel = 'ท่านยังอบรมไม่ครบตามเกณฑ์';
                this.passLearningResultCss = 'text-center bg-red-warning';
                if (profile.privilegeType === 'MD') {
                    this.passLearningResultLabel = 'ท่านใช้สิทธิ์ดำรงสถานภาพผู้จัดการ';
                    this.passLearningResultCss = 'text-center bg-green-success';
                } else if (profile.privilegeType === 'FM') {
                    this.passLearningResultLabel = 'ท่านใช้สิทธิ์ผู้จัดการกองทุน';
                    this.passLearningResultCss = 'text-center bg-green-success';
                }
            }
        }
    }

    ngOnDestroy() {
        if (this.chart) {
            this.AmCharts.destroyChart(this.chart);
        }
    }

    async viewLicenseDetail(): Promise<IcLicenseSummaryV2Result> {
        return WSCall.wsCall(
            () => {
                return this._icProfileV2ControllerService.viewLicenseDetailUsingGET1();
            },
            respBody => {
                return respBody.data;
            }
        );
    }

    async getLicenseCurrentPeriod(): Promise<LicenseCurrentPeriodResult> {
        return WSCall.wsCall(
            () => {
                return this._icProfileV2ControllerService.getLicenseCurrentPeriodUsingGET();
            },
            respBody => {
                return respBody.data;
            }
        );
    }

    async getIcProfileById(id: number): Promise<IcProfileModel> {
        return WSCall.wsCall(
            () => {
                return this._icProfileControllerService.getIcProfileByIdUsingGET(id);
            },
            respBody => {
                return respBody.data;
            }
        );
    }

    renderChart(icLicenseSummaryV2Result: IcLicenseSummaryV2Result) {


        let secProfLinkTotalEthicMinutes = icLicenseSummaryV2Result.secProfLinkTotalEthicMinutes / 60;
        let secNonProfLinkTotalEthicMinutes = icLicenseSummaryV2Result.secNonProfLinkTotalEthicMinutes / 60;
        let profLinkTotalEthicMinutes = icLicenseSummaryV2Result.profLinkTotalEthicMinutes / 60;
        let requiredEthicMinutes = 3;

        let secProfLinkTotalEsgMinutes = icLicenseSummaryV2Result.secProfLinkTotalEsgMinutes / 60;
        let secNonProfLinkTotalEsgMinutes = icLicenseSummaryV2Result.secNonProfLinkTotalEsgMinutes / 60;
        let profLinkTotalEsgMinutes = icLicenseSummaryV2Result.profLinkTotalEsgMinutes / 60;
        let requiredEsgMinutes = 3;

        let secProfLinkTotalGeneralMinutes = icLicenseSummaryV2Result.secProfLinkTotalGeneralMinutes / 60;
        let secNonProfLinkTotalGeneralMinutes = icLicenseSummaryV2Result.secNonProfLinkTotalGeneralMinutes / 60;
        let profLinkTotalGeneralMinutes = icLicenseSummaryV2Result.profLinkTotalGeneralMinutes / 60;
        let requiredGeneralMinutes = 6;

        let secProfLinkTotalAcquiredMinutes = icLicenseSummaryV2Result.secProfLinkTotalAcquiredMinutes / 60;
        let secNonProfLinkTotalAcquiredMinutes = icLicenseSummaryV2Result.secNonProfLinkTotalAcquiredMinutes / 60;
        let profLinkTotalAcquiredMinutes = icLicenseSummaryV2Result.profLinkTotalAcquiredMinutes / 60;
        let requiredMinutes = 15;

        console.log(icLicenseSummaryV2Result)
     
        let maxValue = Math.max(secProfLinkTotalAcquiredMinutes, secNonProfLinkTotalAcquiredMinutes, profLinkTotalAcquiredMinutes);

        let maximum = 18;

        if (maxValue > maximum) {
            maximum = maxValue + 1;
        }
        else if (requiredMinutes > maximum) {
            maximum = 20;
        }

        this.chart = this.AmCharts.makeChart("chartdiv", {
            "type": "serial",
            "theme": "none",
            "fontSize": 9,
            "legend": {
                "reversedOrder": true,
                "horizontalGap": 10,
                "maxColumns": 1,
                "position": "bottom",
                "markerSize": 10,
                "useGraphSettings": true
            },
            "balloon": {
                "adjustBorderColor": true,
                "color": "#000000",
                "cornerRadius": 5,
                "fillColor": "#FFFFFF"
            },
            "dataProvider": [{
                "year": "ความรู้กฎระเบียบฯ<br>("+profLinkTotalEthicMinutes+" ชั่วโมง)",
                "secProflink": secProfLinkTotalEthicMinutes,
                "other": secNonProfLinkTotalEthicMinutes,
                "secApprove": profLinkTotalEthicMinutes,
                "totalHour": requiredEthicMinutes, //จำนวน hour ที่ set ให้กราฟอันที่2
                "icon": "assets/icons/line-green.svg",
                "labeColor": "#008000"
            }, {
                "year": "ความรู้ด้าน ESG<br>("+secProfLinkTotalEsgMinutes+" ชั่วโมง)",
                "secProflink": secProfLinkTotalEsgMinutes,
                "other": secNonProfLinkTotalEsgMinutes,
                "secApprove": profLinkTotalEsgMinutes,
                "totalHour": requiredEsgMinutes,
                "icon": "assets/icons/line-purple.svg",
                "labeColor": "#9800C7"
            }, {
                "year": "ความรู้ด้านอื่นๆ<br>("+profLinkTotalGeneralMinutes+" ชั่วโมง)",
                "secProflink": secProfLinkTotalGeneralMinutes,
                "other": secNonProfLinkTotalGeneralMinutes,
                "secApprove": profLinkTotalGeneralMinutes,
                //  "totalHour":requiredGeneralMinutes,
                //  "icon": "assets/icons/line-blue.svg"
            },
            {
                "year": "จำนวนชั่วโมงสะสม<br>("+profLinkTotalAcquiredMinutes+" ชั่วโมง)",
                "secProflink": secProfLinkTotalAcquiredMinutes,
                "other": secNonProfLinkTotalAcquiredMinutes,
                "secApprove": profLinkTotalAcquiredMinutes,
                "totalHour": requiredMinutes,
                "icon": "assets/icons/line-pink.svg",
                "labeColor": "#E91E63"
            }],
            "valueAxes": [{
                "id": "v1",
                "maximum": maximum,
                // "stackType": "regular",
                "axisAlpha": 0.3,
                "gridAlpha": 0,
                "unit": " ชม.",
            }, {
                "id": "v2",
                "position": "left",
                "synchronizeWith": "v1",
                "labelsEnabled": false,
                "synchronizationMultiplier": 1
            }],
            "graphs": [
                {
                    "fillAlphas": 1,
                    "lineAlpha": 0,
                    "title": "ผ่านการอบรม รอการรับรองผลจาก ก.ล.ต.",
                    "type": "column",
                    "fillColors": "#FE9A2E",
                    "valueField": "secApprove",
                    "fixedColumnWidth": 40,
                    "valueAxis": "v1",
                },
                {
                    "fillAlphas": 1,
                    "lineAlpha": 0,
                    "title": "ชั่วโมงที่ ก.ล.ต. รับรองผล(อบรมผ่าน Prof.Link)",
                    "type": "column",
                    "fillColors": "#3498DB",
                    "valueField": "secProflink",
                    "fixedColumnWidth": 40,
                    "valueAxis": "v1",
                    "clustered": false
                },
                {
                    "fillAlphas": 1,
                    "lineAlpha": 0,
                    "title": "ชั่วโมงที่ ก.ล.ต. รับรองผล(อบรมผ่านช่องทางอื่นๆ)",
                    "type": "column",
                    "fillColors": "#0B0B61",
                    "valueField": "other",
                    "fixedColumnWidth": 40,
                    "valueAxis": "v1",
                    "clustered": false
                },
                {
                    "id": "g2",
                    "valueAxis": "v2", //assign line graph to valueAxis2
                    "lineThickness": 1.5,
                    "fillAlphas": 0,
                    "lineAlpha": 0,
                    "lineColor": "#e95f30",
                    "bullet": "round",
                    //"customBullet":"assets/icons/horizontal-line-svgrepo-com.svg",
                    "customBulletField": "icon", //ไว้ custom attrubute name ที่ data provider
                    // "classNameField": "iconClass",
                    "bulletBorderColor": "#00ff00",
                    "bulletSize": 55,
                    "labelOffset": -20,
                    "labelPosition": "top",
                    "title": "Total",
                    "valueField": "totalHour",
                    "stackable": false,
                    "animationPlayed": true,
                    "color": "#ff0000",
                    "labelText": "[[value]] ชั่วโมง",
                    "visibleInLegend": false,
                    "fontSize": 13
                }
            ],
            "categoryField": "year",
            "categoryAxis": {
                "gridPosition": "start",
                "axisAlpha": 0,
                "gridAlpha": 0,
                "position": "left",
            }
        });

    }



}
