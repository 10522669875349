import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ResultOTPModel} from "@proflink/prof-link-web-ic-api-ts-angular/model/resultOTPModel";
import {Subscription} from "rxjs";
import {RegisterICTemporaryAccountModel} from "@proflink/prof-link-web-ic-api-ts-angular/model/registerICTemporaryAccountModel";
import {APIResultstring} from "@proflink/prof-link-web-ic-api-ts-angular/model/aPIResultstring";
import {CreateICTemporaryAccountModel} from "@proflink/prof-link-web-ic-api-ts-angular/model/createICTemporaryAccountModel";
import * as Utils from "@app/utils/Utils";
import {AppState} from "@core/app-state";
import {AuthProvider} from "@core/auth/auth-provider.service";
import {Router} from "@angular/router";
import * as WSCall from "@app/utils/WSCall";
import {AccountControllerService} from "@proflink/prof-link-web-ic-api-ts-angular/api/accountController.service";
import {TimerService} from "@app/service/timer-service";
import {APIResultResultOTPModel} from '@proflink/prof-link-web-ic-api-ts-angular/model/aPIResultResultOTPModel';

@Component({
  selector: 'app-otp-share',
  templateUrl: './otp-share.component.html',
  styleUrls: ['./otp-share.component.scss']
})
export class OtpShareComponent implements OnInit {


  @Input("resultOTP")
  set setResultOtp(resultOTP:ResultOTPModel){
    this.resultOTP = resultOTP;

    if (this.resultOTP) {
      this.mobileNumber = this.resultOTP.mobileNumber;
      this.email = this.resultOTP.email;
      this.refCode = this.resultOTP.refCode;
      let expiredSeconds = this.getExpiredTimeOtp(this.resultOTP);
      this.counter = expiredSeconds;
      this.startTimer();
      setTimeout(() => {
        this.otpInput.nativeElement.focus();
      }, 1000);
    }
  }


  @Input("validateNewOTP")
  validateNewOTP:boolean;

  resultOTP:ResultOTPModel;

  @Input("pathBack")
  pathBack:string="";

  @ViewChild('otpInput')
  otpInput: ElementRef;


  @Output('successVerifyOtp')
  successVerifyOtp: EventEmitter<string> = new EventEmitter();

  canRequestOtp:boolean = true;

  counter = 0;
  tick = 1000;
  countDown: Subscription;

  mobileNumber:string="";
  refCode:string="";
  email:string="";

  constructor(
      private _appState: AppState,
      private _authProvider: AuthProvider,
      private _accountControllerService: AccountControllerService,
      private _router: Router,
      private _timerService: TimerService
  ) { }

  async ngOnInit() {


  }

  async ngAfterViewInit() {

    this.otpInput.nativeElement.focus();
  }


  validateInputAndSubmitOtp(){
    let otp = this.otpInput.nativeElement.value;
    if(otp.length >= 4 ){
      this.submitOtp();
    }
  }

  async submitOtp(){
    let icId = this.resultOTP.icId;
    let deviceToken = await this._authProvider.validateAndCreateDeviceToken();
    let otp = this.otpInput.nativeElement.value;

    let formResendOtp:RegisterICTemporaryAccountModel = {
      "mobileNumber" : this.resultOTP.mobileNumber,
      "refCode" : this.resultOTP.refCode,
      "otpType" : this.resultOTP.otpType,
      "pinCodeOTP" : otp,
      "uniqueToken" : deviceToken,
      "icId" : icId
    };

    this.otpInput.nativeElement.value = '';

    let resultVerifyOtp:APIResultstring = null;
    if(this.validateNewOTP){
      resultVerifyOtp = await this.validateOTPNew(formResendOtp);
    }else{
      console.log("validate otp");
      resultVerifyOtp =await this.verifyOtp(formResendOtp);
    }

    if(resultVerifyOtp.resultCode == 'SUCCESS'){

      this.successVerifyOtp.emit(resultVerifyOtp.data);
    }else{
      this.otpInput.nativeElement.value = '';
      Utils.error(resultVerifyOtp.errorMessage,'เกิดข้อผิดพลาด');
    }

  }


  async validateOTPNew(fromResendOtp:RegisterICTemporaryAccountModel): Promise<APIResultstring> {

    return WSCall.wsCall(
        () => {
          return this._accountControllerService.validateOTPNewUsingPOST(fromResendOtp);
        },
        respBody => {
          return respBody;
        }
    );

  }


  async verifyOtp(fromResendOtp:RegisterICTemporaryAccountModel): Promise<APIResultstring> {

    return WSCall.wsCall(
        () => {
          return this._accountControllerService.verifyOTPUsingPOST(fromResendOtp);
        },
        respBody => {
          return respBody;
        }
    );

  }


  async requestOtp(){
    if(!this.canRequestOtp){
      return;
    }

    if(this.validateNewOTP){

      let registerICTemporaryAccountModel: RegisterICTemporaryAccountModel = {
        mobileNumber: this.resultOTP.mobileNumber,
        icId: this.resultOTP.icId,
        otpType : "CM"
      };
      let resultOTPModel:ResultOTPModel = await this.generateOTPGeneral(registerICTemporaryAccountModel);
      this.resultOTP = resultOTPModel;

    }else{

      let icId = this._appState.currentUser.icId;
      let fromResendOtp:RegisterICTemporaryAccountModel = {
        icId : icId
      };

      let resultOTPModel:ResultOTPModel =await this.resendOTPUsingPOST(fromResendOtp);
      this.resultOTP = resultOTPModel;

    }

    let expiredSeconds = this.getExpiredTimeOtp(this.resultOTP);
    this.counter = expiredSeconds;

    this.startTimer();

    this.otpInput.nativeElement.focus();

  }

  async resendOTPUsingPOST(fromResendOtp:RegisterICTemporaryAccountModel): Promise<ResultOTPModel> {

    return WSCall.wsCall(
        () => {
          return this._accountControllerService.resendOTPUsingPOST(fromResendOtp);
        },
        respBody => {
          return respBody.data;
        }
    );
  }

  async generateOTPGeneral(registerICTemporaryAccountModel : RegisterICTemporaryAccountModel): Promise<ResultOTPModel> {
    return WSCall.wsCall(
        () => {
          return this._accountControllerService.generateOTPGeneralUsingPOST(registerICTemporaryAccountModel)
        },
        respBody => {
          return respBody.data;
        }
    );
  }


  getExpiredTimeOtp(resultOTPModel:ResultOTPModel) : number{
    let currentDate:Date = new Date();
    let expiredDate:Date = new Date(resultOTPModel.expireDatetime);

    let duration = expiredDate.valueOf() - currentDate.valueOf();
    let expiredSeconds = Math.floor(duration/1000);

    return expiredSeconds;
  }

  startTimer(){
    this.canRequestOtp = false;
    this.countDown = this._timerService
        .getCounter(this.tick)
        .subscribe(() => {
          if(this.counter<=0){
            this.canRequestOtp = true;
            this.countDown = null;
          }else{
            this.counter--;
          }
        });


    setTimeout(() => {
      this.otpInput.nativeElement.focus();
    }, 1000);

  }


  ngOnDestroy() {
    this.countDown = null;
  }

}
