import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-my-course-share',
  templateUrl: './my-course-share.component.html',
  styleUrls: ['./my-course-share.component.scss']
})
export class MyCourseShareComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
