import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IcProfileModel} from '@proflink/prof-link-web-ic-api-ts-angular/model/icProfileModel';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AccountControllerService} from '@proflink/prof-link-web-ic-api-ts-angular/api/accountController.service';
import {RegisterICTemporaryAccountModel} from '@proflink/prof-link-web-ic-api-ts-angular/model/registerICTemporaryAccountModel';
import {Observable} from 'rxjs';
import {APIResultstring} from '@proflink/prof-link-web-ic-api-ts-angular/model/aPIResultstring';
import {APIResultResultOTPModel} from '@proflink/prof-link-web-ic-api-ts-angular/model/aPIResultResultOTPModel';
import {ResultOTPModel} from '@proflink/prof-link-web-ic-api-ts-angular/model/resultOTPModel';
import * as Utils from '@app/utils/Utils';

@Component({
  selector: 'app-profile-contact-form',
  templateUrl: './profile-contact-form.component.html',
  styleUrls: ['./profile-contact-form.component.scss']
})
export class ProfileContactFormComponent implements OnInit {
  @Input() profile: IcProfileModel;
  @Input() callbackForm: FormGroup;
  @Output() editSuccess: EventEmitter<boolean> = new EventEmitter();
  @Output() verifyOTP: EventEmitter<{form: FormGroup, resultOTPModel: ResultOTPModel}> = new EventEmitter();
  deviceTokenKey = 'device-token';
  form: FormGroup;
  isShowResendEmail = false;
  constructor(private fb: FormBuilder, private accountService: AccountControllerService) { }

  ngOnInit(): void {
    if (this.callbackForm) {
      if (this.callbackForm.controls.isChangeEmail.value) {
        this.saveEmail(this.callbackForm).subscribe((res) => {
          if (res.resultCode === 'ERROR') {
            Utils.error(res.errorMessage, '');
          } else {
            this.editSuccess.emit(true);
          }
        });
      } else {
        this.editSuccess.emit(true);
      }
    } else {
      this.form = this.fb.group({
        mobileNumber: [this.profile.mobileNumber, [Validators.required, Validators.pattern('^(\\+\\d{1,2}\\s)?\\(?\\d{3}\\)?[\\s.-]?\\d{3}[\\s.-]?\\d{4}$')]],
        email : [this.profile.email, [Validators.required, Validators.email]],
        isChangeEmail: []
      });
    }
    if (!this.profile.emailValidated) {
      this.isShowResendEmail = true;
    }
    this.form.controls.email.valueChanges.subscribe(() => {
      this.isShowResendEmail = false;
    });
  }
  save(): void {
    if (this.form.valid) {
      if (this.isChangeMobileNumber()) {
        this.form.controls.isChangeEmail.setValue(this.isChangeEmail());
        this.requestOTP().subscribe((res) => {
              if (res.resultCode === 'ERROR') {
                Utils.error( res.errorMessage, '');
              } else {
                this.verifyOTP.emit({form: this.form, resultOTPModel: res.data});
              }
            },
            error => {
              Utils.error( error, '');
            }
        );
      } else if (this.isChangeEmail()) {
         this.saveEmail(this.form).subscribe((res) => {
          if (res.resultCode === 'ERROR') {
            Utils.error(res.errorMessage, '');
          } else {
            this.editSuccess.emit(true);
          }
        });
      }
    } else {
      this.form.markAllAsTouched();
    }
  }
  isChangeMobileNumber(): boolean {
    return this.form.controls.mobileNumber.value !== this.profile.mobileNumber;
  }
  isChangeEmail(): boolean {
    return this.form.controls.email.value !== this.profile.email;
  }
  saveEmail(form: FormGroup): Observable<APIResultstring> {
    const request: RegisterICTemporaryAccountModel = {
      icId: this.profile.icId,
      email: form.controls.email.value,
      uniqueToken: this.deviceToken
    };
    return this.accountService.generateMailActivatedTokenUsingPOST(request);
  }
  get deviceToken(): string {
    return localStorage.getItem(this.deviceTokenKey);
  }
  requestOTP(): Observable<APIResultResultOTPModel> {
    const request: RegisterICTemporaryAccountModel = {
      icId: this.profile.icId,
      mobileNumber: this.form.controls.mobileNumber.value,
      otpType: 'CM',
      uniqueToken: this.deviceToken
    };
    return this.accountService.generateOTPUsingPOST(request);
  }

    resendEmail(): void {
      console.log(this.form.controls.email.value);
      if(this.form.controls.email.value==''||this.form.controls.email.value==null){
        Utils.error("กรุณากรอก อีเมล");
      }
      this.saveEmail(this.form).subscribe((res) => {
        if (res.resultCode === 'ERROR') {
          Utils.error(res.errorMessage, '');
        } else {
          this.editSuccess.emit(true);
        }
      });
    }
}
