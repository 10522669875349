<div [formGroup]="reportProblemForm" class="form-group card-body">
    <div class="m-0 p-0 col-sm-12">
        <div class="form-group m-0 text-left">
            <label class="register-info">
                เรื่อง
            </label>
        </div>

        <div class="form-group">
            <input class="form-control required" formControlName="topicName" disabled="true"/>
        </div>

        <div class="form-group m-0 text-left">
            <label class="register-info">
                ชื่อ - สกุล <span class="text-danger">*</span>
            </label>
        </div>

        <div class="form-group">
            <input formControlName="fullName" class="form-control required" />
        </div>

        <div class="form-group m-0 text-left">
            <label class="register-info">
                อีเมล <span class="text-danger">*</span>
            </label>
        </div>

        <div class="form-group">
            <input formControlName="email" class="form-control required"  />
        </div>

        <div class="form-group m-0 text-left">
            <label class="register-info">
                ข้อความ <span class="text-danger">*</span>
            </label>
        </div>

        <div class="form-group">
            <textarea formControlName="message" rows="2" class="form-control required"></textarea>
        </div>

        <div class="form-group m-0 text-left">
            <label class="register-info">
                ข้อมูลเพิ่มเติม
            </label>
        </div>

        <div class="row col-12 col-sm-6 pl-0 pr-0 pt-0 pb-2 m-0">
            <div *ngFor="let o of files" class="col-6 col-sm-4 m-0 pl-0 pr-0 pt-2 pb-2 text-left">
                <div class="card attachment-item text-right p-0" [style.background-image]="'url('+o.downloadLink+')'" width="100px">
                    <a class="cursor-pointer btn-remove" (click)="removeFile(o)">
                        <i class="fa fa-times-circle text-danger icon-remove" aria-hidden="true" style="font-size: 32px;"></i>
                    </a>
                </div>
            </div>

            <div class="col-6 col-sm-4 m-0 pl-0 pr-0 pt-2 pb-2 text-left" style="width: 100px;height: 100px">
                <div class="card attachment-item text-center pt-4 pb-2" style="width: 100px">
                    <a class="cursor-pointer" (click)="upload()">
                        <i class="fa fa-plus-circle text-primary" style="font-size: 32px;"></i>
                    </a>
                </div>
            </div>
        </div>

        <div class="pt-2 form-group m-0 text-left">
            <div class="border-custom">
                <div class="m-3">
                    <p class="m-0">ติดต่อ : SET Contact Center</p>
                    <p class="m-0" >โทร 0 2009 9999</p>
                    <p class="m-0">Email : SETContactCenter@set.or.th</p>
                </div>
            </div>
        </div>

        <div class="form-group m-0 text-center pt-4">
            <button (click)="submitData()" class="btn btn-primary pr-5 pl-5">ส่งข้อมูล</button>
        </div>
    </div>
</div>

<app-file-upload style="display: none;" #attachmentDocument="fileUpload" (fileUpdate)="onBackgroundImageChange($event)" [maxWidth]="'400px'"
                 [maxHeight]="'300px'" [accept]="'image/*'">
</app-file-upload>
