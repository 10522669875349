import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function checkIDCardValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {

    const value = control.value;

    if (!value) {
      return null;
    }
    const validIdCard = validNationalID(value);

    return !validIdCard ? { formatCorrect: true } : null;
  };

  function validNationalID(id: any) {
    if (id == null || id.length !== 13) { return false; }
    let i; let sum = 0;
    // STEP1 - get only first 12 digits
    for ((i = 0), (sum = 0); i < 12; i++) {
      // STEP 2 - multiply each digit with each index (reverse)
      // STEP 3 - sum multiply value together
      sum += parseInt(id.charAt(i)) * (13 - i);
    }
    // STEP 4 - mod sum with 11
    const mod = sum % 11;
    // STEP 5 - subtract 11 with mod, then mod 10 to get unit
    const check = (11 - mod) % 10;
    // STEP 6 - if check is match the digit 13th is correct
    if (check === parseInt(id.charAt(12))) {
      return true;
    }
    return false;
  }
}

export function checkLaserIDValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {

    const value = control.value;

    if (!value) {
      return null;
    }
    const validIdCard = validLaseID(value);

    return !validIdCard ? { formatCorrect: true } : null;
  };

  function validLaseID(id: any) {
    const format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    if (format.test(id)) {
      return false;
    } else {
      return true;
    }
    // if (id.includes('-') || id.includes(' ')) {
    //   return false;
    // }else{
    //   return true;
    // }
  }
}
