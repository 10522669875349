import { Component, OnInit, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import * as Utils from '@app/utils/Utils.ts';
import {AccountControllerService} from '@proflink/prof-link-web-ic-api-ts-angular/api/accountController.service';
import { UserProfileControllerService } from '@proflink/prof-link-web-ic-api-ts-angular/api/userProfileController.service';
import {ChangePinModel} from '@proflink/prof-link-web-ic-api-ts-angular/model/changePinModel';
import {sha256} from 'js-sha256';
import { flyInAnimation } from '@app/shared/animations/animation';
import {RegisterICTemporaryAccountModel} from '@proflink/prof-link-web-ic-api-ts-angular/model/registerICTemporaryAccountModel';
import {APIResultMapstringstring} from '@proflink/prof-link-web-ic-api-ts-angular/model/aPIResultMapstringstring';
import * as WSCall from '@app/utils/WSCall';
import {APIResultstring} from '@proflink/prof-link-web-ic-api-ts-angular/model/aPIResultstring';
import {AppState} from '@core/app-state';
import {IcProfileModel} from '@proflink/prof-link-web-ic-api-ts-angular/model/icProfileModel';
import { AuthProvider} from '@core/auth/auth-provider.service';
import { Router} from '@angular/router';
import { promise, utils } from 'protractor';

@Component({
  selector: 'app-change-pin',
  templateUrl: './change-pin.component.html',
  styleUrls: ['./change-pin.component.scss'],
  animations: [flyInAnimation]
})
export class ChangePinComponent implements OnInit {

  title = 'เปลี่ยนรหัส PIN';

  constructor(
    private _userProfileControllerService: UserProfileControllerService,
    private _appState: AppState,
    private _authProvider: AuthProvider,
    private _router: Router
) { }

icProfileId: number;
userName: string;

@ViewChild('password')
password: ElementRef;

@ViewChild('newPassword')
newPassword: ElementRef;

@ViewChild('confirmPassword')
confirmPassword: ElementRef;


  ngOnInit(): void {
    this.loadIcProfileId();
  }

   loadIcProfileId(): void{
    if (this._appState.currentUser != null){
      this.icProfileId = this._appState.currentUser.icId;
      this.userName = this._appState.currentUser.idCard;
      console.log(this.icProfileId + '-' + this.userName);
    }
  }

  async validateAndChangePin() {

    let password: string  = this.password.nativeElement.value;
    let newPassword: string = this.newPassword.nativeElement.value;
    let confirmPassword: string = this.confirmPassword.nativeElement.value;

    if (password.trim() === ''){
      Utils.error('กรุณากรอกรหัส PIN ปัจจุบัน', '', () => {
        this.password.nativeElement.focus();
      });
      return;
    }

    if (newPassword.trim() === ''){
      Utils.error('กรุณาสร้างรหัส PIN ใหม่', '', () => {
        this.password.nativeElement.focus();
      });
      return;
    }

    if (confirmPassword.trim() === ''){
      Utils.error('กรุณายืนยันรหัส PIN', '', () => {
        this.confirmPassword.nativeElement.focus();
      });
      return;
    }

    if (newPassword === confirmPassword) {
      let deviceToken: string = await this._authProvider.validateAndCreateDeviceToken();
      let changePingModel: ChangePinModel = {
        icId : this.icProfileId,
        oldPassword: sha256(password),
        newPassword: sha256(confirmPassword),
        uniqueToken: deviceToken
      };

      let resultChangePin: APIResultstring = await this.changePinUsingPOST(changePingModel);
      if (resultChangePin.resultCode === 'SUCCESS'){
        Utils.success(resultChangePin.data, '', '' , () => {
         this._appState.login(this.userName, sha256(confirmPassword), deviceToken)
          .then(() => {
            this._router.navigate(['home']);
          })
          .catch(reason => {
            this._router.navigate(['login']);
          });
        });
      }else {
        Utils.error(resultChangePin.errorMessage, '', () => {
          this.password.nativeElement.focus();
        });
      }
    } else {
      Utils.error('การยืนยันรหัส PIN ไม่ตรงกัน', '', () => {
        this.newPassword.nativeElement.focus();
        this.confirmPassword.nativeElement.focus();
      });
    }
  }

  async changePin(): Promise<void> {
    await this.validateAndChangePin();
    // this._appState.login(this.userName, sha256(confirmPassword), deviceToken)
    // .then(() => {
    //   this._router.navigate(['home']);
    // })
    // .catch(reason => {
    //   this._router.navigate(['login']);
    // });
  }

  async changePinUsingPOST(changePinModel: ChangePinModel): Promise<APIResultstring> {
    return WSCall.wsCall(
        () => {
          return this._userProfileControllerService.changePinUsingPOST1(changePinModel);
        },
        respBody => {
          return respBody;
        }
    );
  }
}
