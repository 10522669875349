import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ResultSessionModel} from "@proflink/prof-link-web-ic-api-ts-angular/model/resultSessionModel";
import {FileStoreModel} from "@proflink/prof-link-web-ic-api-ts-angular/model/fileStoreModel";
import {TrainingDateModel} from "@proflink/prof-link-web-ic-api-ts-angular/model/trainingDateModel";
import {DatePipe} from "@angular/common";
import {SessionModel} from "@proflink/prof-link-web-ic-api-ts-angular/model/sessionModel";
import {ProvinceModel} from "@proflink/prof-link-web-ic-api-ts-angular/model/provinceModel";
import {RegisterSessionModel} from '@proflink/prof-link-web-ic-api-ts-angular/model/registerSessionModel';

@Component({
  selector: 'app-my-course-list',
  templateUrl: './my-course-list.component.html',
  styleUrls: ['./my-course-list.component.scss']
})
export class MyCourseListComponent implements OnInit {

  constructor(
      private _datePipe:DatePipe
  ) { }

  public colorSessionStatus:string;

  @Input("resultSession")
  resultSession:Array<ResultSessionModel> = [];

  @Output('selectedResult')
  selectedResultEventEmitter: EventEmitter<ResultSessionModel> = new EventEmitter();

  ngOnInit() {


  }

  getFile(file:FileStoreModel){

    if(file ==null){
      return '';
    }else{
      return file.downloadLink;
    }
  }

  getPeriodTrainingDayDisplay(trainingdays:Array<TrainingDateModel>){

    let displayDate:string = '';
    if(trainingdays.length <= 1){

      let trainingDate:Date = new Date(trainingdays[0].trainingDate);
      let birthDaySubmit = this._datePipe.transform(trainingDate, 'dd MMM');
      let yearTraining = Number(this._datePipe.transform(trainingDate, 'yyyy'));

      yearTraining =yearTraining +543;

      displayDate = birthDaySubmit+ ' '+yearTraining;


    }else {

      let firstTrainingDate:Date = new Date(trainingdays[0].trainingDate);
      let lastTrainingDate:Date = new Date(trainingdays[trainingdays.length-1].trainingDate);

      let firstDayDisplay = this._datePipe.transform(firstTrainingDate, 'dd MMM');
      let lastDayDisplay = this._datePipe.transform(lastTrainingDate, 'dd MMM');

      let yearTraining = Number(this._datePipe.transform(firstTrainingDate, 'yyyy'));

      yearTraining =yearTraining +543;
      displayDate = firstDayDisplay +' - '+lastDayDisplay+ ' '+yearTraining;
    }


    return displayDate;

  }

  getDisplaySessionStatus(registerSession:RegisterSessionModel){

    if(registerSession.session != null && registerSession.session.sessionStatus == '6L'){
      return 'ยกเลิกการจัดอบรม';
    }
    else if(registerSession.holdResult){
      return 'ระงับผลการอบรม';
    }else if(!registerSession.holdResult){
      return 'ลงทะเบียนสำเร็จ';
    }

  }

  getColorDisplay(registerSession:RegisterSessionModel){
    if(registerSession.session != null && registerSession.session.sessionStatus == '6L'){
      return ''
    }
    else if(registerSession.holdResult){
      return 'color-red';

    }else if(!registerSession.holdResult){
      return 'color-green';
    }
  }



  getCourseDetail(resultSessionSelect:ResultSessionModel){
    this.selectedResultEventEmitter.emit(resultSessionSelect);
  }

}
