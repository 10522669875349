import { Component, OnInit } from '@angular/core';
import { CourseResponse, IcProfileControllerService, OpenUrlSignResponse } from '@proflink/prof-link-web-ic-api-ts-angular';
import * as WSCall from '@app/utils/WSCall';
import { flyInAnimation } from '@app/shared/animations/animation';
import { ELearningService } from '@app/service/e-learning-service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-e-learning-result',
  templateUrl: './e-learning-result.component.html',
  styleUrls: ['./e-learning-result.component.scss'],
  animations: [flyInAnimation]
})
export class ELearningResultComponent implements OnInit {
  title: string;
  courseByCategory: Array<CourseResponse> = [];
  constructor(
    private _icProfileControllerService: IcProfileControllerService,
    private _eLearningService: ELearningService,
  ) {
  }

  ngOnInit() {
    this._eLearningService.title.pipe(first(x => x != null)).subscribe((title: string) => {
      this.title = title;
    });
    this._eLearningService.courseByCategorySelected.pipe(first(x => x != null)).subscribe((courseByCategory: CourseResponse[]) => {
      this.courseByCategory = courseByCategory;
    });
  }

  async viewDetail(courseResponse: CourseResponse) {
    let openUrlSignResponse: OpenUrlSignResponse = await this.viewLicenseDetail(courseResponse.id);
    var form = document.createElement("form");
    var elementInputData = document.createElement("input");
    var elementInputSignature = document.createElement("input");

    elementInputData.style.display = "none;";
    elementInputData.type = "hidden";

    elementInputSignature.style.display = "none;";
    elementInputSignature.type = "hidden";

    form.method = "POST";
    form.action = openUrlSignResponse.urlLogin;
    form.target = "_blank";
    //
    elementInputData.value = openUrlSignResponse.data;
    elementInputData.name = "data";
    form.appendChild(elementInputData);
    //
    elementInputSignature.value = openUrlSignResponse.signature;
    elementInputSignature.name = "signature";
    form.appendChild(elementInputSignature);
    //
    document.body.appendChild(form);

    form.submit();


  }

  async viewLicenseDetail(courseId: number): Promise<OpenUrlSignResponse> {
    return WSCall.wsCall(
      () => {

        return this._icProfileControllerService.viewCourseUsingGET(courseId);
      },
      respBody => {
        return respBody.data;
      }
    );
  }
}
