<div class="container">
    <div class="col-sm-12 pt-4 ">
        <div [formGroup]="icForm" class="form-group">

            <div class="col-sm-12 border-custom m-2">
                <div class="col-sm-6 offset-md-3 col-xs-12">
                    <div class="col-xs-12">
                        <label class="change-mobile-info">เรื่อง : แจ้งเปลี่ยนแปลงเบอร์มือถือ</label>
                    </div>

                </div>

                <div class="col-sm-6 offset-md-3 col-xs-12">
                    <div class="col-xs-12">
                        <label class="change-mobile-info required">เบอร์มือถือใหม่</label>
                    </div>
                    <div class="col-xs-12 text-left">
                        <input type="text" class="form-control required" formControlName="mobileNumberNew"
                            maxlength="10">
                        <div *ngIf="f.mobileNumberNew.touched && f.mobileNumberNew.invalid">
                            <div class="text-danger" *ngIf="f.mobileNumberNew.errors.required">กรุณากรอก
                                เบอร์มือถือใหม่</div>
                            <div class="text-danger" *ngIf="f.mobileNumberNew.errors.pattern">กรุณากรอกเฉพาะตัวเลขให้ครบ 10 หลัก
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-sm-6 offset-md-3 col-xs-12">
                    <div class="col-xs-12">
                        <label class="change-mobile-info required">ยืนยันเบอร์มือถือใหม่</label>
                    </div>
                    <div class="col-xs-12 text-left">
                        <input type="text" class="form-control required" formControlName="mobileNumberNewConfirm"
                            maxlength="10">
                        <div *ngIf="f.mobileNumberNewConfirm.touched && f.mobileNumberNewConfirm.invalid">
                            <div class="text-danger" *ngIf="f.mobileNumberNewConfirm.errors.required">กรุณากรอก
                                ยืนยันเบอร์มือถือใหม่</div>
                            <div class="text-danger" *ngIf="f.mobileNumberNewConfirm.errors.pattern">กรุณากรอกเฉพาะตัวเลขให้ครบ 10 หลัก
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 offset-md-3 col-xs-12 text-danger" *ngIf="f.mobileNumberNew.value!='' && f.mobileNumberNew.value!=f.mobileNumberNewConfirm.value">
                    การยืนยันเบอร์โทรศัพท์มือถือ ไม่ตรงกัน
                </div>
                <div class="col-sm-6 offset-md-3 col-xs-12">
                    <div class="col-xs-12">
                        <label class="change-mobile-info required">หลักฐาน 1 (แนบรูปถ่ายบัตรประชาชน)</label>
                    </div>

                    <div class="col-xs-12 text-left">

                        <div class="p-0 d-flex flex-row justify-content-center">
                            <div class="p-0 text-center">
                                <img #imagePreviewUpload1 src="../../../../assets/image/bg-gray.png" width="200px" height="150px">
                            </div>
                        </div>

                        <div class="pt-3 text-center">
                            <div class="p-0">
                                <label for="cameraInputFile2" class="btn btn-primary mr-1 ml-1">เลือกรูปภาพ</label>
                            </div>

                            <div class="p-0">
                                <input type="file" style="visibility:hidden;" accept="image/*" id="cameraInputFile2"
                                    name="cameraInput2" (change)="onFileSelected1($event)">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-sm-6 offset-md-3 col-xs-12">
                    <div class="col-xs-12">
                        <label class="change-mobile-info required">หลักฐาน 2 (แนบรูปถ่ายของตัวท่านเองพร้อมบัตรประชาชน)</label>
                    </div>

                    <div class="col-xs-12 text-left">

                        <div class="p-0 d-flex flex-row justify-content-center">
                            <div class="p-0 text-center">
                                <img #imagePreviewUpload2 src="../../../../assets/image/bg-gray.png" width="200px" height="150px">
                               
                            </div>
                        </div>

                        <div class="pt-3 text-center">
                            <div class="p-0">
                                <label for="cameraInputFile1" class="btn btn-primary mr-1 ml-1">เลือกรูปภาพ</label>
                            </div>

                            <div class="p-0">
                                <input type="file" style="visibility:hidden;" accept="image/*" id="cameraInputFile1"
                                    name="cameraInput1" (change)="onFileSelected2($event)">
                            </div>
                        </div>

                    </div>
                </div>

                <div class="col-sm-12 text-center pt-4 pb-4">
                    <button class="btn btn-primary pl-5 pr-5" [disabled]="!canSubmit"
                        (click)="onConfirmInformation()">ยืนยันเพื่อรับ OTP</button>
                </div>

            </div>

        </div>

    </div>
</div>