import {Injectable} from "@angular/core";
import {timer} from "rxjs";


@Injectable()
export class TimerService {

    getCounter(tick:number) {
        return timer(0, tick);
    }

}
