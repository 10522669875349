import { Injectable } from "@angular/core";
import { CourseResponse } from "@proflink/prof-link-web-ic-api-ts-angular";
import { BehaviorSubject } from "rxjs";

@Injectable()
export class ELearningService {
    public courseByCategorySelected = new BehaviorSubject<CourseResponse[]>([]);
    public title = new BehaviorSubject<string>("E-learning Search");

    constructor() { }
}