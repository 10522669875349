<div class="modal-body">
    <div class="text-center pt-3">
        <img src="assets/image/BehindIDCard.PNG" alt="BehindIDCard" class="mx-auto img-fluid" style="max-width: 50%;" >
        <h3>เลขหลังบัตร</h3>
        <h4 class="text-secondary">กรุณากรอกตัวอักษรภาษาอังกฤษ 2 หลัก และตัวเลข 10 หลัก<br> บนด้านหลังบัตรประจำตัวประชาชน</h4>
    </div>
    <div class="form-group pt-3 pb-3 text-center">
        <button type="button" class="btn btn-outline-primary btn-next" style="width: 100px;"
            (click)="activeModal.close('Close click')"><h4 class="m-0">ตกลง</h4>
        </button>
    </div>
</div>