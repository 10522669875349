import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {PublicContactUsControllerService} from '@proflink/prof-link-web-ic-api-ts-angular/api/publicContactUsController.service';
import {ProblemTopicModel} from '@proflink/prof-link-web-ic-api-ts-angular/model/problemTopicModel';
import * as WSCall from '@app/utils/WSCall';

@Component({
  selector: 'app-report-problem-list',
  templateUrl: './report-problem-list.component.html',
  styleUrls: ['./report-problem-list.component.scss']
})
export class ReportProblemListComponent implements OnInit {


  constructor(
      private _publicContactUsControllerService:PublicContactUsControllerService
  ) { }

  problems:ProblemTopicModel[] = [];


  @Output('completeSelected')
  completeSelected: EventEmitter<ProblemTopicModel> = new EventEmitter();

  async ngOnInit() {

    this.problems = await this.getAllProblemTopic();
    console.log(this.problems);

  }

  onSelected(selected:ProblemTopicModel){
    this.completeSelected.emit(selected);
  }

  async getAllProblemTopic() : Promise<Array<ProblemTopicModel>> {
    return WSCall.wsCall(() => {
      return this._publicContactUsControllerService.getAllProblemTopicUsingGET1();
    }, (respBody) => {
      return respBody.data;
    });
  }

}
