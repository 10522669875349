<div>
    <div>
        <label>สังกัดในฐานะพนักงานบริษัท</label>
        <p *ngIf="companyStaff.length < 1">-</p>
        <div><span *ngFor="let item of companyStaff">
            <ul><li>
                {{item.company.nameTh}}
            </li></ul>
          </span>
        </div>
    </div>
    <div>
        <label>ตัวแทนสนับสนุน (วันที่แจ้งเริ่มปฏิบัติงาน)</label>
        <p *ngIf="companyAgent.length < 1">-</p>
        <div><span *ngFor="let item of companyAgent">
            <ul>
                <li>
                    {{item.company.nameTh}} ({{ item.startDate | dateThai }} -
                    {{
                      item.endDate != null ? (item.endDate | dateThai) : "ยังปฏิบัติหน้าที่"
                    }}
                    )
                </li>
            </ul>
        </span>
        </div>
    </div>
    <div *ngIf="companyStaff.length > 0">
        <button type="button" class="btn btn-block un-submit bg-primary" (click)="edit()" >ส่งคำขออัพเดทสังกัด</button>
    </div>
</div>
