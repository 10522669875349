<div>
    <div>
        <label>เบอร์มือถือ</label>
        <p>{{profile.mobileNumber}}</p>
    </div>
    <div>
        <label>อีเมล</label>
        <p>{{profile.email}}</p>
    </div>
    <button type="button" class="btn btn-block un-submit bg-primary" (click)="edit()" >เปลี่ยนแปลงข้อมูล</button>
</div>
