

<div [formGroup]="createUserForm" class="row custom-input">


    <div class="col-sm-12 pt-3">
        <div class="col-sm-12 m-0">
            <label class="register-info">รหัส PIN (ตัวเลข 6 หลัก)</label>
        </div>

        <div class="col-sm-12">
            <input name="password" formControlName="password" maxlength="6" class="form-control input-password"  #password />
            <div class="text-left" *ngIf="f.password.touched && f.password.invalid">
                <div class="text-danger" *ngIf="f.password.errors.required">
                    กรุณากรอกรหัส PIN</div>
                <div class="text-danger" *ngIf="f.password.errors.pattern">
                    กรุณากรอกเฉพาะตัวเลขให้ครบ 6 หลัก </div>
            </div>
        </div>
    </div>

    <div class="col-sm-12 pt-3">
        <div class="col-sm-12 m-0">
            <label class="register-info">ยืนยันรหัส PIN</label>
        </div>

        <div class="col-sm-12">
            <input maxlength="6" formControlName="confirmPassword" name="confirmPassword" class="form-control input-password" name="confirmPassword"
                #confirmPassword />
                <div class="text-left" *ngIf="f.confirmPassword.touched && f.confirmPassword.invalid">
                    <div class="text-danger" *ngIf="f.confirmPassword.errors.required">
                        กรุณากรอกยืนยันรหัส PIN</div>
                    <div class="text-danger" *ngIf="f.confirmPassword.errors.pattern">
                        กรุณากรอกเฉพาะตัวเลขให้ครบ 6 หลัก </div>
                </div>
        </div>
    </div>

    <div class="col-sm-12 pt-4 text-center">
        <div class="col-sm-12">
            <button type="button" [disabled]="!canSubmit" class="btn btn-block btn-primary btn-next" (click)="submitCreatePIN()">
                ​ถัดไป
            </button>
        </div>
    </div>

</div>
