import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-middle-name-modal',
  templateUrl: './middle-name-modal.component.html',
  styleUrls: ['./middle-name-modal.component.scss']
})
export class MiddleNameModalComponent implements OnInit {

  @Input() name:string;
  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {
  }

}
