<app-header-with-back *ngIf="platform != 'mobile'" class="header" [title]="title"
    [path]="'profile/license-status'"></app-header-with-back>
<div class="box-container">
    <div class="box">
        <div *ngIf="icLicenseSummaryV2Result != null" class="container">
            <div class="d-flex flex-column justify-content-center">
                <div class="pt-3 d-flex flex-row justify-content-center">
                    <label class="title">รายละเอียดการอบรม</label>
                </div>

                <div class="p-0 d-flex flex-row justify-content-center">
                    <div class="pt-2 pb-2 pr-4 pl-4 mr-2 ml-2 d-flex flex-row d-inline-flex summary-border">
                        <label class="m-2 p-2">จำนวนชั่วโมงสะสม</label>
                        <div class="summary-bg pl-4 pr-4 pt-3"
                            [style]="'background-color :'+totalAcquiredMinutestStatusHexColor">
                            <label
                                [style]="'color:'+totalAcquiredMinutesTextStatusHexColor">{{icLicenseSummaryV2Result.totalAcquiredMinutes/60|
                                number:'1.0-2'}}</label>
                        </div>
                    </div>
                </div>
                <div class="pt-3 d-flex flex-row justify-content-center">
                    <label class="title">ตามเกณฑ์บังคับอย่างน้อย 15 ชั่วโมง</label>
                </div>
            </div>

            <div class="d-flex flex-column justify-content-center">

                <div class="pt-3 d-flex flex-row justify-content-start">
                    <label class="title">ความรู้กฏระเบียบ จรรยาบรรณฯ</label>
                </div>

                <div class="d-flex  flex-wrap">

                    <div *ngFor="let licenseDetail of licenseEthic"
                        class="p-0 m-1 card-detail d-flex align-content-between flex-wrap">

                        <div class="pr-2 pl-2 text-center flex-fill">
                            <label class="detail-label-1">{{licenseDetail.categoryName}}</label>
                        </div>

                        <div class="p-0 detail-bg-2 text-center flex-fill"
                            [style]="'background-color :'+licenseDetail.hoursStatusHexColor">
                            <label class="detail-label-2">{{licenseDetail.acquiredMinutesByProfLink/60| number:'1.0-2'}}
                                ชม.</label>
                        </div>

                    </div>
                </div>
            </div>

            <div class="d-flex flex-column justify-content-center">

                <div class="pt-3 d-flex flex-row justify-content-start">
                    <label class="title">ความรู้ด้าน ESG</label>
                </div>

                <div class="d-flex  flex-wrap">

                    <div *ngFor="let licenseDetail of licenseEsg"
                        class="p-0 m-1 card-detail d-flex align-content-between flex-wrap">

                        <div class="pr-2 pl-2 text-center flex-fill">
                            <label class="detail-label-1">{{licenseDetail.categoryName}}</label>
                        </div>

                        <div class="p-0 detail-bg-2 text-center flex-fill"
                            [style]="'background-color :'+licenseDetail.hoursStatusHexColor">
                            <label class="detail-label-2">{{licenseDetail.acquiredMinutesByProfLink/60| number:'1.0-2'}}
                                ชม.</label>
                        </div>

                    </div>
                </div>
            </div>
            <div class="d-flex flex-column justify-content-center">

                <div class="pt-3 d-flex flex-row justify-content-start">
                    <label class="title">ความรู้ด้านอื่นๆ</label>
                </div>

                <div class="d-flex  flex-wrap">

                    <div *ngFor="let licenseDetail of licenseGeneral"
                        class="p-0 m-1 card-detail d-flex align-content-between flex-wrap">

                        <div class="pr-2 pl-2 text-center flex-fill">
                            <label class="detail-label-1">{{licenseDetail.categoryName}}</label>
                        </div>

                        <div class="p-0 detail-bg-2 text-center flex-fill"
                            [style]="'background-color :'+licenseDetail.hoursStatusHexColor">
                            <label class="detail-label-2">{{licenseDetail.acquiredMinutesByProfLink/60| number:'1.0-2'}}
                                ชม.</label>
                        </div>

                    </div>
                </div>
            </div>
            <div class="d-flex flex-column justify-content-center">
                <div class="pt-3 d-flex flex-row justify-content-center">
                    <label class="title">สรุปชั่วโมงที่ต้องอบรมเพิ่มเติม</label>
                </div>
            </div>


            <div *ngIf="!isHavePrivilege">
                <div class="d-flex flex-column justify-content-center">
                    <div class="pt-3 d-flex flex-row justify-content-start">
                        <label class="title">ความรู้กฏระเบียบ จรรยาบรรณฯ</label>
                    </div>

                    <div class="flex-column card card-body card-custom p-0 m-0">

                        <div class="p-0 text-center">
                            <label class="title-required-1">ชั่วโมงที่ต้องอบรมเพิ่มเติม</label>
                        </div>

                        <div class="p-0 text-center">
                            <label class="title-required-2">{{icLicenseSummaryV2Result.totalRequiredEthicMinutes/60|
                                number:'1.0-2'}}</label>
                        </div>

                        <div class="p-0 text-center">
                            <label class="title-required-3">ชั่วโมง</label>
                        </div>
                        <!-- <div *ngIf="(icLicenseSummaryV2Result.totalRequiredEthicMinutes/60)==0?false:true"
                            class="p-0 text-center mb-3">
                            <button #EthicCourse class="btn btn-warning text-black text-center"
                                [routerLink]="['/search']"
                                [queryParams]="{categoryGroup: 'Ethic'}">ค้นหาหลักสูตร</button>
                        </div> -->
                    </div>
                </div>

                <div class="d-flex flex-column justify-content-center">
                    <div class="pt-3 d-flex flex-row justify-content-start">
                        <label class="title">ความรู้ด้าน ESG</label>
                    </div>

                    <div class="flex-column card card-body card-custom p-0 m-0">

                        <div class="p-0 text-center">
                            <label class="title-required-1">ชั่วโมงที่ต้องอบรมเพิ่มเติม</label>
                        </div>

                        <div class="p-0 text-center">
                            <label class="title-required-2">{{icLicenseSummaryV2Result.totalRequiredEsgMinutes/60|
                                number:'1.0-2'}}</label>
                        </div>

                        <div class="p-0 text-center">
                            <label class="title-required-3">ชั่วโมง</label>
                        </div>
                        <!-- <div *ngIf="(icLicenseSummaryV2Result.totalRequiredEsgMinutes)/60==0?false:true"
                            class="p-0 text-center mb-3">
                            <button #ESGCourse class="btn btn-warning text-black text-center" [routerLink]="['/search']"
                                [queryParams]="{categoryGroup: 'ESG'}">ค้นหาหลักสูตร</button>
                        </div> -->
                    </div>
                </div>
                <div class="d-flex flex-column justify-content-center">
                    <div class="pt-3 d-flex flex-row justify-content-start">
                        <label class="title">ความรู้ด้านใดก็ได้</label>
                    </div>

                    <div class="flex-column card card-body card-custom p-0 m-0">

                        <div class="p-0 text-center">
                            <label class="title-required-1">ชั่วโมงที่ต้องอบรมเพิ่มเติม</label>
                        </div>

                        <div class="p-0 text-center">
                            <label class="title-required-2">{{icLicenseSummaryV2Result.totalRequiredGeneralMinutes/60|
                                number:'1.0-2'}}</label>
                        </div>

                        <div class="p-0 text-center">
                            <label class="title-required-3">ชั่วโมง</label>
                        </div>
                        <!-- <div *ngIf="(icLicenseSummaryV2Result.totalRequiredGeneralMinutes)/60==0?false:true"
                            class="p-0 text-center mb-3">
                            <button class="btn btn-warning text-black text-center" [routerLink]="['/search']"
                                [queryParams]="{categoryGroup: 'General'}">ค้นหาหลักสูตร</button>
                        </div> -->
                    </div>
                </div>

            </div>
            <div class="d-flex p-3 flex-row text-white justify-content-center" style="background-color: #27AE60;"
                *ngIf="isHavePrivilege">
                <span>{{privilegeDescription}}</span>

            </div>

            <div class="p-1 text-muted remark-text">
                *หมายเหตุ : ข้อมูลจำนวนชั่วโมงอบรมต่ออายุใบอนุญาตฯ ที่ปรากฎอยู่ใน Prof.Link นี้
                เป็นการเชื่อมโยงข้อมูลและประมวลผลเพื่อความสะดวกในการใช้ข้อมูลเท่านั้น
                กรณีที่ท่านต้องการข้อมูลรับรองอย่างเป็นทางการ กรุณาตรวจสอบเพิ่มเติมกับทางสำนักงาน ก.ล.ต.
            </div>
        </div>
    </div>
</div>