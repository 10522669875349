<div [formGroup]="registerForm" class="custom-input mt-3">
  <div class="form-group">
    <div class="col-sm-12">
      <div class="form-group m-0 text-left">
        <label class="register-info">เลขบัตรประจำตัวประชาชน<span class="text-danger">*</span></label>
      </div>

      <div class="form-group">
        <input class="form-control required" #idCardNumber formControlName="idCardNumber" maxlength="13" />
        <div class="text-left" *ngIf="f.idCardNumber.touched && f.idCardNumber.invalid">
          <div class="text-danger" *ngIf="f.idCardNumber.errors.required">
            กรุณากรอกเลขบัตรประจำตัวประชาชน
          </div>
          <div class="text-danger" *ngIf="f.idCardNumber.errors.pattern">
            กรุณากรอกเฉพาะตัวเลข
          </div>
          <div class="text-danger" *ngIf="f.idCardNumber.errors.formatCorrect">
            เลขบัตรประจำตัวประชาชนไม่ถูกต้อง
          </div>
        </div>
      </div>

      <div class="form-group m-0 text-left">
        <label class="register-info">ชื่อ<span class="text-danger">*</span> (ไม่ต้องระบุคำนำหน้าชื่อ)</label>
      </div>

      <div class="form-group">
        <input class="form-control required" formControlName="firstName" required />
        <div class="text-left" *ngIf="f.firstName.touched && f.firstName.invalid">
          <div class="text-danger" *ngIf="f.firstName.errors.required">
            กรุณากรอกชื่อ
          </div>
        </div>
      </div>
      
      <div class="form-group m-0 text-left row no-gutters">
        <div class="col-10 col-md-8 col-xl-5">
          <label class="register-info">ชื่อกลาง (เฉพาะผู้มีชื่อกลางในบัตรประชนเท่านั้น)</label>
        </div>
        <div class="col-2 col-md-4 col-xl-7">
          <div class="d-flex flex-wrap">
            <a class="mx-1 text-warning pl-2 pr-2 cursor-pointer" (click)="showModalMiddleNameInfo()">
              <i class="fas fa-info-circle fa-lg"></i></a>
            <a (click)="hidMiddleName($event,'inputMiddleName')"
              class="text-primary btn-show-middle pl-2 pr-2">{{btnDisplayMiddleName}}</a>
          </div>
        </div>
      </div>
      <div id="inputMiddleName" style="display:none;" class="form-group">
        <input class="form-control" formControlName="middleName" />
      </div>

      <div class="form-group m-0 text-left">
        <label class="register-info">นามสกุล<span class="text-danger">*</span></label>
      </div>

      <div class="form-group">
        <input class="form-control required" formControlName="lastName" required />
        <div class="text-left" *ngIf="f.lastName.touched && f.lastName.invalid">
          <div class="text-danger" *ngIf="f.lastName.errors.required">
            กรุณากรอกนามสกุล
          </div>
        </div>
      </div>

      <div class="form-group m-0 text-left">
        <label class="register-info">วัน-เดือน-ปีเกิด (พ.ศ.)<span class="text-danger">*</span></label>
      </div>
      <div class="form-group m-0 text-left">
        <div class="custom-control custom-radio">
          <input (click)="handleSelectBirthDayType($event);" class="custom-control-input" formControlName="birthDayType"
            type="radio" name="birthDayType" value="yyyyMMdd" id="yyyyMMdd" />
          <label class="custom-control-label" for="yyyyMMdd">
            มีวัน/เดือน/ปีเกิด
          </label>
        </div>
        <div class="custom-control custom-radio">
          <input (click)="handleSelectBirthDayType($event);" class="custom-control-input" formControlName="birthDayType"
            type="radio" name="birthDayType" value="yyyyMM" id="yyyyMM" />
          <label class="custom-control-label" for="yyyyMM">
            มีเฉพาะเดือนและปีเกิด
          </label>
        </div>
        <div class="custom-control custom-radio">
          <input (click)="handleSelectBirthDayType($event);" class="custom-control-input" formControlName="birthDayType"
            type="radio" name="birthDayType" value="yyyy" id="yyyy" />
          <label class="custom-control-label" for="yyyy">
            มีเฉพาะปีเกิด
          </label>
        </div>
      </div>
      <ng-container>
        <div class="form-group m-0 text-left">
          <label class="register-info">ปี<span class="text-danger">*</span></label>
        </div>
        <div class="form-group">
          <select (change)="calculateLifeTime()" name="year" id="year" formControlName="year"
            class="form-control">
            <option *ngFor="let year of listYear" [ngValue]="year">
              {{ year | yearThai }}
            </option>
          </select>
          <div class="text-left" *ngIf="f.year.touched && f.year.invalid">
            <div class="text-danger" *ngIf="f.year.errors.required">
              กรุณากรอกปี
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="f.birthDayType.value != 'yyyy'">
        <div class="form-group m-0 text-left">
          <label class="register-info">เดือน<span class="text-danger">*</span></label>
        </div>
        <div class="form-group">
          <select name="month" id="month" class="form-control" formControlName="month">
            <option *ngFor="let month of listMonth; let i = index" [ngValue]="i + 1">
              {{ month }}
            </option>
          </select>
          <div class="text-left" *ngIf="f.month.touched && f.month.invalid">
            <div class="text-danger" *ngIf="f.month.errors.required">
              กรุณากรอกเดือน
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="f.birthDayType.value == 'yyyyMMdd'">
        <div class="form-group m-0 text-left">
          <label class="register-info">วัน<span class="text-danger">*</span></label>
        </div>
        <div class="form-group">
          <select name="day" id="day" class="form-control" formControlName="day">
            <option *ngFor="let day of days; let i = index" [ngValue]="day">
              {{ day }}
            </option>
          </select>
          <div class="text-left" *ngIf="f.year.touched && f.year.invalid">
            <div class="text-danger" *ngIf="f.year.errors.required">
              กรุณากรอกวัน
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="f.birthDayType.value !== 'yyyyMMdd'">
        <div class="form-group m-0 text-left">
          <div class="form-check-inline" *ngIf="isLifeTime">
            <input class="form-check-input" type="checkbox" id="confrimLifeTime" formControlName="confrimLifeTime"
              (change)="handleSelected($event)" />
            <label class="register-info-checkbox" for="confrimLifeTime">
              เป็นบัตรประชาชนแบบตลอดชีพ</label>
          </div>
        </div>
        <div *ngIf="!f.confrimLifeTime.value">
          <div class="d-flex justify-content-start">
            <div class="form-group m-0 text-left">
              <label class="register-info">วันที่บัตรประชาชนหมดอายุ<span class="text-danger">*</span></label>
            </div>
          </div>
          <div class="form-group m-0 text-left">
            <div class="input-group">
              <input id="idCardExpireDate" class="form-control required" placeholder="dd-mm-yyyy" [minDate]="minDate"
                [maxDate]="maxDate" [firstDayOfWeek]="7" name="dp" ngbDatepicker #d="ngbDatepicker"
                formControlName="idCardExpireDate" (dateSelect)="updateSelectedDate($event)" readonly />
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button" style="width: 70px">
                  <i class="far fa-calendar-alt"></i>
                </button>
              </div>
            </div>
            <div class="form-group m-0 text-left text-danger" *ngIf="requireIdCardExpireDate">
              กรุณากรอกวันที่บัตรประชาชนหมดอายุ
            </div>
          </div>
        </div>
      </ng-container>

      <div class="form-group m-0 text-left">
        <label class="register-info">เลขหลังบัตร<span class="text-danger">*</span></label>
      </div>

      <div class="form-group">
        <div class="d-flex align-items-center">
          <input class="form-control required" formControlName="laserId" maxlength="12" required />
          <a class="mx-1 text-warning" style="cursor: pointer" (click)="showModalBehindIDCard()"><i
              class="fas fa-info-circle fa-lg"></i></a>
        </div>

        <div class="text-left text-mute">ตัวอย่างการกรอก : JT9999999999</div>
        <div class="text-left" *ngIf="f.laserId.touched && f.laserId.invalid">
          <div class="text-danger" *ngIf="f.laserId.errors.required">
            กรุณากรอกเลขหลังบัตร
          </div>
          <div class="text-danger" *ngIf="f.laserId.errors.formatCorrect">
            เลขหลังบัตรไม่ถูกต้อง กรุณากรอกตัวอักษรภาษาอังกฤษและตัวเลขเท่านั้น
          </div>
        </div>
      </div>

      <div class="form-group pt-3 pb-3">
        <button type="button" [disabled]="!canSubmit" class="btn btn-block btn-primary btn-next" (click)="submitNext()">
          ถัดไป
        </button>
      </div>
    </div>
  </div>
</div>