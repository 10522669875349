import { HostListener } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as WSCall from "@app/utils/WSCall";
import { NoticeControllerService } from "@proflink/prof-link-web-ic-api-ts-angular/api/noticeController.service";
import { NoticeSummaryResponse } from "@proflink/prof-link-web-ic-api-ts-angular/model/noticeSummaryResponse";
import { AppState } from '@core/app-state';
import { MenuItem } from 'primeng/api';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor(
    private _appState: AppState,
    private _router: Router,
    private router: Router,
    private _noticeControllerService: NoticeControllerService
  ) { }

  items: MenuItem[] | undefined;
  profileItems: MenuItem[] | undefined;

  // @HostListener('document:click', ['$event']) click(event: Event) {

  //   if (!$(event.target).is('.collapse-custom *')) {
  //     this.closeCollapse();
  //   }
  // }

  // closeCollapse() {
  //   let elems = document.querySelectorAll(".collapse-custom");

  //   [].forEach.call(elems, function (el: any) {
  //     el.classList.remove("show");
  //   });
  // }

  numNotification: number = 0;

  async ngOnInit() {

    this.loadData();
    this.items = [
      {
        label: 'หน้าแรก',
        routerLink: ['home'],
        routerLinkActiveOptions: { exact: true }
      },
      {
        label: 'คอร์สของฉัน',
        routerLink: ['mycourse'],
        routerLinkActiveOptions: { exact: true }
      },
      {
        label: 'e-learning',
        routerLink: ['bannerelearning'],
        routerLinkActiveOptions: { exact: true }
      },
      {
        label: 'เพิ่มเติม',
        items: [
          {
            label: 'ตั้งค่าความปลอดภัย',
            expanded: true,
            items: [
              {
                label: 'เปลี่ยนรหัส PIN',
                routerLink: ['more/privacy-setting/change-pin']
              }
            ]
          },
          {
            label: 'เปลี่ยนแปลงข้อมูลส่วนบุคคล',
            routerLink: ['profile/personal/1']
          },
          {
            label: 'ช่วยเหลือ',
            routerLink: ['report-problem'],
            routerLinkActiveOptions: { exact: true }
          },
          {
            label: 'เงื่อนไขข้อตกลงการใช้งานระบบ Prof.Link',
            expanded: true,
            items: [
              {
                label: 'เงื่อนไขข้อตกลงการใช้งานระบบ Prof.Link',
                routerLink: ['more/condition-and-agreement']
              },
              {
                label: 'ยกเลิกการใช้ระบบและข้อมูลส่วนบุคคล',
                routerLink: ['more/privacy-setting/cancellation-and-privacy']
              }
            ]
          }
        ]
      },
      {
        label: 'เกี่ยวกับ Prof. Link',
        routerLink: ['more/about-us'],
        routerLinkActiveOptions: { exact: true }
      },
      {
        label: 'ออกจากระบบ',
        command: () => this.logout(),
      }
    ];

    this.profileItems = [
      {
        label: 'ประวัติส่วนตัว',
        routerLink: ['profile/personal/1']
      },
      {
        label: 'ดิจิตอลพาสปอร์ต',
        routerLink: ['profile/digital-passport']
      },
      {
        label: 'สถานะการอบรมต่ออายุใบอนุญาตฯ',
        routerLink: ['profile/license-status']
      }]
  }

  openNewsAndActivities() {
    this.router.navigate(['news']);
  }

  async loadData() {

    const summaryMyNotification = await this.summaryMyNotification();
    if (summaryMyNotification != null) {
      this.numNotification = summaryMyNotification.unReadNotice;
    }

    console.log('summaryMyNotification : ', summaryMyNotification);

  }

  async summaryMyNotification(): Promise<NoticeSummaryResponse> {
    return WSCall.wsCall(
      () => {
        return this._noticeControllerService.summaryMyNotificationUsingGET();
      },
      respBody => {
        return respBody.data;
      }
    );
  }

  stopPropagation(event: Event) {
    event.stopPropagation();
  }

  logout() {
    this._appState.logout();
    this._router.navigate(["login"]);
  }


}
