<form [formGroup]="form">
    <div class="form-group">

        <div class="custom-input">

            <div class="form-group m-0">
                <label>เลขประจำตัวประชาชน</label>
            </div>

            <div class="form-group">
                <p>{{profile.idCard}}</p>
                <!-- <input class="form-control" formControlName="idCardNumber" [readOnly]="true"/> -->
            </div>

            <div class="form-group m-0">
                <label>ชื่อ</label>
            </div>

            <div class="form-group">
                <input class="form-control" formControlName="firstName" [ngClass]="{ 'is-invalid': form.controls.firstName?.touched && form.controls.firstName.errors?.required}"/>
                <span *ngIf="form.controls.firstName?.touched && form.controls.firstName.errors?.required"
                      class="danger">
                        กรุณากรอก ชื่อ
                    </span>
            </div>

             <div class="form-group m-0">
                <label>ชื่อกลาง</label>
            </div>

            <div class="form-group">
                <input class="form-control" formControlName="middleName"/>
            </div>

            <div class="form-group m-0">
                <label>นามสกุล</label>
            </div>

            <div class="form-group">
                <input class="form-control" formControlName="lastName" [ngClass]="{ 'is-invalid': form.controls.lastName?.touched && form.controls.lastName.errors?.required}"/>
                <span *ngIf="form.controls.lastName?.touched && form.controls.lastName.errors?.required"
                      class="danger">
                        กรุณากรอก นามสกุล
                    </span>
            </div>

            <div class="form-group m-0">
                <label>วันเกิด</label>
            </div>

            <div class="form-group">
                <p>{{profile.birthdayTh}}</p>
                <!-- <input class="form-control" formControlName="birthday" [readOnly]="true"/> -->
            </div>

            <div class="form-group m-0">
                <label>เลขหลังบัตร</label>
            </div>

            <div class="form-group">
                <input class="form-control" formControlName="laserId" [ngClass]="{ 'is-invalid': form.controls.laserId?.touched && form.controls.laserId.errors?.required}"/>
                <span *ngIf="form.controls.laserId?.touched && form.controls.laserId.errors?.required"
                      class="danger">
                        กรุณากรอก เลขหลังบัตร
                    </span>
            </div>

            <div class="form-group">
                <button type="button" class="btn btn-block un-submit bg-primary" (click)="save()">บันทึก</button>
            </div>

        </div>
    </div>
</form>
