<div class="wrapper" [@flyInAnimation]="'in'">
    <app-header-with-back class="header" [title]="title" [path]="'home'"></app-header-with-back>
    <div class="box-container">
        <div class="box">
            <div class="col-sm-12 pt-3">
                <div class="col-sm-12">
                    <label>รหัส PIN ปัจจุบัน</label>
                </div>

                <div class="col-sm-12">
                    <input type="number" name="password"
                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                        maxlength="6" autocomplete="off" class="form-control input-password" inputmode="numeric"
                        #password />
                </div>
            </div>

            <div class="col-sm-12 pt-3">
                <div class="col-sm-12">
                    <label>รหัส PIN ใหม่</label>
                </div>

                <div class="col-sm-12">
                    <input type="number" name="newPassword"
                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                        maxlength="6" class="form-control input-password" autocomplete="off" inputmode="numeric"
                        #newPassword />
                </div>
            </div>

            <div class="col-sm-12 pt-3">
                <div class="col-sm-12">
                    <label>ยืนยันรหัส PIN </label>
                </div>

                <div class="col-sm-12">
                    <input type="password" name="confirmPassword"
                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                        maxlength="6" class="form-control input-password" autocomplete="off" inputmode="numeric"
                        #confirmPassword />
                </div>
            </div>

            <div class="col-sm-12 py-4 text-center">
                <div class="col-sm-12">
                    <button type="button" class="btn btn-block btn-primary btn-next" (click)="changePin()">
                        <label style="color: black;">ถัดไป</label>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>