<div *ngIf="!selected" class="body-content" [@flyUpAnimation]="'in'">
  <app-header-with-close class="header mb-1" [title]="title"></app-header-with-close>
  <div class="box-container" *ngIf="!selected">
    <div class="box">
      <div *ngFor="let n of myNoticeAll">
        <div class="card">
          <div class="card-body row no-gutters" [ngClass]="{'unread-bg': !n.matchingIcNotice.markAsRead}">
            <div class="col-3 col-sm-2 align-self-center d-flex justify-content-center">
              <img class="noti-img" src="{{getImageNotice(n)}}">
            </div>
            <div class="col-9 col-sm-10 pl-2"
              [routerLink]="['/notification-detail',n.noticeId, n.matchingIcNotice.icNoticeId]">
              <div class="title-topic text-truncate">{{n.title}}</div>
              <p class="text-truncate">{{n.detail}}</p>
              <span class="text-muted">{{getDisplayDate(n.createDate)}} </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="selected" class="box-container" [@flyUpAnimation]="'in'">
  <div class="box">
    <app-notification-detail></app-notification-detail>
  </div>
</div>
