<app-header-with-banner></app-header-with-banner>
<div class="box-container">
    <div class="box">
        <div class="pt-3 pt-sm-5">
            <div class="form-group pt-sm-5 pb-sm-5">
                <div class="custom-input">
                    <app-email-validate-form *ngIf="emailState == 'EMAIL_FORM'" [email]="email"
                        (completeState)="onConfirmEmail($event)"></app-email-validate-form>
                    <app-email-validate-confirm *ngIf="emailState == 'EMAIL_CONFIRM'"
                        [email]="email"></app-email-validate-confirm>
                </div>
            </div>
        </div>
    </div>
</div>