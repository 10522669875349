import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CategoryResponse } from '@proflink/prof-link-web-ic-api-ts-angular/model/categoryResponse';
import { CourseCategoryResponse } from '@proflink/prof-link-web-ic-api-ts-angular/model/courseCategoryResponse';
import * as WSCall from '@app/utils/WSCall';
import { PublicMiscControllerService } from '@proflink/prof-link-web-ic-api-ts-angular/api/publicMiscController.service';
import { PublicCategoryGroupControllerService } from '@proflink/prof-link-web-ic-api-ts-angular/api/publicCategoryGroupController.service';
import { CategoryGroupModel } from '@proflink/prof-link-web-ic-api-ts-angular/model/categoryGroupModel';
import { ResultSessionModel } from '@proflink/prof-link-web-ic-api-ts-angular/model/resultSessionModel';
import { SearchSessionModel } from '@proflink/prof-link-web-ic-api-ts-angular/model/searchSessionModel';
import { SessionControllerService } from '@proflink/prof-link-web-ic-api-ts-angular/api/sessionController.service';
import { SearchCreteriaRequest } from '@proflink/prof-link-web-ic-api-ts-angular/model/searchCreteriaRequest';
import { DatePipe } from '@angular/common';
import {
  SearchTrainingProviderModel,
  TrainingProviderControllerService,
  TrainingProviderModel,
  PublicSessionControllerService,
  CategoryModel
} from '@proflink/prof-link-web-ic-api-ts-angular';
import { AppState } from '@core/app-state';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {

  constructor(
    private _datePipe: DatePipe,
    private _appState: AppState,
    private _publicCategoryGroupControllerService: PublicCategoryGroupControllerService,
    private _sessionControllerService: SessionControllerService,
    private _trainingProviderControllerService: TrainingProviderControllerService,
    private _publicSessionControllerService: PublicSessionControllerService,
    private _activatedRoute: ActivatedRoute
  ) { }


  trainingProviders: Array<TrainingProviderModel> = [];

  minDate: any = {
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    day: new Date().getDate()
  };

  dateSelected: any;
  categoryGroup: string;
  ngOnInit(): void {
    this._appState.loadCurrentUser();
    this.searchState = SearchListState.SHOW_CATEGORY;
    this._activatedRoute.queryParams
      .subscribe(params => {
        this.categoryGroup = params.categoryGroup;
      }
      );
    console.log(this.categoryGroup);
    if (this.categoryGroup !== undefined) {
      this.searchByCategory(this.categoryGroup);
    }
    this.loadData();
  }

  categoryGroups: Array<CategoryGroupModel> = [];
  resultSession: Array<ResultSessionModel> = [];

  searchState: SearchListState = SearchListState.SHOW_CATEGORY;

  allCategoryGroups: CategoryGroupModel;

  @ViewChild('searchDate')
  searchDate: ElementRef;



  async ngAfterViewInit() {
    this._appState.loadCurrentUser();
    let icId = this._appState.currentUser.icId;

    let searchTrainingProviderModel: SearchTrainingProviderModel = {
      'trainingProviderType': 0,
      'icId': icId,
      'pagable': false,
      'recordStatus': 1
    };

     this.trainingProviders = await this.getAllTrainingProviderByTrainingProviderType(searchTrainingProviderModel);
  }

  keyword: string = '';
  categoryIdSelected: number = null;
  trainingProviderIdSelected: number = null;
  acceptRedirectUrl = '';



  async loadData() {
    this.categoryGroups = await this.getAllCategoryGroup();
    let allCategoryGroup: CategoryGroupModel = {
      'categoryGroupId': 0,
      'categoryGroupName': 'หลักสูตรทั้งหมด',
      'categoryGroupNameEN': 'All',
      'categoryGroupSeq': 0
    };
    this.categoryGroups.push(allCategoryGroup);

  }

  async searchByCategory(categoryType: string) {
    this.resultSession = [];
    this.searchState = SearchListState.COURSE_LIST;

    let searchCreteriaRequest: SearchSessionModel = {
      categoryType: categoryType === 'ESG' ? SearchSessionModel.CategoryTypeEnum.ESG : categoryType === 'Ethic' ? SearchSessionModel.CategoryTypeEnum.ETHIC : SearchSessionModel.CategoryTypeEnum.GENERAL
    };
    let resultSearch: Array<ResultSessionModel> = await this.getSessionByCondition(searchCreteriaRequest);

    this.resultSession = resultSearch;
  }

  async search() {
    this.resultSession = [];
    this.searchState = SearchListState.COURSE_LIST;
    let inputDate = this.searchDate.nativeElement.value;

    let searchCreteriaRequest: SearchSessionModel = {
      keyword: this.keyword,
      trainingDate : inputDate,
      categoryGroupId: this.categoryIdSelected !== 0 ? this.categoryIdSelected : null,
      trainingProviderId: this.trainingProviderIdSelected
    };
    console.log(searchCreteriaRequest);
    let resultSearch: Array<ResultSessionModel> = await this.getSessionByCondition(searchCreteriaRequest);
    this.resultSession = resultSearch;

  }

  searchByTp(trainingProviderId: number) {

    this.trainingProviderIdSelected = trainingProviderId;
    this.keyword = null;
    this.categoryIdSelected = null;
    this.search();

  }

  async getAllTrainingProviderByTrainingProviderType(searchTrainingProviderModel: SearchTrainingProviderModel): Promise<Array<TrainingProviderModel>> {

    return WSCall.wsCall(
      () => {
        return this._trainingProviderControllerService.getAllTrainingProviderByTrainingProviderTypeUsingPOST1(searchTrainingProviderModel);
      },
      respBody => {
        return respBody.data;
      }
    );
  }

  async getAllCategoryGroup(): Promise<Array<CategoryGroupModel>> {

    return WSCall.wsCall(
      () => {
        return this._publicCategoryGroupControllerService.getAllCategoryGroupUsingGET1();
      },
      respBody => {
        return respBody.data;
      }
    );
  }

  //
  async searchCourseByCreteria(searchCreteriaRequest: SearchCreteriaRequest): Promise<Array<ResultSessionModel>> {

    return WSCall.wsCall(
      () => {
        return this._sessionControllerService.searchCourseByCreteriaUsingPOST(searchCreteriaRequest);
      },
      respBody => {
        return respBody.data;
      }
    );
  }

  async getSessionByCondition(searchCreteriaRequest: SearchSessionModel): Promise<Array<ResultSessionModel>> {

    return WSCall.wsCall(
      () => {
        return this._sessionControllerService.getSessionByConditionUsingPOST1(searchCreteriaRequest);
      },
      respBody => {
        return respBody.data;
      }
    );
  }
  onSearch(event: Event) {

    let input: HTMLInputElement = <HTMLInputElement> event.target;
    this.keyword = input.value;
    console.log(input.value);
  }


  onSelectResultSession(resultSessionModel: ResultSessionModel) {

  }

  onSelectCategory(categoryId: number) {
    this.keyword = null;
    this.categoryIdSelected = categoryId;
    this.trainingProviderIdSelected = null;
    this.search();
  }
  async onSelectByCategory(categoryId: number) {
    let searchCreteriaRequest: SearchSessionModel = {
      categoryGroupId: categoryId !== 0 ? categoryId : null
    };
    let resultSearch: Array<ResultSessionModel> = await this.getSessionByCondition(searchCreteriaRequest);
    console.log(resultSearch);
  }

}

export type SearchListState = 'SHOW_CATEGORY' | 'COURSE_LIST';
export const SearchListState = {

  SHOW_CATEGORY: 'SHOW_CATEGORY' as SearchListState,
  COURSE_LIST: 'COURSE_LIST' as SearchListState

};
