<div class="box-container">
    <div class="box">
        <div class="d-flex flex-row justify-content-center flex-wrap">
            <div class="p-0 m-2 card-detail d-flex flex-column flex-fill text-center">
                <div class="p-2 text-center flex-fill cursor-pointer" style="position: relative;" (click)="gotoElearning()">
                    <img class="img-custom" src="../../../../assets/image/set_elearning_banner.png">
                </div>
            </div>
        </div>
    </div>
</div>