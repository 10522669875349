<form [formGroup]="form">
    <div>
        <p>กรุณาเลือกบริษัทที่ไม่ใช่ต้นสังกัดปัจจุบันของท่าน</p>
    </div>

    <div>
        <label>สังกัดในฐานะพนักงานบริษัท</label>
        <div *ngFor="let item of companyStaffActive" class="form-check pt-1">
            <div class="card card-body  custom-div form-check">
                <label class="pr-3 pl-3 form-check-label">
                    <input type="checkbox" *ngIf="item.hrMOU" [id]="item.company.companyId" class="form-check-input" (click)="onChecked($event,item)" [value]="item.company.companyId" name="companyId">{{item.company.nameTh}}
                </label>
            </div>
        </div>

    </div>

    <div>
        <label>ตัวแทนสนับสนุน (วันที่แจ้งเริ่มปฏิบัติงาน)</label>
        <p *ngIf="companyAgent.length < 1">-</p>
        <div *ngFor="let item of companyAgent">
            <ul>
                <li>
                    {{item.company.nameTh}} ({{ item.startDate | dateThai }} -
                    {{
                    item.endDate != null ? (item.endDate | dateThai) : "ยังปฏิบัติหน้าที่"
                    }}
                    )
                </li>
            </ul>
        </div>
    </div>
</form>
