import { Observable } from 'rxjs/Observable';

export async function wsCall<V, T>(callback: () => Observable<V>, bodyParser: (respBody: V) => T = null): Promise<T> {
    var parser = bodyParser;
    if (parser == null) {
        parser = (respBody: any) => {
            return respBody.data;
        }
    }

    let p = new Promise<T>((resolve, reject) => {
        callback().subscribe((body: V) => {
            resolve(parser(body));
        }, (error) => {
            reject(error);
        });
    });

    return p;
}