<div class="wrapper" [@flyInAnimation]="'in'">
  <app-header-with-back class="header" [title]="title" [path]="'more'"></app-header-with-back>
  <div class="box-container">
    <div class="box">
      <div class="list-group">
        <a [routerLink]="'cancellation-and-privacy'" class="list-group-item list-group-item-action">
          <span>ยกเลิกการใช้ระบบและข้อมูลส่วนบุคคล <i class="fas fa-chevron-right float-right"></i></span>
        </a>
        <a [routerLink]="'change-pin'" class="list-group-item list-group-item-action">

          <span>เปลี่ยนรหัส PIN <i class="fas fa-chevron-right float-right"></i></span>
        </a>
      </div>
      <div class="p-2 text-muted">เพื่อความปลอดภัยของคุณ กรุณาเปลี่ยนรหัส PIN อย่างน้อยปีละ 1 ครั้ง </div>
      <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
    </div>
  </div>
</div>