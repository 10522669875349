import { APIResultLicenseCurrentPeriodResult } from '@proflink/prof-link-web-ic-api-ts-angular/model/aPIResultLicenseCurrentPeriodResult';
import {Component, Input, OnInit} from '@angular/core';
import {IcProfileModel} from '@proflink/prof-link-web-ic-api-ts-angular/model/icProfileModel';
import {IcCompanySecRoleModel} from '@proflink/prof-link-web-ic-api-ts-angular/model/icCompanySecRoleModel';
import {IcCompanyModel} from '@proflink/prof-link-web-ic-api-ts-angular/model/icCompanyModel';
import {LicenseQueryResult} from '@proflink/prof-link-web-ic-api-ts-angular/model/licenseQueryResult';
import {WorkingStatusResult} from '@proflink/prof-link-web-ic-api-ts-angular/model/workingStatusResult';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { IcProfileControllerService } from '@proflink/prof-link-web-ic-api-ts-angular';
import * as WSCall from '@app/utils/WSCall';
import { List } from '@zxing/library/esm/customTypings';

@Component({
  selector: 'app-profile-license',
  templateUrl: './profile-license.component.html',
  styleUrls: ['./profile-license.component.scss', '../personal-profile.component.scss']
})
export class ProfileLicenseComponent implements OnInit {
  @Input() profile: IcProfileModel;
  constructor(
    private _icProfileControllerService: IcProfileControllerService
  ) { }
  expireDate: Date;
  @Input() secRoleStaff: IcCompanySecRoleModel[] = [];
  @Input() companyStaff: IcCompanyModel[] = [];
  @Input() secRoleAgent: IcCompanySecRoleModel[] = [];
  @Input() companyAgent: IcCompanyModel[] = [];

  ngOnInit(): void {
    this.expireDate = Math.max.apply(Math, this.profile.icLicenses.map((o) =>  o.expireDate));
    this.loadData();
  }

   isLicenseCollapsed:Boolean;
   isWorkingCollapsed:Boolean;

   currentLicense : Array<LicenseQueryResult> = [];
   historyLicense : Array<LicenseQueryResult> = [];

   currentWorking : Array<WorkingStatusResult> = [];
   historyWorking : Array<WorkingStatusResult> = [];


  async loadData() {
    this.currentLicense = await this.getCurrentLicenseByIcProfileId(this.profile.icId);
    this.historyLicense = await this.getHistoryLicenseByIcProfileId(this.profile.icId);
    this.currentWorking = await this.getCurrentWorkingByIcProfileId(this.profile.icId);
    this.historyWorking = await this.getHistoryWorkingByIcProfileId(this.profile.icId);
  }

  async getCurrentLicenseByIcProfileId(icProfileId: number): Promise<List<LicenseQueryResult>>{
    return WSCall.wsCall(
      () => {
        return this._icProfileControllerService.getCurrentLicenseByIcProfileIdUsingGET(icProfileId);
      },
      (respBody) => {
        return respBody.data;
      }
    );
  }

  async getHistoryLicenseByIcProfileId(icProfileId: number): Promise<List<LicenseQueryResult>>{
    return WSCall.wsCall(
      () => {
        return this._icProfileControllerService.getHistoryLicenseByIcProfileIdUsingGET(icProfileId);
      },
      (respBody) => {
        return respBody.data;
      }
    );
  }

  async getCurrentWorkingByIcProfileId(icProfileId: number): Promise<List<WorkingStatusResult>>{
    return WSCall.wsCall(
      () => {
        return this._icProfileControllerService.getCurrentWorkingStatusByIcProfileIdUsingGET(icProfileId);
      },
      (respBody) => {
        return respBody.data;
      }
    );
  }

  async getHistoryWorkingByIcProfileId(icProfileId: number): Promise<List<WorkingStatusResult>>{
    return WSCall.wsCall(
      () => {
        return this._icProfileControllerService.getHistoryWorkingStatusByIcProfileIdUsingGET(icProfileId);
      },
      (respBody) => {
        return respBody.data;
      }
    );
  }
}
