import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IcProfileModel} from '@proflink/prof-link-web-ic-api-ts-angular/model/icProfileModel';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {IcProfileControllerService} from '@proflink/prof-link-web-ic-api-ts-angular/api/icProfileController.service';
import {UpdateProfileRequest} from '@proflink/prof-link-web-ic-api-ts-angular/model/updateProfileRequest';
import {DatePipe} from '@angular/common';
import * as Utils from '@app/utils/Utils';

@Component({
  selector: 'app-profile-information-form',
  templateUrl: './profile-information-form.component.html',
  styleUrls: ['./profile-information-form.component.scss']
})
export class ProfileInformationFormComponent implements OnInit {
  @Input() profile: IcProfileModel;
  @Output('editSuccess') editSuccess: EventEmitter<boolean> = new EventEmitter();
  public form: FormGroup;
  constructor(private fb: FormBuilder,
              private datePipe: DatePipe,
              private icProfileService: IcProfileControllerService) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      idCardNumber: [this.profile.idCard, Validators.required],
      firstName : [this.profile.firstnameTh, Validators.required],
      middleName : [this.profile.middlenameTh],
      lastName : [this.profile.lastnameTh, Validators.required],
      birthday : [this.profile.birthday, Validators.required],
      laserId : ['', Validators.required],
    });
  }
  save(): void {
    if (this.form.valid) {
      const request: UpdateProfileRequest = {
        birthDay: this.datePipe.transform(this.profile.birthday, 'yyyy-MM-dd'),
        firstnameEn: this.profile.firstnameEn,
        firstnameTh: this.form.controls.firstName.value,
        idCard: this.profile.idCard,
        laserId: this.form.controls.laserId.value,
        lastnameEn: this.profile.lastnameEn,
        lastnameTh: this.form.controls.lastName.value,
        middlenameEn: this.profile.middlenameEn,
        middlenameTh: this.form.controls.middleName.value,
      };
      this.icProfileService.updateProfileUsingPOST(request).subscribe((res) => {
        if (res.resultCode === 'ERROR') {
          Utils.error(res.errorMessage, '');
        } else {
          this.editSuccess.emit(true);
        }
      });
    } else {
      this.form.markAllAsTouched();
    }
  }

}
