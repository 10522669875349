
<app-header-with-back-emitter class="header"
                              (backComplete)="onBack()"
                      [title]="title"></app-header-with-back-emitter>


<div class="d-flex flex-row justify-content-center flex-wrap">

    <div *ngFor="let cs of courseByCategory" class="p-0 m-2 card-detail d-flex flex-column flex-fill text-center" (click)="viewDetail(cs)">
        <div class="p-0 text-center flex-fill">
            <img class="img-custom" [src]="cs.thumbnail">
        </div>

        <div class="p-0 detail-bg-2 m-3 text-center d-flex flex-column flex-fill">
            <div class="p-0 m-0">
                <label class="description-1">{{cs.title}}</label><br/>
            </div>

            <div class="p-0 text-left m-0">
                <div class="description-2">
                    {{cs.shortDescripton}}
                </div>
            </div>
        </div>
    </div>

</div>
