
<div class="d-flex flex-column">
    <div class="p-0" *ngIf="upcomingCourses!= null && upcomingCourses.length > 0">
        <ngb-carousel #carousel class="p-2" id="carousel-upcoming">
            <ng-template ngbSlide *ngFor="let u of upcomingCourses">
                <div class="pb-1" (click)="getDetail(u)">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="card card-margin" style="width: 340px; height:150px;">
                                    <div class="card-header no-border">
                                    </div>
                                    <div class="card-body pt-0">
                                        <div class="widget-49">
                                            <div class="widget-49-title-wrapper">
                                                <div class="widget-49-date-primary">
                                                    <span
                                                        class="widget-49-date-day">{{getDisplayDay(u.firstDate)}}</span>
                                                    <span
                                                        class="widget-49-date-month">{{getDisplayMonth(u.firstDate)}}</span>
                                                </div>
                                                <div class="widget-49-meeting-info text-left">
                                                    <span>
                                                        <label *ngIf="u.nonEthicTimeMins>0"
                                                            class="badge rounded-pill bg-light text-dark">ความรู้ด้านอื่นๆ
                                                            <span
                                                                class="badge badge-pill badge-warning">   {{u.nonEthicTimeMins/60}}</span></label>
                                                        <label *ngIf="u.ethicTimeMins>0"
                                                            class="badge rounded-pill bg-light text-dark">กฏระเบียบฯ<span
                                                                class="badge badge-pill badge-warning">   {{u.ethicTimeMins/60}}</span></label>
                                                        <label *ngIf="u.esg>0"
                                                            class="badge rounded-pill bg-light text-dark">Default
                                                            ESG <span
                                                                class="badge badge-pill badge-warning">   {{u.esg/60}}</span></label></span>
                                                    <div class="text-left"> <span> <i
                                                                class="fa fa-map-marker-alt pr-2"></i>
                                                            <label
                                                                class="text-muted">{{u.session.province.provinceTh}}</label>
                                                        </span></div>


                                                </div>
                                            </div>
                                            <div class="widget-49-meeting-info widget-49-pro-title">
                                                <span>
                                                    <label class="text-muted">{{u.session.sessionName}}</label>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </ngb-carousel>
    </div>
</div>
