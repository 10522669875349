import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import * as Utils from '@app/utils/Utils.ts';
import { AccountControllerService } from "@proflink/prof-link-web-ic-api-ts-angular/api/accountController.service";
import { ChangePinModel } from "@proflink/prof-link-web-ic-api-ts-angular/model/changePinModel";
import { sha256 } from "js-sha256";
import { RegisterICTemporaryAccountModel } from "@proflink/prof-link-web-ic-api-ts-angular/model/registerICTemporaryAccountModel";
import { APIResultMapstringstring } from "@proflink/prof-link-web-ic-api-ts-angular/model/aPIResultMapstringstring";
import * as WSCall from "@app/utils/WSCall";
import { APIResultstring } from "@proflink/prof-link-web-ic-api-ts-angular/model/aPIResultstring";
import { AppState } from "@core/app-state";
import { AuthProvider } from "@core/auth/auth-provider.service";
import { Router } from "@angular/router";
import { ResultOTPModel } from "@proflink/prof-link-web-ic-api-ts-angular/model/resultOTPModel";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-create-pin',
  templateUrl: './create-pin.component.html',
  styleUrls: ['./create-pin.component.scss']
})
export class CreatePinComponent implements OnInit {

  constructor(
    private _accountControllerService: AccountControllerService,
    private _appState: AppState,
    private _authProvider: AuthProvider,
    private _router: Router,
    private fb: FormBuilder
  ) { }

  public createPIN: FormGroup;

  @Output('successVerifyOtp')
  changePinSuccess: EventEmitter<string> = new EventEmitter();

  @ViewChild('password')
  password: ElementRef;

  @ViewChild('confirmPassword')
  confirmPassword: ElementRef;

  @Input('otpToken')
  otpToken: string;

  @Input('username')
  username: string;

  @Input("resultOTP")
  set setResultOtp(resultOTP: ResultOTPModel) {
    if (resultOTP != null) {
      this.resultOTP = resultOTP;
    }
  }

  resultOTP: ResultOTPModel;

  canSubmit: boolean = false;

  get f() {
    return this.createPIN.controls;
  }

  ngOnInit(): void {
    this.createForm();
  }


  createForm() {
    this.createPIN = this.fb.group({
      password: ['', Validators.compose([Validators.required, Validators.minLength(6), Validators.pattern(/^\d{6}$/)])],
      confirmPassword: ['', Validators.compose([Validators.required, Validators.minLength(6), Validators.pattern(/^\d{6}$/)])],
      email: ['']
    });

    this.createPIN.valueChanges.subscribe((changeObject) => {
      this.canSubmit = this.createPIN.valid;
    });

  }

  async validateAndChangePin() {


    let password = this.password.nativeElement.value;
    let confirmPassword = this.confirmPassword.nativeElement.value;

    if (password.trim() === '') {
      Utils.error('กรุณกรอก รหัส PIN', '', () => {
        this.password.nativeElement.focus();
      });
      return;
    }

    if (confirmPassword.trim() === '') {
      Utils.error('กรุณากรอก ยืนยันรหัส PIN', '', () => {
        this.confirmPassword.nativeElement.focus();
      });
      return;
    }

    let deviceToken = await this._authProvider.validateAndCreateDeviceToken();

    if (password.trim() === confirmPassword.trim()) {

      let deviceToken = await this._authProvider.validateAndCreateDeviceToken();
      let changePingModel: ChangePinModel = {
        icId: this.resultOTP.icId,
        otpToken: this.otpToken,
        newPassword: sha256(confirmPassword),
        uniqueToken: deviceToken
      };

      let resultChangePin = await this.changePinUsingPOST(changePingModel);
      if (resultChangePin.resultCode == 'SUCCESS') {
        this._appState.login(this.username, sha256(confirmPassword), deviceToken)
          .then(() => {
            this._router.navigate(["home"]);
          })
          .catch(reason => {
            this._router.navigate(["login"]);
          });
      }
    } else {
      Utils.error('การยืนยันรหัส PIN ไม่ตรงกัน', '', () => {
        this.confirmPassword.nativeElement.focus();
      });
      return;
    }
  }

  async createPin() {
    await this.validateAndChangePin();
  }

  async changePinUsingPOST(changePinModel: ChangePinModel): Promise<APIResultstring> {
    return WSCall.wsCall(
      () => {
        return this._accountControllerService.changePinUsingPOST(changePinModel);
      },
      respBody => {
        return respBody;
      }
    );
  }

}
