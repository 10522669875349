import {Host, Input} from '@angular/core';
import { Component, OnInit } from '@angular/core';
import {ResultSessionModel} from "@proflink/prof-link-web-ic-api-ts-angular/model/resultSessionModel";
import {DatePipe} from "@angular/common";
import {flyInAnimation} from "@shared/animations/animation";
import {MyCourseComponent} from "@protected/my-course/my-course.component";
import {SessionModel} from "@proflink/prof-link-web-ic-api-ts-angular/model/sessionModel";
import {ProvinceModel} from "@proflink/prof-link-web-ic-api-ts-angular/model/provinceModel";
import {TrainingDateModel} from "@proflink/prof-link-web-ic-api-ts-angular/model/trainingDateModel";
import {
  PublicSessionControllerService,
  RegisterSessionControllerService,
  RegisterSessionModel,
  SessionSummaryModel
} from '@proflink/prof-link-web-ic-api-ts-angular';
import * as WSCall from "@app/utils/WSCall";

@Component({
  selector: 'app-my-course-detail',
  templateUrl: './my-course-detail.component.html',
  styleUrls: ['./my-course-detail.component.scss'],
  animations: [flyInAnimation]
})
export class MyCourseDetailComponent implements OnInit {

  constructor(
      private _datePipe: DatePipe,
      private _registerSessionController:RegisterSessionControllerService,
      private _publicSessionControllerService:PublicSessionControllerService,
      @Host() myCourseComponent: MyCourseComponent
  ) {

    this._myCourseComponent = myCourseComponent;

  }

  title:string = '';
  holdResult:Boolean;

  public color:string;
  activeId:number=1;


  session:SessionModel;
  summarySession:SessionSummaryModel;



  private _myCourseComponent: MyCourseComponent;


  @Input("displayTrainingDate")
  displayTrainingDate:string='';

  @Input("location")
  location:string='';


  status:string='';


  @Input("holdResult")
  set setHoldResult(holdResult:Boolean){

    if(holdResult != null){
      this.holdResult = holdResult;

      if(!this.holdResult){
        this.status = 'ลงทะเบียนสำเร็จ';
        this.color = 'green';
      }else{
        this.status = 'ระงับผลการอบรม (กรุณาส่งเอกสารยืนยันตัวตน)';
        this.color = 'red';
      }
    }
  }


  resultSessionModel:ResultSessionModel;

  registerSession:RegisterSessionModel;

  @Input("resultSessionModel")
  set setResultSession(resultSessionModel:ResultSessionModel){
    if(resultSessionModel != null){
      this.resultSessionModel = resultSessionModel;
      console.log(resultSessionModel);
      this.loadData();
    }
  }


  arrayCollapses:Array<boolean> = [];
  isCollapsed = false;

  ngOnInit() {




  }

  async loadData(){
    this.session = this.resultSessionModel.session;
    this.registerSession = await this.getRegisterSessionBySid(this.session.sessionId);
    this.summarySession  = await this.getSessionSummaryBySessionId(this.session.sessionId);

    this.displayTrainingDate = this.getPeriodTrainingDayDisplay(this.session.trainingDates);
    let province:ProvinceModel = this.session.province;

    if(province != null){
      this.location = province.provinceTh;
    }
  }


  getDateDisplay(time:string){

    let trainingDate:Date = new Date(time);

    return this._datePipe.transform(trainingDate,"dd MMM yyyy");
  }

  getDateTime(min:number){

    let displayTime = new Date();
    displayTime.setHours(0,min,0,0);

    return this._datePipe.transform(displayTime,"HH:mm");

  }

  getPeriodTrainingDayDisplay(trainingdays:Array<TrainingDateModel>){

    let displayDate:string = '';
    if(trainingdays.length <= 1){

      let trainingDate:Date = new Date(trainingdays[0].trainingDate);
      let birthDaySubmit = this._datePipe.transform(trainingDate, 'dd MMM');
      let yearTraining = Number(this._datePipe.transform(trainingDate, 'yyyy'));

      yearTraining = yearTraining +543;
      displayDate = birthDaySubmit+ ' '+yearTraining;

    }else {

      let firstTrainingDate:Date = new Date(trainingdays[0].trainingDate);
      let lastTrainingDate:Date = new Date(trainingdays[trainingdays.length-1].trainingDate);

      let firstDayDisplay = this._datePipe.transform(firstTrainingDate, 'dd MMM');
      let lastDayDisplay = this._datePipe.transform(lastTrainingDate, 'dd MMM');

      let yearTraining = Number(this._datePipe.transform(firstTrainingDate, 'yyyy'));

      yearTraining =yearTraining +543;
      displayDate = firstDayDisplay +' - '+lastDayDisplay+ ' '+yearTraining;
    }

    return displayDate;
  }

  async getRegisterSessionBySid(sid:number): Promise<RegisterSessionModel> {
    return WSCall.wsCall(
        () => {
          return this._registerSessionController.getRegisterSessionBySidUsingGET(sid);
        },
        respBody => {
          return respBody.data;
        }
    );
  }


  backToList(){
    // this._myCourseComponent.displayDateSelected = '';
    // this._myCourseComponent.locationSelected = '';
    // this._myCourseComponent.holdResultSelected = false;
    // this._myCourseComponent.resultSessionSelected = null;
    // this._myCourseComponent.selected=false;

  }

  async getSessionSummaryBySessionId(sid:number): Promise<SessionSummaryModel> {
    return WSCall.wsCall(
        () => {
          return this._publicSessionControllerService.getSessionSummaryBySessionIdUsingGET(sid);
        },
        respBody => {
          return respBody.data;
        }
    );
  }


}
