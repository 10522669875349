<div class="container banner-profile" [ngStyle]="{'background-color': digitalPassportStatusHexColor}">
    <div>
        <label class="text-welcome-1 pr-1">ยินดีต้อนรับ คุณ </label>
        <label class="text-welcome-1">{{displayName}}</label>
    </div>
    <div>
        <label class="text-welcome-2 pr-1 pr-1">เข้าสู่ระบบครั้งล่าสุด</label>
        <label class="text-welcome-2">{{lastLoginDate | localDate: 'dd/MM/yyyy' }} {{lastLoginTime}}</label>
    </div>
</div>
