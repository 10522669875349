import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-header-with-title',
  templateUrl: './header-with-title.component.html',
  styleUrls: ['./header-with-title.component.scss']
})
export class HeaderWithTitleComponent implements OnInit {

  constructor() { }

  @Input("displayTitle")
  displayTitle:boolean;

  @Input("title")
  title:string;

  ngOnInit(): void {

  }

}
