import { Pipe, PipeTransform } from '@angular/core';
import {DatePipe, formatDate} from '@angular/common';

@Pipe({
  name: 'dateThai'
})
export class DateThaiPipe implements PipeTransform {

  transform(date: any, ...args: unknown[]): unknown {
    const dayMonth = new DatePipe('th-TH').transform(date, 'd MMM');
    const year = new DatePipe('th-TH').transform(date, 'yyyy');
    return dayMonth + ' ' + (Number(year) + 543);
  }

}
