import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { NgbCarousel, NgbCarouselConfig } from "@ng-bootstrap/ng-bootstrap";
import { SessionControllerService } from "@proflink/prof-link-web-ic-api-ts-angular/api/sessionController.service";
import { ResultSessionModel } from "@proflink/prof-link-web-ic-api-ts-angular/model/resultSessionModel";
import { SearchModel } from "@proflink/prof-link-web-ic-api-ts-angular/model/searchModel";
import { fromEvent } from "rxjs";
import { takeWhile } from "rxjs/operators";
import * as WSCall from "@app/utils/WSCall";
import { Session } from "@app/utils/Constants";
import Direction = Session.Direction;
import { MyHammerConfig } from "@app/utils/my-hammer-config";
import { Router } from "@angular/router";
import { DatePipe } from '@angular/common';
import {BroadcastV2ControllerService} from '@proflink/prof-link-web-ic-api-ts-angular/api/broadcastV2Controller.service';
import {BroadcastResult} from '@proflink/prof-link-web-ic-api-ts-angular/model/broadcastResult';

@Component({
  selector: 'app-upcoming-course-list',
  templateUrl: './upcoming-course-list.component.html',
  styleUrls: ['./upcoming-course-list.component.scss']
})
export class UpcomingCourseListComponent implements OnInit {

  constructor(
    private _router: Router,
    config: NgbCarouselConfig,
    private _sessionControllerService: SessionControllerService,
    private _datePipe: DatePipe
  ) {

    config.showNavigationArrows = false;

  }

  @Output('selectedResult')
  selectedResultEventEmitter: EventEmitter<ResultSessionModel> = new EventEmitter();

  @Output('upcomingCoursesResult')
  upcomingCoursesEventEmitter: EventEmitter<Array<ResultSessionModel>> = new EventEmitter();




  @ViewChild('carousel', { static: true }) carousel: NgbCarousel;

  alive: boolean = true;
  result: Direction;

  upcomingCourses: Array<ResultSessionModel> = [];


  async ngOnInit() {

    this.loadData();
  }

  async loadData() {

    let icId = +(localStorage.getItem("ic_id"));
    let searchModel: SearchModel = {
      id: icId,
      limitResults: 5
    };

    this.upcomingCourses = await this.getSessionNewBroadCastByCondition(searchModel);
    this.upcomingCoursesEventEmitter.emit(this.upcomingCourses);


  }

  ngAfterViewInit() {

    const hammerConfig = new MyHammerConfig()
    let carouselUpcomingElement = document.getElementById("carousel-upcoming");
    const hammer = hammerConfig.buildHammer(carouselUpcomingElement)
    fromEvent(hammer, "swipe").pipe(
      takeWhile(() => this.alive))
      .subscribe((res: any) => {
        this.result = res.deltaX < 0 ? Direction.Left : Direction.Right;
        this.swipeTouch(this.result);
      });

  }

  swipeTouch(result: Direction) {
    if (result == Direction.Left) {
      this.carousel.next();
    } else {
      this.carousel.prev();
    }
  }

  async getSessionNewBroadCastByCondition(searchModel: SearchModel): Promise<Array<ResultSessionModel>> {
    return WSCall.wsCall(
      () => {
        return this._sessionControllerService.getSessionNewBroadCastByConditionUsingPOST1(searchModel);
      },
      respBody => {
        return respBody.data;
      }
    );
  }

  getDetail(resultSessionModel: ResultSessionModel) {

    let pathBack = 'home';
    this._router.navigate([`/my-course-detail-share/${resultSessionModel.session.sessionId}/0/${pathBack}`]);
  }

  getDisplayDay(date: Date) {

    if (date == null) {
      return null;
    }

    let dateMonthExpired = this._datePipe.transform(date, 'dd MMMM');
    let yearExpired = Number(this._datePipe.transform(date, 'yyyy'));
    yearExpired = yearExpired + 543;
    let day = this._datePipe.transform(date,'dd');

    let displayDate = day;
    return displayDate;

  }


  getDisplayMonth(date: Date) {

    if (date == null) {
      return null;
    }

    let month = this._datePipe.transform(date, 'MMM');
    let yearExpired = Number(this._datePipe.transform(date, 'yyyy'));
    yearExpired = yearExpired + 543;

    let displayDate = month;

    return displayDate;

  }

}
