<div class="d-flex justify-content-center">
    <div class="card custom-card">
        <div class="card-body">
            <div class="text-center">
                <!--    <div class="d-flex flex-row-reverse d-inline-block justify-content-center">-->
                <!--        <div class="p-2 m-0 p-0">-->
                <!--            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style="width: 4rem;height: 4rem;">-->
                <!--                <path d="M415.6 434h-5.1c-6.5 0-12-.9-12.7-7.4v-.1C383.2 290.6 297.6 288 297.6 256s85.7-34.6 100.2-170.5v-.1c.7-6.5 6.2-7.4 12.7-7.4h5.1c7.5 0 14-5.8 14.4-13.3.4-8-6-14.7-14-14.7H96.4c-7.5 0-14 5.8-14.4 13.3-.4 8 6 14.7 14 14.7h5.5c6.5 0 12 .9 12.7 7.4v.1C128.8 221.4 214.4 224 214.4 256s-85.7 34.6-100.2 170.5v.1c-.7 6.5-6.2 7.4-12.7 7.4h-5.1c-7.5 0-14 5.8-14.4 13.3-.4 8 6 14.7 14 14.7h320c8 0 14.4-6.6 14-14.7-.4-7.5-6.9-13.3-14.4-13.3zm-252.3-34.2c28.7-79.8 79.6-70.1 79.6-101.6v-55.6c0-19.4-36.8-32.9-59.8-64.4-3.8-5.2 0-12.4 6.5-12.4h132.9c6.5 0 10.4 7 6.7 12.2-22.6 31.6-60.1 45.2-60.1 64.6v55.6c0 31.2 48.9 22.7 79.8 101.6 2.8 7.1.7 16-6.9 16H170.2c-7.7 0-9.5-8.8-6.9-16z"/>-->
                <!--            </svg>-->
                <!--        </div>-->

                <!--    </div>-->

                <div class="d-flex progress-bar-custom d-inline-flex flex-row flex-fill justify-content-center">

                    <div id="percentDisplay" class="p-0 d-flex flex-row-reverse flex-fill">
                        <li class="li-gray"></li>
                        <li class="li-gray"></li>
                        <li class="li-gray"></li>
                        <li class="li-gray"></li>
                        <li class="li-gray"></li>
                        <li class="li-gray"></li>
                        <li class="li-gray"></li>
                        <li class="li-gray"></li>
                        <li class="li-gray"></li>
                        <li class="li-gray"></li>
                        <li class="li-gray"></li>
                        <li class="li-gray"></li>
                        <li class="li-gray"></li>
                        <li class="li-gray"></li>
                        <li class="li-gray"></li>
                        <li class="li-gray"></li>
                        <li class="li-gray"></li>
                        <li class="li-gray"></li>
                        <li class="li-gray"></li>
                        <li class="li-gray"></li>
                        <li class="li-gray"></li>
                        <li class="li-gray"></li>
                        <li class="li-gray"></li>
                        <li class="li-gray"></li>
                        <li class="li-gray"></li>
                    </div>

                    <div class="p-0">
                        <img [src]="logoPathDisplay" class="icon-hourglass">
                    </div>
                </div>
            </div>
            <div class="d-flex flex-column flex-fill pt-3">
                <div class="p-0 flex-fill text-center">
                    <span class="text-license-2">ใบอนุญาตฯ จะหมดอายุใน</span>
                </div>

                <div class="p-0 flex-fill d-inline-flex text-center">
                    <div class="p-0 flex-fill">
                        <label [ngClass]="graphClassDisplay" class="time-expired pr-1">{{duration.years < 0 ? 0 :duration.years}}</label>
                                <label class="text-unit pr-4">ปี</label>
                                <label [ngClass]="graphClassDisplay" class="time-expired p-1">{{duration.months}}
                                </label>
                                <label class="text-unit pr-4">เดือน</label>
                                <label [ngClass]="graphClassDisplay" class="time-expired pr-1">{{duration.days}}
                                </label>
                                <label class="text-unit pr-4">วัน</label>
                    </div>
                </div>

                <div *ngIf="expiredDateDisplay != null" class="p-0 flex-fill d-flex d-inline-flex text-center">
                    <div class="p-0 flex-fill">
                        <label class="p-0 text-expired-2">วันที่หมดอายุ ทะเบียน</label>
                        <label class="pl-1 expired-date-2">{{expiredDateDisplay}}</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-footer privilege-color" *ngIf="privilegeDescription">
            <span class="text-small-responsive">{{privilegeDescription}}</span>
        </div>
        <div class="card-footer renew-color" *ngIf="showRenewLicense">
            <span *ngIf="showRenewLicense" class="text-small-responsive">ขณะนี้สำนักงาน ก.ล.ต. เปิดรับต่ออายุใบอนุญาตฯ แล้ว</span>
        </div>
        <div class="card-footer expired-color" *ngIf="showExpiredLicense">
            <span *ngIf="showExpiredLicense" class="text-small-responsive">คุณขาดการต่อใบอนุญาตฯ กรุณาติตต่อสำนักงาน ก.ล.ต.</span>
        </div>
    </div>
</div>