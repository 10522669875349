<div class="container">
    <div class="col-sm-12 pt-4">
        <div class="col-sm-12 p-1 border-custom">
            <div class="col-sm-12 text-center pt-2 m-">
                <label class="header-info">กรุณาเตรียมหลักฐานการขอเปลี่ยนเบอร์มือถือ ดังนี้</label>
            </div>
            <div class="col-sm-12 pt-1">
                <div class="col-sm-12">
                    <label class="body-info">
                        โปรดทราบว่า บัตรประจำตัวประชาชน หรือเอกสารแสดงตนอย่างอื่นที่หน่วยงานของรัฐออกให้
                            เป็นข้อมูลที่จำเป็นต่อการยืนยันตัวตนเพื่อการใช้บริการตามที่ท่านร้องขอ
                            โดยบัตรประจำตัวประชาชนหรือเอกสารแสดงตนอย่างอื่นที่หน่วยงานของรัฐออกให้
                            อาจปรากฏข้อมูลส่วนบุคคลที่มีความอ่อนไหว เช่น ข้อมูลศาสนา ข้อมูลหมู่โลหิตข้อมูลเชื้อชาติ
                            เป็นต้น ซึ่งเป็นข้อมูลที่ไม่จำเป็นต่อการให้บริการดังกล่าว และกลุ่มตลาดหลักทรัพย์ฯ
                            รวมถึงผู้ให้บริการที่เกี่ยวข้องไม่มีความประสงค์ที่จะเก็บข้อมูลส่วนบุคคลที่มีความอ่อนไหวดังกล่าว
                       </label>
                       <label class="body-info">
                            ดังนั้น
                            เจ้าของข้อมูลส่วนบุคคลสามารถดำเนินการปกปิดข้อมูลส่วนบุคคลที่มีความอ่อนไหวดังกล่าวก่อนนำส่งเอกสารแสดงตนที่ปรากฎข้อมูลส่วนบุคคลที่มีความอ่อนไหวนั้นให้แก่กลุ่มตลาดหลักทรัพย์ฯ
                            รวมถึงผู้ให้บริการที่เกี่ยวข้อง ทั้งนี้
                            ในกรณีที่ไม่ได้ดำเนินการปกปิดข้อมูลส่วนบุคคลที่มีความอ่อนไหว กลุ่มตลาดหลักทรัพย์ฯ รวมถึง
                            ผู้ให้บริการที่เกี่ยวข้องจะถือว่าท่านได้ให้ความยินยอมแก่กลุ่มตลาดหลักทรัพย์ฯ
                            รวมถึงผู้ให้บริการที่เกี่ยวข้องในการประมวลผลข้อมูลส่วนบุคคลที่มีความอ่อนไหวแล้ว
                        </label>
                </div>
            </div>
            <div class="col-sm-12 pt-4">
                <div class="col-sm-12">
                    <label class="body-info">1.ภาพถ่ายบัตรประชาชนที่ชัดเจน โดยขอให้ปิดทึบในส่วนของเชื้อชาติ
                        ศาสนาและกรุ๊บเลือด</label>
                </div>

                <div class="col-sm-12">
                    <img src="../../../../assets/image/dc_1.png" width="200px" />
                </div>
            </div>

            <div class="col-sm-12 pt-4">
                <div class="col-sm-12">
                    <label class="body-info">2.ภาพถ่ายตัวท่านเองคู่กับบัตรประชาชน โดยขอให้ปิดทึบในส่วนของเชื้อชาติ
                        ศาสนาและกรุ๊บเลือด</label>
                </div>

                <div class="col-sm-12">
                    <img src="../../../../assets/image/verify05.jpeg" width="200px" />
                </div>
            </div>
        </div>

        <div class="col-sm-12 text-center p-2">
            <button class="btn btn-primary" (click)="nextStage()">ดำเนินการต่อ</button>
        </div>

    </div>


</div>
