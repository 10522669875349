import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header-with-back-emitter',
  templateUrl: './header-with-back-emitter.component.html',
  styleUrls: ['./header-with-back-emitter.component.scss']
})
export class HeaderWithBackEmitterComponent implements OnInit {

  @Input() title: string;
  @Input() path: string;
  @Input() queryParams: string;

  @Output('backComplete')
  backComplete: EventEmitter<Boolean> = new EventEmitter();


  constructor(private router: Router) { }

  ngOnInit() { }

  back() {

    this.backComplete.emit(true);
  }



}
