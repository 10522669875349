<div>
    <div class="text-center">

        <img [src]="profileLink" width="100px" height="100px" onerror="this.src='../../../../../assets/image/unknown_person.jpeg'">
    </div>
    <div>
        <label>ชื่อ-สกุล</label>
        <p>{{firstName}} {{middleName}} {{lastName}}</p>
    </div>
    <div>
        <label>วันเกิด</label>
        <p>{{profile.birthdayTh}}</p>
    </div>
    <div>
        <label>สัญชาติ</label>
        <p>{{profile.nationality.nationalityTh}}</p>
    </div>
    <div *ngIf="profile.nationality.nationalityCode == 'TH'">
        <label>เลขประจำตัวประชาชน</label>
        <p>{{profile.idCard}}</p>
    </div>
    <div *ngIf="profile.nationality.nationalityCode != 'TH'">
        <label>เลขพาสปอร์ต</label>
        <p>{{profile.passport}}</p>
    </div>
    <button *ngIf="canEdit"  type="button" class="btn btn-block un-submit bg-primary" (click)="edit()" >เปลี่ยนแปลงข้อมูล</button>
</div>
