import {Component, Input, OnInit} from '@angular/core';
import {NewsModel, TrainingProviderModel} from '@proflink/prof-link-web-ic-api-ts-angular';

@Component({
  selector: 'app-search-training-provider-detail',
  templateUrl: './search-training-provider-detail.component.html',
  styleUrls: ['./search-training-provider-detail.component.scss']
})
export class SearchTrainingProviderDetailComponent implements OnInit {

  constructor() { }

  @Input("trainingProvider")
  trainingProvider:TrainingProviderModel;

  ngOnInit(): void {


  }

  getDownloadLink(td:TrainingProviderModel){

    let downloadLink = '';
    if(td.logoFile != null && td.logoFile.downloadLink != null){
      downloadLink = td.logoFile.downloadLink;
    }

    return downloadLink;

  }


}
