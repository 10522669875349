<div class="col-sm-12 p-0 m-0" [@flyInAnimation]="'in'">
<!--    <app-header-with-back class="header header-green" [title]="" path="home"></app-header-with-back>-->

    <app-my-course-detail-banner (backComplete)="backToList()"
                                 [holdResult]="holdResult"
                                 [status]="status"
                                 [displayTrainingDate]="displayTrainingDate"
                                 [location]="location"
                                 [session]="session"
    ></app-my-course-detail-banner>

    <div class="col-sm-12">
    <ul ngbNav #nameDetail="ngbNav" [activeId]="1" class="nav-tabs nav-pills nav-justified">

        <li  [ngbNavItem]="1">
            <a ngbNavLink>การเข้าเรียน</a>
            <ng-template ngbNavContent>
                <app-tab-course-checkin-checkout
                        [session]="resultSessionModel.session"
                        [registerSession]="registerSession"
                ></app-tab-course-checkin-checkout>
            </ng-template>
        </li>

        <li  [ngbNavItem]="2">
            <a ngbNavLink>หลักสูตร</a>
            <ng-template ngbNavContent>

                <app-tab-course-detail [summarySession]="summarySession"></app-tab-course-detail>
            </ng-template>
        </li>

        <li  [ngbNavItem]="3">
            <a ngbNavLink>รายละเอียด</a>
            <ng-template ngbNavContent>
                <app-tab-course-location-detail [session]="summarySession.session"></app-tab-course-location-detail>
            </ng-template>
        </li>
    </ul>

    <div [ngbNavOutlet]="nameDetail" class="mt-2"></div>
    </div>

    <div class="p-5"></div>
</div>
