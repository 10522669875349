<div class="box-container" *ngIf="searchState == 'SHOW_CATEGORY'">
    <div class="box">
        <div class="flex-row">
            <div class="d-flex flex-column">
                <div class="list-group">
                    <div class="list-group-item list-group-item-action">
                        <div class="input-group">
                            <input placeholder="ค้นหา ชื่อหลักสูตร,หัวข้ออบรม,จังหวัด" class="form-control"
                                (change)="onSearch($event)">
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary calendar" type="button" style="width: 70px"
                                    (click)="search()">
                                    <i class="fas fa-search"></i>
                                </button>
                            </div>
                        </div>

                        <div class="pt-1 input-group">
                            <input class="form-control" placeholder="วันที่อบรม" [minDate]="minDate" #searchDate
                                (dateSelect)="search()" name="dp" ngbDatepicker #d="ngbDatepicker">
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"
                                    style="width: 70px">
                                    <i class="far fa-calendar-alt"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <!--        <div class="d-flex flex-column" *ngIf="searchState == 'SHOW_CATEGORY'">-->

                <!--            <div class="pl-2 m-2 flex-fill text-left">-->
                <!--                <i class="far fa-bookmark pr-1 text-warning"></i>-->
                <!--                <label class="title-headline-box">สถานบันฝึกอบรม</label>-->
                <!--            </div>-->

                <!--            <div class="pl-2 m-2 flex-fill text-left">-->

                <!--            </div>-->
                <!--        </div>-->
                <div class="d-flex flex-column" *ngIf="searchState == 'SHOW_CATEGORY'">
                    <div class="pl-2 m-2 flex-fill text-left">
                        <!-- <i class="far fa-bookmark pr-1 text-warning"></i> -->
                        <label class="title-headline-box">Training Provider</label>
                    </div>

                </div>

                <div class="list-group" *ngIf="searchState == 'SHOW_CATEGORY'">

                    <div class="d-flex d-inline-flex flex-row ml-2 mr-2 content">
                        <div *ngFor="let tp of trainingProviders">

                            <div *ngIf="tp.logoFile !=null && tp.logoFile.downloadLink !=null ; else lodeImageError"
                                class="pl-2 pr-2">

                                <img class="box-shadow d-inline-block" [src]="tp.logoFile.downloadLink"
                                    [alt]="tp.abbreviation" (click)="searchByTp(tp.trainingProviderId)" width="96px"
                                    height="64px">
                            </div>

                            <ng-template #lodeImageError>

                                <button class="text-center box-shadow card-image-link"
                                    (click)="searchByTp(tp.trainingProviderId)">
                                    <div class="  d-inline-block">
                                        <div class="text-center">
                                            <h2 class="text-info">{{tp.abbreviation}}</h2>
                                        </div>
                                    </div>
                                </button>
                            </ng-template>
                        </div>
                    </div>
                </div>

                <div class="d-flex flex-column" *ngIf="searchState == 'SHOW_CATEGORY'">
                    <div class="pl-2 m-2 flex-fill text-left">
                        <label class="title-headline-box">Area of Knowledge</label>
                    </div>
                </div>

                <div class="list-group" *ngIf="searchState == 'SHOW_CATEGORY'">
                    <a *ngFor="let o of categoryGroups" class="list-group-item list-group-item-action"
                        (click)="onSelectCategory(o.categoryGroupId)">
                        <span>{{o.categoryGroupName}}
                            <i class="fas fa-chevron-right float-right"></i>
                        </span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="searchState == 'COURSE_LIST'">
    <app-search-list (selectedResult)="onSelectResultSession($event)"
        [resultSession]="resultSession"></app-search-list>
</div>