import {Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-change-mobile-number-info',
  templateUrl: './change-mobile-number-info.component.html',
  styleUrls: ['./change-mobile-number-info.component.scss']
})
export class ChangeMobileNumberInfoComponent implements OnInit {

  constructor() { }

  @Output('nextStateComplete')
  nextStateComplete: EventEmitter<Boolean> = new EventEmitter();

  ngOnInit() {



  }

  postMessage(){
    //for develop flutter
    //@ts-ignore
    FLUTTER_CHANEL_CHANGE_MOBILE.postMessage("TEST_BROADCAST_1");

  }

  nextStage(){

    this.nextStateComplete.emit(true);
  }

}
