import {NgxUiLoaderConfig} from 'ngx-ui-loader';

export const loaderConfigConstant: NgxUiLoaderConfig = {
    bgsColor: '#FBAF34',
    bgsOpacity: 0.5,
    bgsPosition: 'bottom-right',
    bgsSize: 60,
    bgsType: 'chasing-dots',
    blur: 5,
    delay: 0,
    fgsColor: '#FBAF34',
    fgsPosition: 'center-center',
    fgsSize: 60,
    fgsType: 'circle',
    gap: 24,
    logoPosition: 'center-center',
    logoSize: 120,
    logoUrl: '',
    masterLoaderId: 'master',
    overlayBorderRadius: '0',
    overlayColor: 'rgba(40, 40, 40, 0.3)',
    pbColor: '#FBAF34',
    pbDirection: 'ltr',
    pbThickness: 3,
    hasProgressBar: true,
    text: '',
    textColor: '#FFFFFF',
    textPosition: 'center-center',
    maxTime: -1,
    minTime: 500
};
