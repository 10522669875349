import {Component, Input, OnInit} from '@angular/core';
import {ResultOTPModel} from "@proflink/prof-link-web-ic-api-ts-angular/model/resultOTPModel";
import {SecCheckIcThaiQueryModel} from "@proflink/prof-link-web-ic-api-ts-angular/model/secCheckIcThaiQueryModel";
import {ConsentVersionControllerService} from "@proflink/prof-link-web-ic-api-ts-angular/api/consentVersionController.service";
import {AccountControllerService} from '@proflink/prof-link-web-ic-api-ts-angular/api/accountController.service';
import {IcAgreeConsentLogSaveRequest} from "@proflink/prof-link-web-ic-api-ts-angular/model/icAgreeConsentLogSaveRequest";
import {APIResultboolean} from "@proflink/prof-link-web-ic-api-ts-angular/model/aPIResultboolean";
import * as WSCall from "@app/utils/WSCall";
import {IcProfileModel} from "@proflink/prof-link-web-ic-api-ts-angular/model/icProfileModel";
import {RegisterICProfileRequest} from "@proflink/prof-link-web-ic-api-ts-angular/model/registerICProfileRequest";
import {APIResultIcProfileModel} from "@proflink/prof-link-web-ic-api-ts-angular/model/aPIResultIcProfileModel";
import * as Utils from "@app/utils/Utils";
import {RegisterICTemporaryAccountModel} from "@proflink/prof-link-web-ic-api-ts-angular/model/registerICTemporaryAccountModel";
import {APIResultResultOTPModel} from "@proflink/prof-link-web-ic-api-ts-angular/model/aPIResultResultOTPModel";
import {CreateICTemporaryAccountModel} from "@proflink/prof-link-web-ic-api-ts-angular/model/createICTemporaryAccountModel";
import {APIResultstring} from "@proflink/prof-link-web-ic-api-ts-angular/model/aPIResultstring";
import {sha256} from "js-sha256";
import {Router} from "@angular/router";
import {AppState} from "@core/app-state";
import {AuthProvider} from "@core/auth/auth-provider.service";

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  constructor(
      private _accountControllerService: AccountControllerService,
      private _router: Router,
      private _appState: AppState,
      private _authProvider: AuthProvider,

  ) { }


  displayTitle:boolean = false;
  title:string ='';

  registerState:RegisterStateEnum = RegisterStateEnum.INSTRUCTIONAL;

  consentVersionNo:string = '';

  resultOTPModel:ResultOTPModel
  token:string='';
  verifySuccess=false;
  requestRegister:SecCheckIcThaiQueryModel;
  icProfile:IcProfileModel;
  url:string;
  createType:string = "1";
  createICTemporaryAccountModel:CreateICTemporaryAccountModel;
  email:string = '';

  submitForm=false;

  ngOnInit() {

    this.displayTitle = true;
    this.title = 'วีดีโอแนะนำวิธีการใช้งาน Prof.Link';
    this.url="https://www.youtube.com/watch?v=IPCiyD6RlQw";
  }

  onAgreeConsent(consentVersionNo: string){
      this.consentVersionNo = consentVersionNo;
      this.registerState = RegisterStateEnum.CHECK_IC;
      this.displayTitle = true;
      this.title ='ลงทะเบียนเพื่อสร้างบัญชีชั่วคราว';
  }


  async onSubmitCreateUser(registerICTemporaryAccountModel:RegisterICTemporaryAccountModel){

    let result:APIResultResultOTPModel = await this.generateOTP(registerICTemporaryAccountModel);
    if(result.resultCode == 'SUCCESS'){

      this.email = registerICTemporaryAccountModel.email;
      this.resultOTPModel = result.data;
      this.registerState = RegisterStateEnum.OTP;
      this.displayTitle = true;
      this.title ='ยืนยันหมายเลข OTP';
    }else{
      Utils.error(result.errorMessage);
    }

  }

  onCompleteState(state: string){

    this.registerState = RegisterStateEnum[state];
  }

  async onCompleteConfirmRegister(registerICProfileRequest : RegisterICProfileRequest){
    
    let result:APIResultIcProfileModel = await this.matchSecIcProfileThai(registerICProfileRequest);
    if(result.resultCode == 'SUCCESS'){
      this.icProfile = result.data;
      this.registerState = RegisterStateEnum.CREATE_USER;
      this.displayTitle = true;
      this.title ='สร้างบัญชี';
    }else{
      Utils.error(result.errorMessage);
    }
  }

  async onCompleteRegisterForm(requestRegister:SecCheckIcThaiQueryModel){
    this.requestRegister = requestRegister;
    let result:APIResultIcProfileModel = await this.matchSecIcThai(requestRegister);
    if(result.resultCode == 'SUCCESS'){
      this.icProfile = result.data;
      this.registerState = RegisterStateEnum.CONFIRM_IC;
      this.displayTitle = true;
      this.title ='ผลการค้นหาเลขที่ใบอนุญาตฯ';
    }else{
      Utils.error(result.errorMessage);
    }
  }

  async onCompleteInstruction(complete:boolean){
   this.registerState = RegisterStateEnum.AGREE_CONSENT;
   this.title ='เงื่อนไขการใช้งานบริการระบบ Prof.Link';
  }

  async onCompleteInstructionLicense(complete:boolean){

    let deviceToken = await this._authProvider.validateAndCreateDeviceToken();
    this._appState.login(this.icProfile.idCard,this.createICTemporaryAccountModel.password,deviceToken)
        .then(() => {
          this._router.navigate(["home"]);
        })
        .catch(reason => {
          Utils.error("เกิดข้อผิดพลาด กรุณาลองใหม่ในภายหลัง");
        });
  }

  async onSubmitCreatePIN(createICTemporaryAccountModel : CreateICTemporaryAccountModel){

    createICTemporaryAccountModel.email = this.email;
    let result:APIResultstring = await this.addTempAccount(createICTemporaryAccountModel);
    if(result.resultCode == 'SUCCESS'){
      this.createICTemporaryAccountModel = createICTemporaryAccountModel;
      this.registerState = RegisterStateEnum.INSTRUCTIONAL_LICENSE;
      this.displayTitle = true;
      this.title ='วิธีการตรวจสอบข้อมูลชั่วโมงอบรมต่ออายุฯ';
    }else{
      Utils.error(result.errorMessage);
    }
  }

  onVerifyOTPSuccess(token:string){

    this.token = token;
    this.verifySuccess = true;
    this.registerState = RegisterStateEnum.CREATE_PIN;
    this.displayTitle = true;
    this.title ='สร้างรหัส PIN';

  }

  async matchSecIcThai(secCheckIcThaiQueryModel : SecCheckIcThaiQueryModel): Promise<APIResultIcProfileModel> {
    return WSCall.wsCall(
        () => {
          return this._accountControllerService.matchSecIcThaiUsingPOST(secCheckIcThaiQueryModel)
        },
        respBody => {
          return respBody;
        }
    );
  }

  async matchSecIcProfileThai(registerICProfileRequest : RegisterICProfileRequest): Promise<APIResultIcProfileModel> {
    return WSCall.wsCall(
        () => {
          return this._accountControllerService.matchSecIcProfileThaiUsingPOST(registerICProfileRequest)
        },
        respBody => {
          return respBody;
        }
    );
  }

  async generateOTP(registerICTemporaryAccountModel : RegisterICTemporaryAccountModel): Promise<APIResultResultOTPModel> {
    return WSCall.wsCall(
        () => {
          return this._accountControllerService.generateOTPUsingPOST(registerICTemporaryAccountModel)
        },
        respBody => {
          return respBody;
        }
    );
  }

  async addTempAccount(createICTemporaryAccountModel : CreateICTemporaryAccountModel): Promise<APIResultstring> {
    return WSCall.wsCall(
        () => {
          return this._accountControllerService.addTempAccountUsingPOST(createICTemporaryAccountModel)
        },
        respBody => {
          return respBody;
        }
    );
  }

}

export type RegisterStateEnum = 'AGREE_CONSENT' | 'CHECK_IC' | 'CONFIRM_IC' | 'CREATE_USER' | 'OTP'| 'CREATE_PIN'|'INSTRUCTIONAL'
export const RegisterStateEnum = {
  INSTRUCTIONAL : 'INSTRUCTIONAL' as RegisterStateEnum,
  AGREE_CONSENT : 'AGREE_CONSENT' as RegisterStateEnum,
  CHECK_IC : 'CHECK_IC' as RegisterStateEnum,
  CONFIRM_IC : 'CONFIRM_IC' as RegisterStateEnum,
  CREATE_USER : 'CREATE_USER' as RegisterStateEnum,
  OTP : 'OTP' as RegisterStateEnum,
  CREATE_PIN : 'CREATE_PIN' as RegisterStateEnum,
  INSTRUCTIONAL_LICENSE : 'INSTRUCTIONAL_LICENSE' as RegisterStateEnum

}
