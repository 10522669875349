<div class="flex-row flex-fill">
    <div class="p-0 text-center banner-custom">

        <div class="p-0 text-center d-flex flex-fill justify-content-center">
            <div class="p-2 d-inline-flex banner-wd banner-opacity " [ngClass]="holdResult ? 'hold-result' : 'success'">
                <div class="p-0">
                    <a class="m-2" style="color: white;" (click)="backToList()">
                        <i class="fa fa-arrow-left"></i>
                    </a>
                </div>
                <div class="p-0 flex-fill">
                    <label class="text-white">{{status}}</label>
                </div>
            </div>
        </div>

        <img class="img-custom" src="../../../../assets/image/session_cover_placeholder.png" height="250px">

        <div class="p-0 text-center d-flex flex-fill justify-content-center">
            <div class="d-inline-flex banner-bg">

            </div>
        </div>

        <div class="p-0 text-center d-flex flex-fill justify-content-center">
            <div class="p-3 d-inline-flex banner-detail banner-wd">
                <div class="p-2 d-flex flex-column">
                    <div class="p-0">
                        <label class="text-warning">{{displayTrainingDate}}</label>
                    </div>

                    <div class="p-0">
                        <label class="text-warning">{{location}}</label>
                    </div>
                </div>

                <div *ngIf="holdResult" class="p-2 flex-fill text-right">
                    <button class="btn btn-warning text-black text-center" [routerLink]="['/consent/ATTACHMENT_DOC']"
                            [queryParams]="{acceptRedirectUrl: '/attachment-document/' + session.sessionId}">แนบเอกสาร</button>
                </div>
            </div>
        </div>
    </div>
</div>
