import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ResultSessionModel} from "@proflink/prof-link-web-ic-api-ts-angular/model/resultSessionModel";

@Component({
  selector: 'app-upcoming-course-detail',
  templateUrl: './upcoming-course-detail.component.html',
  styleUrls: ['./upcoming-course-detail.component.scss']
})
export class UpcomingCourseDetailComponent implements OnInit {

  constructor() { }

  @Output('backPageCallback')
  backPageCallback: EventEmitter<Boolean> = new EventEmitter();


  @Input("resultSessionModel")
  resultSessionModel:ResultSessionModel;

  ngOnInit() {

  }

  onBackPage(resutl:boolean){

    this.backPageCallback.emit(true);

  }

}
