import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'yearThai'
})
export class YearThaiPipe implements PipeTransform {

  transform(yearC: any, ...args: unknown[]): number {
    return (Number(yearC) + 543);
  }

}
