import {Component, OnInit} from '@angular/core';
import * as WSCall from "@app/utils/WSCall";
import {
  MySessionResult,
  RegisterSessionControllerService,
  RegisterSessionV2ControllerService,
  ResultSessionModel,
  ResultSessionNonProflinkModel,
  TrainingDateModel} from '@proflink/prof-link-web-ic-api-ts-angular';
import {DatePipe} from "@angular/common";
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-my-course',
  templateUrl: './my-course.component.html',
  styleUrls: ['./my-course.component.scss']
})

export class MyCourseComponent implements OnInit {

  sessions: Array<MySessionResult> = [];
  sessionTypeEnum = MySessionResult.SessionTypeEnum;
  currentDate: number = new Date().setHours(0, 0, 0, 0);

  constructor(
    private _registerSessionController: RegisterSessionControllerService,
    private _registerSessionV2Controller: RegisterSessionV2ControllerService,
    private _datePipe: DatePipe,
    private activatedRoute: ActivatedRoute,
    private _router: Router,
  ) { }

  ngOnInit() {
    this._registerSessionV2Controller.myAllSessionV2UsingGET().subscribe(res => {
      this.sessions = res.data
    });
    if (this.activatedRoute.snapshot.queryParamMap.get('openLastResultDetail')) {
      this.openLastDetail();
    }
  }

  displayDate(item: MySessionResult): string {
    let displayDate: string = '';
    if (item.startTrainingDate && item.endTrainingDate) {
      let firstTrainingDate: Date = new Date(item.startTrainingDate);
      let lastTrainingDate: Date = new Date(item.endTrainingDate);

      let firstDayDisplay = this._datePipe.transform(firstTrainingDate, 'dd MMM');
      let lastDayDisplay = this._datePipe.transform(lastTrainingDate, 'dd MMM');

      let yearTraining = Number(this._datePipe.transform(firstTrainingDate, 'yyyy'));

      yearTraining = yearTraining + 543;
      if (firstDayDisplay == lastDayDisplay) {
        displayDate = firstDayDisplay + ' ' + yearTraining;
      } else {
        displayDate = firstDayDisplay + ' - ' + lastDayDisplay + ' ' + yearTraining;
      }
    } else {
      let trainingDate: Date = new Date(item.startTrainingDate);
      let birthDaySubmit = this._datePipe.transform(trainingDate, 'dd MMM');
      let yearTraining = Number(this._datePipe.transform(trainingDate, 'yyyy'));

      yearTraining = yearTraining + 543;
      displayDate = birthDaySubmit + ' ' + yearTraining;
    }

    return displayDate;
  }

  openLastDetail(): void {
      const resultSessionSelected = sessionStorage.getItem('resultSessionSelected');
      const result = JSON.parse(resultSessionSelected);
      if (result) {
        this.onSelectResultSession(result);
      }
  }

  async getMyAllSessionByIcId(): Promise<Array<ResultSessionModel>> {
    return WSCall.wsCall(
      () => {
        return this._registerSessionController.getMyAllSessionByIcIdUsingGET();
      },
      respBody => {
        return respBody.data;
      }
    );
  }

  async getMyAllSessionNonProflink(): Promise<Array<ResultSessionNonProflinkModel>> {
    return WSCall.wsCall(
      () => {
        return this._registerSessionController.getMyAllSessionNonProflinkUsingGET();
      },
      respBody => {
        return respBody.data;
      }
    );
  }

  onSelectSession(item: MySessionResult): void {
    if (item.sessionId) {
      this._router.navigate([`/my-course-detail-share/${item.sessionId}/1/mycourse`]);
    }
  }

  onSelectResultSession(resultSessionModel: ResultSessionModel) {
    sessionStorage.setItem('resultSessionSelected', JSON.stringify(resultSessionModel));
    if (resultSessionModel.sessionId) {
      this._router.navigate([`/my-course-detail-share/${resultSessionModel.sessionId}/1/mycourse`]);
    }
  }

  getPeriodTrainingDayDisplay(trainingdays: Array<TrainingDateModel>) {

    let displayDate: string = '';
    if (trainingdays.length <= 1) {

      let trainingDate: Date = new Date(trainingdays[0].trainingDate);
      let birthDaySubmit = this._datePipe.transform(trainingDate, 'dd MMM');
      let yearTraining = Number(this._datePipe.transform(trainingDate, 'yyyy'));

      yearTraining = yearTraining + 543;
      displayDate = birthDaySubmit + ' ' + yearTraining;

    } else {

      let firstTrainingDate: Date = new Date(trainingdays[0].trainingDate);
      let lastTrainingDate: Date = new Date(trainingdays[trainingdays.length - 1].trainingDate);

      let firstDayDisplay = this._datePipe.transform(firstTrainingDate, 'dd MMM');
      let lastDayDisplay = this._datePipe.transform(lastTrainingDate, 'dd MMM');

      let yearTraining = Number(this._datePipe.transform(firstTrainingDate, 'yyyy'));

      yearTraining = yearTraining + 543;
      displayDate = firstDayDisplay + ' - ' + lastDayDisplay + ' ' + yearTraining;
    }

    return displayDate;
  }

}
