import { Component, OnInit } from '@angular/core';
import { flyInAnimation } from '@app/shared/animations/animation';
@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss'],
  animations: [flyInAnimation]
})
export class ContactUsComponent implements OnInit {

  title = 'ติดต่อเรา';

  constructor() { }

  ngOnInit(): void {
  }

}
