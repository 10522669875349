<div class="wrapper">

    <!-- <app-header-with-banner class="header" [title]="'test'"></app-header-with-banner> -->

    <div
        class="container col-sm-12 offset-md-2 col-md-8 offset-lg-3 col-lg-6 offset-xl-4 col-xl-4 content custom-input">
        <div class="d-flex m-4 align-items-center justify-content-center">
            <img src="../../../../../assets/image/logo_old.png" width="110px" height="110px" style="border: 1px solid #FEA30B; border-radius: 18px;">
        </div>
        <form class="row custom-container">

            <div class="card col-sm-12 m-sm-0 p-0">
                <div class="card-body pt-5 pb-5 pl-0 pr-0 m-sm-0 m-xl-4 m-md-4">
                    <div class="col-sm-12">
                        <div class="col-sm-12">
                            <label>Username</label>
                        </div>

                        <div class="col-sm-12">
                            <input type="text" name="username" class="form-control" maxlength="13" (change)="onChangeInputUsername()"
                                (keypress)="onEnterKeyUserName($event)"
                                [(ngModel)]="entryData.username" />
                        </div>
                    </div>

                    <div *ngIf="showFromPassword" class="col-sm-12 pt-3">
                        <div class="col-sm-12">
                            <label>รหัส PIN</label>
                        </div>

                        <div class="col-sm-12">
                            <input type="password" name="password" class="form-control input-password" maxlength="6"
                                (keypress)="onEnterKeyPassword($event)" [(ngModel)]="entryData.password"
                                #inputPassword />
                        </div>
                    </div>

                    <div *ngIf="!canRegister" class="col-sm-12 pt-4 text-center">
                        <div class="col-sm-12">
                            <button type="button" class="btn btn-block btn-login" (click)="submitLogin()">
                                <label class="custom-font">เข้าสู่ระบบ</label>
                            </button>
                        </div>
                    </div>

                    <div *ngIf="canRegister" class="col-sm-12 pt-3 text-center">
                        <div class="col-sm-12">
                            <div class="col-12 pb-3" style="background-color: lightgray;">

                                <div class="p-2">
                                    <span class="text-center custom-font">
                                        คุณยังไม่ได้สร้างบัญชีในระบบ Prof. Link <br />
                                        กรุณาลงทะเบียน<br />
                                    </span>
                                </div>

                                <button type="button" class="btn btn-block btn-login pb-2"
                                    (click)="registerIcProfile()">
                                    <label class="custom-font">ลงทะเบียน</label>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="canForgotPassword && (!canRegister)" class="col-sm-12 pt-3 text-center">
                        <!-- <div class="col-sm-12 pt-3 text-center"> -->
                        <div class="col-sm-12 text-right">
                            <!-- <a class="btn-forgot" href="javascript:void(0);" (click)="forgotPassword()">Forgot PIN</a> -->
                            <a class="btn-forgot" href="javascript:void(0);"
                                (click)="open(content)">Forgot PIN
                                / Help</a>
                        </div>
                    </div>

                    <div *ngIf="!canRegister" class="col-sm-12 pt-3">
                        <div class="col-sm-12">
                            <p class="col-form-label-sm pl-1 custom-font bg-remark remark-text">
                                หมายเหตุ <br />
                                Username คนไทย - กรอกหมายเลขบัตรประชน 13 หลัก <br />
                                Username Foreigner - Fill in your Mobile Number (10 Digit)<br />
                                <u>สำหรับการลงทะเบียนใหม่</u> ให้กรอก Username เป็นหมายเลขบัตรประชาชน 13 หลักแล้วคลิก
                                "เข้าสู่ระบบ"
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>

    <ng-template #content let-modal>
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Forgot PIN / Help</h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="card card-body col-md-12 col-sm-12 m-2">
                <a class="btn-forgot" href="javascript:void(0);" (click)="forgotPassword()">รีเซ็ต PIN</a>
                <span class="text-muted">ตั้งค่า PIN ใหม่ โดยใช้รหัสยืนยันผ่าน SMS</span>
            </div>
            <div class="card card-body col-md-12 col-sm-12 m-2">
                <a *ngIf="entryData.username.length===13" class="btn-forgot" href="javascript:void(0);"
                    (click)="changeMobileNumber()">แจ้งเปลี่ยนแปลงเบอร์มือถือ (เฉพาะคนไทย)</a>
                    <span *ngIf="entryData.username.length!==13" class="btn-forgot" aria-disabled="true">แจ้งเปลี่ยนแปลงเบอร์มือถือ (เฉพาะคนไทย)</span>
                <span class="text-muted">แจ้งปัญหาของคุณมาที่เราเพื่อให้เจ้าหน้าที่ดำเนินการแก้ไขให้</span>
                <span class="text-muted">[Foreigner] - If you would like to change you mobile number, please call SET
                    contact center 02-009-9999</span>
            </div>
        </div>
    </ng-template>
</div>