import { Injectable } from "@angular/core";
import { CookieService } from "ngx-cookie-service";
import { Configuration } from "@proflink/prof-link-web-ic-api-ts-angular";
import { AppState } from "@app/core/app-state";
import { Router } from "@angular/router";
import { TokenEndpointService } from "@proflink/prof-link-springsec-web-ic-api-ts-angular";
import { v4 as uuidv4 } from 'uuid';


@Injectable()
export class AuthProvider {
    constructor(
        private _appState: AppState,
        private _router: Router,
        private _tokenService: TokenEndpointService,
        private _apiConfig: Configuration) {
    }

    private deviceTokenKey:string = 'device-token';

    async onAppLoaded(): Promise<any> {

        return new Promise<any>(async (resolve, reject) => {

            if (!this._apiConfig.apiKeys) {
                this._apiConfig.apiKeys = {};
            }
            const accessToken = localStorage.getItem('access_token');
            if (accessToken != null) {
                this._apiConfig.accessToken = accessToken;
                this._apiConfig.apiKeys.Authorization = 'Bearer ' + accessToken;
            }
            resolve();
        });

    }

    async validateAndCreateDeviceToken(){

        let deviceToken = localStorage.getItem(this.deviceTokenKey);
        if(deviceToken == null){
            deviceToken =  uuidv4() + uuidv4() + uuidv4();
            localStorage.setItem(this.deviceTokenKey,deviceToken);
        }

        return deviceToken;
    }

}
