
<ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
    <li *ngFor="let newsCategory of newsCategories" [ngbNavItem]="newsCategory.newsCategoryId">
        <a ngbNavLink>{{newsCategory.name}}</a>
        <ng-template ngbNavContent>
            <app-news-and-activity-by-category [categoryId]="newsCategory.newsCategoryId"></app-news-and-activity-by-category>
        </ng-template>
    </li>
</ul>

<div [ngbNavOutlet]="nav" class="mt-2"></div>
