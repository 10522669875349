import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IcProfileModel} from '@proflink/prof-link-web-ic-api-ts-angular/model/icProfileModel';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {IcCompanyModel} from '@proflink/prof-link-web-ic-api-ts-angular/model/icCompanyModel';
import {IcProfileControllerService} from '@proflink/prof-link-web-ic-api-ts-angular/api/icProfileController.service';
import {ChangeIcCompanyLogRequest} from '@proflink/prof-link-web-ic-api-ts-angular/model/changeIcCompanyLogRequest';
import {CompanyControllerService} from '@proflink/prof-link-web-ic-api-ts-angular/api/companyController.service';
import {CompanyModel} from '@proflink/prof-link-web-ic-api-ts-angular/model/companyModel';
import * as Utils from '@app/utils/Utils';
import {IcChangedLogControllerService} from '@proflink/prof-link-web-ic-api-ts-angular/api/icChangedLogController.service';
import * as WSCall from '@app/utils/WSCall';
import {APIResultboolean} from '@proflink/prof-link-web-ic-api-ts-angular/model/aPIResultboolean';
import {ValidateMoveCompanyResponse} from '@proflink/prof-link-web-ic-api-ts-angular/model/validateMoveCompanyResponse';
import Swal from 'sweetalert2';
@Component({
    selector: 'app-profile-company-form',
    templateUrl: './profile-company-form.component.html',
    styleUrls: ['./profile-company-form.component.scss']
})
export class ProfileCompanyFormComponent implements OnInit {
    @Input() profile: IcProfileModel;
    @Input() companyStaff: IcCompanyModel[] = [];
    @Input() companyAgent: IcCompanyModel[] = [];

    @Output() editSuccess: EventEmitter<boolean> = new EventEmitter();

    companyStaffActive: IcCompanyModel[] = [];

    companyIdSelected:number = null;

    companyList: CompanyModel[] = [];
    public form = new FormGroup({
        changeType: new FormControl(null, Validators.required),
        companyId: new FormControl('')
    });
    buttonDisabled = true;

    constructor(private icProfileService: IcProfileControllerService,
                private companyService: CompanyControllerService,
                private icChangedLogControllerService: IcChangedLogControllerService) {
    }

    ngOnInit(): void {
        this.companyService.getAllCompanyIgnoreCurrentUsingGET().subscribe((res) => {
            this.companyList = res.data;
        });

        if(this.profile != null){
            this.companyStaffActive = this.profile.listIcCompanyStaff;
            console.log(this.companyStaffActive);
        }

        this.form.controls.changeType.valueChanges.subscribe(
            (value: string) => {
                this.buttonDisabled = false;
                this.form.get('companyId').setValidators(Validators.required);
                this.form.get('companyId').updateValueAndValidity();
            }
        );
    }

    sendRequest(): void {

        console.log(this.form.controls.companyId.value);
        const request: ChangeIcCompanyLogRequest = {
            changeType: 'N',
            companyId: this.companyIdSelected
        };

        this.icProfileService.changedCompanyUsingPOST(request).subscribe((res) => {
            if (res.resultCode === 'ERROR') {
              Utils.error(res.errorMessage, '');
            } else {
              this.editSuccess.emit(true);
            }
        });

    }

    async onChecked(event: Event,icCompanyModel:IcCompanyModel) {
        this.buttonDisabled = true;
        let input: HTMLInputElement = <HTMLInputElement> event.target;
        let companyIds: any = document.getElementsByName("companyId");
        companyIds.forEach(function(x: any) {
            if (x.id != input.id) {
                x.checked = false;
            }
        });

        if(!input.checked){
            return;
        }

        let companyId: number = parseInt(input.value);
        this.companyIdSelected = companyId;
        let validateMoveCompany:ValidateMoveCompanyResponse = await this.canRequestMoveCompany(companyId);
        if(validateMoveCompany.canRequestMoveCompany){
            let message = `คุณได้แจ้งยืนยันไม่ปฏิบัติหน้าที่ในสังกัด ${icCompanyModel.company.nameTh} ไม่สามารถแจ้งซ้ำได้`;
            this.showInfo(message);
            this.buttonDisabled = true;
            return;
        }

        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: "btn btn-warning pl-3 pr-3 m-2",
                cancelButton: "btn btn-light pl-3 pr-3 m-2"
            },
            buttonsStyling: false
        });
        swalWithBootstrapButtons.fire({
            text: `คุณยืนยันแจ้งไม่ปฏิบัติหน้าที่ในสังกัด ${icCompanyModel.company.nameTh}  ใช่หรือไม่`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "ยืนยัน",
            cancelButtonText: "ยกเลิก",
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                this.sendRequest();
            }else{
                companyIds.forEach(function(x: any) {
                    x.checked = false;
                });
            }
        });
    }

    async canRequestMoveCompany(companyId:number): Promise<ValidateMoveCompanyResponse> {
        return WSCall.wsCall(
            () => {
                return this.icChangedLogControllerService.canRequestMoveCompanyUsingGET(companyId);
            },
            respBody => {
                return respBody.data;
            }
        );
    }

    showInfo(message:string){
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: "btn btn-warning pl-3 pr-3 m-2",
                cancelButton: "btn btn-light pl-3 pr-3 m-2"
            },
            buttonsStyling: false
        });

        swalWithBootstrapButtons.fire({
            text: message,
            icon: "warning",
            showCancelButton: false,
            confirmButtonText: "รับทราบ"
        });
    }

}
