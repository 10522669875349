<app-change-mobile-number-info *ngIf="changeMobilePhoneNoState =='INFO'" (nextStateComplete)="onConfirmInfo()">
</app-change-mobile-number-info>
<app-change-mobile-number-form *ngIf="changeMobilePhoneNoState =='CREATE_FORM'"
    (nextStateComplete)="onCreateForm($event)"></app-change-mobile-number-form>
<div *ngIf="changeMobilePhoneNoState == 'AGREE_CONSENT'">
    <app-consent-by-type [isPublic]="false" [consentType]="'ATTACHMENT_DOC'"
        (nextStateComplete)="onConfirmConsent($event)"></app-consent-by-type>
</div>

<app-change-mobile-number-confirm *ngIf="changeMobilePhoneNoState == 'CONFIRM'"
    [changeMobileNumberRequest]="changeMobileNumberRequest" (nextStateComplete)="onConfirmForm($event)">
</app-change-mobile-number-confirm>

<app-otp-share *ngIf="changeMobilePhoneNoState == 'OTP'" (successVerifyOtp)="onVerifyOTPSuccess($event)"
    [resultOTP]="resultOTPModel" [validateNewOTP]="true" pathBack="login"></app-otp-share>
