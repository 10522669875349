import { ChangeMobilePhoneNoState } from './../change-mobile-number.component';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ChangeMobileNumberRequest } from '@proflink/prof-link-web-ic-api-ts-angular/model/changeMobileNumberRequest';
import { RegisterICTemporaryAccountModel } from '@proflink/prof-link-web-ic-api-ts-angular/model/registerICTemporaryAccountModel';
import * as Utils from '@app/utils/Utils';
import { checkIDCardValidator } from '@app/utils/ValidatorCustom';

@Component({
  selector: 'app-change-mobile-number-form',
  templateUrl: './change-mobile-number-form.component.html',
  styleUrls: ['./change-mobile-number-form.component.scss']
})
export class ChangeMobileNumberFormComponent implements OnInit {

  constructor(
    private fb: FormBuilder
  ) { }

  @Output('nextStateComplete')
  nextStateComplete: EventEmitter<ChangeMobileNumberRequest> = new EventEmitter();

  public icForm: FormGroup;

  canSubmit: boolean = false;

  ngOnInit() {

    this.createForm();
  }

  createForm() {
    this.icForm = this.fb.group({
      firstName: ['', Validators.required],
      idCard: ['', Validators.compose([Validators.required, Validators.minLength(13), Validators.pattern(/^-?(0|[1-9]\d*)?$/), checkIDCardValidator()])],
      lastName: ['', Validators.required],
      mobilePhoneNumber: ['', Validators.compose([Validators.required, Validators.pattern(/^\d{10}$/)])],
      middleName: [''],
    });

    this.icForm.valueChanges.subscribe((changeObject) => {
      this.canSubmit = this.icForm.valid;
    })

  }
  get f() {
    return this.icForm.controls;
  }

  checkIDCard(id: any) {
    let sum = 0;
    console.log(id);
    if (!this.isNumber(id)) return false;
    if (id.substring(0, 1) == 0) return false;
    if (id.length != 13) return false;
    for (let i = 0, sum = 0; i < 12; i++)
      sum += parseFloat(id.charAt(i)) * (13 - i);
    if ((11 - sum % 11) % 10 != parseFloat(id.charAt(12))) return false;
    return true;
  }

  isNumber(input: string) {
    var RE = /^-?(0|INF|(0[1-7][0-7]*)|(0x[0-9a-fA-F]+)|((0|[1-9][0-9]*|(?=[\.,]))([\.,][0-9]+)?([eE]-?\d+)?))$/;
    return (RE.test(input));
  }
  submitForm() {


    // console.log("valid"+ this.icForm.valid);
    if (!this.canSubmit) {
      Utils.error("กรุณาตรวจสอบข้อมูลของท่าน แล้วลองใหม่อีกครั้ง");
    }

    //
    let changeMobileNumberRequest: ChangeMobileNumberRequest = {
      idCard: this.icForm.value.idCard.replaceAll(/\s/g, ''),
      mobilePhoneNumber: this.icForm.value.mobilePhoneNumber.replaceAll(/\s/g, ''),
      firstName: this.icForm.value.firstName.replaceAll(/\s/g, ''),
      lastName: this.icForm.value.lastName.replaceAll(/\s/g, ''),
      middleName: this.icForm.value.middleName
    };


    this.nextStateComplete.emit(changeMobileNumberRequest);
  }

}
