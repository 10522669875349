<div class="container-fluid ">
    <div class="col-sm-12">
        <div class="col-sm-12 m-0 text-center">
            <label class="title-topic">ท่านยังไม่ได้ยืนยันอีเมล กรุณาดำเนินการ</label>
        </div>
        <div class="col-sm-12 m-0 text-center">
            <label class="">กรุณาตรวจสอบความถูกต้องของอีเมลที่ท่านให้ข้อมูลไว้กับระบบ หากต้องการแก้ไข หรือเปลี่ยนแปลงอีเมล สามารถแก้ไขในกล่องข้อความด้านล่างแล้วกดปุ่ม "ตกลง"</label>
        </div>

        <div class="d-flex justify-content-center">
                <div class="col-sm-6">
                    <input class="form-control text-center register-normal" [(ngModel)]="email"/>
                </div> 
          
        </div>
        <div class="p-0 m-0 d-flex justify-content-center pt-3">
            <div class="p-2">
                <button (click)="confirmEmail()" class="btn btn-primary btn-email pl-2 pr-2">ตกลง</button>
            </div>
        </div>
        <!-- <div class="col-sm-12 text-center pt-3">
            <button class="btn btn-primary btn-email" (click)="confirmEmail()">ยืนยันอีเมล</button>
        </div> -->
    </div>
</div>

