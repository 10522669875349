
<div class="col-sm-12 card consent">
    <div class="card-img-top">
        <div class="pt-3 text-center" style="font-size: 22px;">
            <p>สำหรับผู้ใช้งานประเภทที่ได้รับความเห็นชอบ</p>
            <p>(ผู้แนะนำการลงทุน (IC) ผู้วางแผนการลงทุน (IP) และนัก</p>
            <p>วิเคราะห์การลงทุน</p>
        </div>
    </div>
    <!-- <object style="min-height: 90%;" class="card-img-top" [data]="pdfUrl">

    </object> -->
            <div class="bloghtml" [innerHTML]="textHtml"></div>
    <div class="card-body">

        <div class="d-flex justify-content-start">
            <label class="remark-text">ข้าพเจ้าได้อ่านและตกลงยินยอมผูกพันตาม “ข้อตกลงและเงื่อนไขการงานระบบ Prof. Link สำหรับผู้ใช้งานประเภทผู้ได้รับความเห็นชอบ(ผู้แนะนาการลงทุน(IC)ผู้วางแผนการลงทุน(IP)และนักวิเคราะห์การลงทุน (IA)”</label>
        </div>

        <div class="d-flex justify-content-center">
            <div class="form-check form-check-inline">
                <input ngbButtonLabel class="form-check-input" name="consentRadio" (click)="updateRadio($event)" type="radio" id="accept" value="accept">
                <label ngbButton class="form-check-label" for="accept">ยอมรับ</label>
            </div>

            <div class="form-check form-check-inline">
                <input ngbButtonLabel class="form-check-input" (click)="updateRadio($event)" name="consentRadio" type="radio" id="notAccept" value="notAccept">
                <label ngbButton class="form-check-label" for="notAccept">ไม่ยอมรับ</label>
            </div>
        </div>

        <div class="d-flex justify-content-around">
            <div class="p-2 flex-fill">
                <button type="button" [disabled]="radioConsent != 'accept'" (click)="onNext()" class="btn btn-block submit">ถัดไป</button>
            </div>
        </div>

<!--        <div class="d-flex justify-content-around">-->
<!--            <div class="p-2 flex-fill">-->
<!--                <button type="button" (click)="submitConsent()" class="btn btn-block submit">ยอมรับ</button>-->
<!--            </div>-->
<!--            <div class="p-2 flex-fill">-->
<!--                <button type="button" (click)="unSubmitConsent()" class="btn btn-block un-submit">ไม่ยอมรับ</button>-->
<!--            </div>-->
<!--        </div>-->
    </div>
</div>
