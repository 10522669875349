<ul class="nav navbar-light bg-light ">
    <li class="nav-item" (click)="back()">
        <div class="nav-link">
            <i class="fas fa-chevron-left icon-header"></i>
        </div>
    </li>
    <li class="nav-item">
        <div class="nav-link">
            <p class="m-0 title-header" [innerHTML]="title"></p>
        </div>
    </li>
    <li class="nav-item">
        <div class="nav-link d-none d-sm-block"></div>
    </li>
</ul>
