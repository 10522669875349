import { Component, OnInit } from '@angular/core';
import { IcProfileModel } from "@proflink/prof-link-web-ic-api-ts-angular/model/icProfileModel";
import { AppState } from "@core/app-state";
import { DatePipe } from "@angular/common";

@Component({
  selector: 'app-banner-profile',
  templateUrl: './banner-profile.component.html',
  styleUrls: ['./banner-profile.component.scss']
})
export class BannerProfileComponent implements OnInit {

  constructor(
    private _appState: AppState,
    private _datePipe: DatePipe
  ) { }

  icProfile: IcProfileModel

  displayName: string;
  digitalPassportStatusHexColor: string = '#fbaf34';
  lastLoginDate: Date;
  lastLoginTime: string;
  ngOnInit() {

    this._appState.loadCurrentUser();
    this.icProfile = this._appState.currentUser;
    if (this.icProfile != null) {
      this.digitalPassportStatusHexColor = this.icProfile.digitalPassportStatusHexColor;
      this.displayName = this.getDisplayName(this.icProfile);
      if (this.icProfile.appUser.lastLoginDate != null) {
        this.lastLoginDate = this.icProfile.appUser.lastLoginDate;
        this.lastLoginTime = this._datePipe.transform(this.icProfile.appUser.lastLoginDate, 'HH:mm');
      } else {
        this.lastLoginDate = new Date();
        this.lastLoginTime = this._datePipe.transform(this.icProfile.appUser.lastLoginDate, 'HH:mm');
      }

    }

  }

  getDisplayName(icProfile: IcProfileModel) {

    let displayName = '';

    if (icProfile.firstnameTh != null && icProfile.firstnameTh != '') {

      let firstNameTh = icProfile.firstnameTh == null ? "" : icProfile.firstnameTh;
      let middlenameTh = icProfile.middlenameTh == null ? "" : icProfile.middlenameTh;
      let lastNameTh = icProfile.lastnameTh == null ? "" : icProfile.lastnameTh;

      displayName = firstNameTh + ' ' + middlenameTh + ' ' + lastNameTh;
    } else {

      let firstNameEn = icProfile.firstnameEn == null ? "" : icProfile.firstnameEn;
      let middlenameEn = icProfile.middlenameEn == null ? "" : icProfile.middlenameEn;
      let lastNameEn = icProfile.lastnameEn == null ? "" : icProfile.lastnameEn;

      displayName = firstNameEn + ' ' + middlenameEn + ' ' + lastNameEn;

    }

    return displayName;

  }

  getLastLoginDateDisplay(date: Date) {

    let displayDate: string = this._datePipe.transform(date, 'dd/MM/yyyy HH:mm:ss');
    return displayDate;

  }

}
