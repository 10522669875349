import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IcProfileModel} from '@proflink/prof-link-web-ic-api-ts-angular/model/icProfileModel';

@Component({
  selector: 'app-profile-contact',
  templateUrl: './profile-contact.component.html',
  styleUrls: ['./profile-contact.component.scss', '../personal-profile.component.scss']
})
export class ProfileContactComponent implements OnInit {
  @Input() profile: IcProfileModel;
  @Output('edit') editForm: EventEmitter<string> = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }
  edit(): void {
    this.editForm.emit('contact');
  }

}
