import { Component, OnInit, ViewChild } from '@angular/core';
import * as WSCall from "@app/utils/WSCall";
import { PublicMiscControllerService } from "@proflink/prof-link-web-ic-api-ts-angular/api/publicMiscController.service";
import { NewsModel } from "@proflink/prof-link-web-ic-api-ts-angular/model/newsModel";
import { Router } from "@angular/router";
import { Session } from "@app/utils/Constants";
import Direction = Session.Direction;
import { NgbCarousel } from '@ng-bootstrap/ng-bootstrap';
import { MyHammerConfig } from '@app/utils/my-hammer-config';
import { fromEvent } from 'rxjs';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'app-news-feed',
  templateUrl: './news-feed.component.html',
  styleUrls: ['./news-feed.component.scss']
})
export class NewsFeedComponent implements OnInit {

  constructor(private _publicMiscControllerService: PublicMiscControllerService,
    private _router: Router) { }

  newsFeeds: Array<NewsModel> = [];
  @ViewChild('carouselNewFeeds', {static : true}) carousel: NgbCarousel;
  alive: boolean = true;
  result: Direction;

  ngOnInit() { }

  async ngAfterViewInit() {
    this.loadData();
    const hammerConfig = new MyHammerConfig()
    let carouselNewFeedElement = document.getElementById("newFeesId");
    const hammer = hammerConfig.buildHammer(carouselNewFeedElement)
    fromEvent(hammer, "swipe").pipe(
      takeWhile(() => this.alive))
      .subscribe((res: any) => {
        this.result = res.deltaX < 0 ? Direction.Left : Direction.Right;
        this.swipeTouch(this.result);
      });
  }

  swipeTouch(result: Direction) {
    if (result == Direction.Left) {
      this.carousel.next();
    } else {
      this.carousel.prev();
    }
  }

  async loadData() {
    this.newsFeeds = await this.getHighlightedNewsList();
  }

  async getHighlightedNewsList(): Promise<Array<NewsModel>> {

    return WSCall.wsCall(() => {
      return this._publicMiscControllerService.getHighlightedNewsListUsingGET();
    }, (respBody) => {
      return respBody.data;
    });
  }

  seeAllNewsHighlight() {
    this._router.navigate(["new-and-activity"]);
  }

  getDownloadLink(newModel: NewsModel) {
    let downloadLink = '';
    if (newModel.cover != null && newModel.cover.downloadLink != null) {
      downloadLink = newModel.cover.downloadLink;
    } else {
      downloadLink = newModel.url;
    }
    return downloadLink;
  }

}
