
<div class="list-group">
    <div *ngFor="let item of problems"
         class="cursor-pointer list-group-item d-flex flex-row d-inline justify-content-between"
         (click)="onSelected(item)"
    >
        <div class="p-0">
            <span class="p-2">{{item.topicName}}</span>
        </div>
        <div class="p-0">
            <i class="fa fa-chevron-right"></i>
        </div>
    </div>
</div>
