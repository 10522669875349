<form [formGroup]="form" *ngIf="!callbackForm">
    <div class="form-group">

        <div class="custom-input">

            <div class="form-group m-0">
                <label>เบอร์มือถือ</label>
            </div>

            <div class="form-group">
                <input class="form-control" formControlName="mobileNumber" [ngClass]="{ 'is-invalid': form.controls.mobileNumber?.touched && form.controls.mobileNumber.errors?.required}" />
                <span *ngIf="form.controls.mobileNumber?.touched && form.controls.mobileNumber.errors?.required"
                      class="danger">
                        กรุณากรอก เบอร์โทรศัพท์มือถือ
                    </span>
                <span *ngIf="form.controls.mobileNumber?.touched && form.controls.mobileNumber.errors?.pattern"
                      class="danger">
                        ข้อมูลเบอร์โทรศัพท์มือถือไม่ถูกต้อง
                    </span>
            </div>

            <div class="form-group m-0">
                <label>อีเมล</label>
            </div>

            <div class="form-group">
                <input class="form-control" formControlName="email" [email]="true" [ngClass]="{ 'is-invalid': form.controls.email?.touched && (form.controls.email.errors?.required || form.controls.email.errors?.email)}"/>
                <span *ngIf="form.controls.email?.touched && form.controls.email.errors?.required"
                      class="danger">
                        กรุณากรอก อีเมล
                    </span>
                <span *ngIf="form.controls.email?.touched && form.controls.email.errors?.email"
                      class="danger">
                        ข้อมูลอีเมลไม่ถูกต้อง
                    </span>
                <p *ngIf="isShowResendEmail">คุณยังไม่ได้ยืนยันตัวตนผ่านทางอีเมล กรุณาส่งใหม่อีกครั้ง
                    <a class="text-primary btn-email" (click)="resendEmail()">คลิก</a>
                </p>
            </div>

            <div class="form-group">
                <button type="button" class="btn btn-block un-submit bg-primary" (click)="save()">บันทึก</button>
            </div>
        </div>
    </div>
</form>
