import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CompleteUploadRequest} from '@proflink/prof-link-web-ic-api-ts-angular/model/completeUploadRequest';
import {FileUploadComponent} from '@public/file-upload/file-upload.component';
import {FileUploadService} from '@app/service/file-service';
import {FileStoreModel} from '@proflink/prof-link-web-ic-api-ts-angular/model/fileStoreModel';
import * as WSCall from '@app/utils/WSCall';
import {HttpClient} from '@angular/common/http';
import {IcProfileControllerService} from '@proflink/prof-link-web-ic-api-ts-angular/api/icProfileController.service';
import {ChangeMobileNumberRequest} from '@proflink/prof-link-web-ic-api-ts-angular/model/changeMobileNumberRequest';
import {ChangeMobileNumberResult} from '@proflink/prof-link-web-ic-api-ts-angular/model/changeMobileNumberResult';
import {ChangeMobileNumberOTPRequest} from '@proflink/prof-link-web-ic-api-ts-angular/model/changeMobileNumberOTPRequest';
import {ChangeMobileNumberOTPResult} from '@proflink/prof-link-web-ic-api-ts-angular/model/changeMobileNumberOTPResult';
import {PublicMiscControllerService} from '@proflink/prof-link-web-ic-api-ts-angular/api/publicMiscController.service';
import {ProtectedMiscControllerService} from '@proflink/prof-link-web-ic-api-ts-angular/api/protectedMiscController.service';
import {Router} from '@angular/router';
import * as Utils from '@app/utils/Utils';

@Component({
  selector: 'app-change-mobile-number-confirm',
  templateUrl: './change-mobile-number-confirm.component.html',
  styleUrls: ['./change-mobile-number-confirm.component.scss']
})
export class ChangeMobileNumberConfirmComponent implements OnInit {

  constructor(
      protected _httpClient: HttpClient,
      private _fileUploadService: FileUploadService,
      private _protMiscService: ProtectedMiscControllerService,
      private _publicMiscControllerService : PublicMiscControllerService,
      private _icProfileControllerService : IcProfileControllerService,
      private fb: FormBuilder
  ) { }


  @ViewChild('attachment1')
  attachment1: FileUploadComponent;

  @ViewChild('attachment2')
  attachment2: FileUploadComponent;

  @ViewChild('imagePreviewUpload1')
  imagePreviewUpload1: ElementRef;

  @ViewChild('imagePreviewUpload2')
  imagePreviewUpload2: ElementRef;


  imageUploadComplete1:FileStoreModel=null;
  imageUploadComplete2:FileStoreModel=null;

  completeResult1:boolean=false;
  completeResult2:boolean=false;


  public icForm: FormGroup;

  @Input("changeMobileNumberRequest")
  changeMobileNumberRequest:ChangeMobileNumberRequest;


  @Output('nextStateComplete')
  nextStateComplete: EventEmitter<ChangeMobileNumberOTPRequest> = new EventEmitter();



  canSubmit:boolean=false;

  ngOnInit() {

    this.createForm();

  }
  get f() {
    return this.icForm.controls;
  }
  createForm(){
    this.icForm = this.fb.group({
      mobileNumberNew: ['', Validators.compose([Validators.required, Validators.pattern(/^\d{10}$/)])],
      mobileNumberNewConfirm: ['', Validators.compose([Validators.required, Validators.pattern(/^\d{10}$/)])],
      email : [""]
    });

    this.icForm.valueChanges.subscribe((changeObject) => {
      this.canSubmit = this.icForm.valid && this.completeResult1 && this.completeResult2;
    })

  }


  async onConfirmInformation() {




    if(!this.canSubmit){
      Utils.error("กรุณาตรวจสอบข้อมูลของท่าน แล้วลองใหม่อีกครั้ง");
      return;
    }

    if(this.icForm.value.mobileNumberNew != this.icForm.value.mobileNumberNewConfirm){
      Utils.error("เบอร์มือถือใหม่ ไม่ตรงกับ ยืนยันเบอร์มือถือใหม่ กรุณาตรวจสอบข้อมูลของท่าน แล้วลองใหม่อีกครั้ง");
      return;
    }

    let changeMobileNumberRequest: ChangeMobileNumberOTPRequest = {
      imageIdCard : this.imageUploadComplete1,
      imagePersonWithIdCard : this.imageUploadComplete2,
      changeMobileNumberRequest :this.changeMobileNumberRequest,
      newMobilePhoneNumber : this.icForm.value.mobileNumberNew
    };


    this.nextStateComplete.emit(changeMobileNumberRequest);

  }

  async onFileSelected1(event: any) {

    let file:File = event.target.files[0];
    let uploadingFs = await this.getDownloadLink();
    let completeUpload:CompleteUploadRequest = {

    };

    await this._fileUploadService.uploadWithFileIgnoreComplete(file, this._httpClient, this._protMiscService, uploadingFs.fileId, uploadingFs.uploadLinkWeb, async () => {
      this.imagePreviewUpload1.nativeElement.src = uploadingFs.downloadLinkWeb;
      this.imageUploadComplete1 = uploadingFs;
      this.completeResult1 = true;

      this.canSubmit = this.icForm.valid && this.completeResult1 && this.completeResult2;
      await this._publicMiscControllerService.completeUploadFileUsingPOST(uploadingFs.fileId,completeUpload);

    }, () => {
      // On fail
      // this.blockUI.stop();
    });

  }

  async onFileSelected2(event: any) {

    let file:File = event.target.files[0];
    let uploadingFs = await this.getDownloadLink();
    let completeUpload:CompleteUploadRequest = {

    };

    await this._fileUploadService.uploadWithFileIgnoreComplete(file, this._httpClient, this._protMiscService, uploadingFs.fileId, uploadingFs.uploadLinkWeb, async () => {
      this.imagePreviewUpload2.nativeElement.src = uploadingFs.downloadLinkWeb;
      this.imageUploadComplete2 = uploadingFs;
      this.completeResult2 = true;

      this.canSubmit = this.icForm.valid && this.completeResult1 && this.completeResult2;
      await this._publicMiscControllerService.completeUploadFileUsingPOST(uploadingFs.fileId,completeUpload);

    }, () => {
      // On fail
      // this.blockUI.stop();
    });
  }

  async getDownloadLink() : Promise<FileStoreModel> {

    let result:ChangeMobileNumberResult = await this.validateChangeMobilePhoneNumber(this.changeMobileNumberRequest);

    const promise = new Promise<FileStoreModel>(function(resolve, reject) {
      resolve(result.fileUpload);
    });

    return promise;
  }

  async validateChangeMobilePhoneNumber(changeMobileNumberRequest:ChangeMobileNumberRequest): Promise<ChangeMobileNumberResult> {
    return WSCall.wsCall(
        () => {
          return this._publicMiscControllerService.validateChangeMobilePhoneNumberUsingPOST(changeMobileNumberRequest)
        },
        respBody => {
          return respBody.data;
        }
    );
  }

  async requestOtpForChangeMobilePhoneNumber(changeMobileNumberRequest: ChangeMobileNumberOTPRequest): Promise<ChangeMobileNumberOTPResult> {
    return WSCall.wsCall(
        () => {
          return this._publicMiscControllerService.requestOtpForChangeMobilePhoneNumberUsingPOST(changeMobileNumberRequest)
        },
        respBody => {
          return respBody.data;
        }
    );
  }




}
