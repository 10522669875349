import {EventEmitter, forwardRef, Input, ViewChild} from '@angular/core';
import {Component, ElementRef, OnInit, Output} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {FileStoreModel} from '@proflink/prof-link-web-ic-api-ts-angular/model/fileStoreModel';
import {HttpClient} from '@angular/common/http';
import {ProtectedMiscControllerService} from '@proflink/prof-link-web-ic-api-ts-angular/api/protectedMiscController.service';
import * as Utils from "@app/utils/Utils";
import * as WSCall from '@app/utils/WSCall';
import {PublicMiscControllerService} from '@proflink/prof-link-web-ic-api-ts-angular';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  exportAs: 'fileUpload',
  providers: [
    {
      // See tutorial in : https://blog.thoughtram.io/angular/2016/07/27/custom-form-controls-in-angular-2.html
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileUploadComponent),
      multi: true
    }
  ]
})
export class FileUploadComponent implements OnInit,ControlValueAccessor {

  @ViewChild('fileInputElement')
  inputElement: ElementRef;

  @Output('fileUpdate')
  fileChange: EventEmitter<File> = new EventEmitter();

  _uploadedFile: FileStoreModel;

  @Input("file")
  set uploadedFile(f: FileStoreModel) {
    this.previewImgData = null;
    this._uploadedFile = f;

    if (f == null) {
      (this.inputElement.nativeElement).value = "";
    }
  }
  get fileInputElement(): ElementRef {
    return this.inputElement;
  }
  get uploadedFile(): FileStoreModel {
    return this._uploadedFile;
  }

  @Input("buttonTitle")
  buttonTitle: string = "Import file";

  @Input("accept")
  accept: string = "*";

  @Input("height")
  height: string = "inherit";
  @Input("width")
  width: string = "100%";
  @Input("maxHeight")
  maxHeight: string = "inherit";
  @Input("maxWidth")
  maxWidth: string = "inherit";

  @Input("allowRemove")
  allowRemove: boolean = true;

  @Input("buttonOnlyMode")
  buttonOnlyMode: boolean = false;

  @Input()
  set disabled(disabled: boolean) {
    this.enable = !disabled;
  }

  // ControlValueAccessor variables
  cvaOnChange: (_: any) => any;
  cvaOnTouched: any;
  enable: boolean = true;
  check: boolean = true;
  ///////// ControlValueAccessor variables //////////

  get imageUrl(): string {
    if (this.uploadedFile != null) {
      if (this.check) {
        this.check = false;
      }

      if (this.uploadedFile.fileType != null && this.uploadedFile.fileType.startsWith("image/")) {
        return this.uploadedFile.downloadLink;
      }

      return null;
    }
    else {
      // return 'img/placeholder.png';
      return null;
    }
  }

  get fileNameUploaded(): string {
    if (this.uploadedFile != null) {
      if (this.uploadedFile.fileName != null) {
        return this.uploadedFile.fileName;
      }
      else {
        return this.uploadedFile.title;
      }
    }
  }

  get fileNameTransient(): string {
    return this.transientFileName != null ? this.transientFileName : null;
  }

  transientFileName: string = null; // It is a file user selecting. This is not yet uploaded to server. (Once uploaded, this field is clear.)
  previewImgData: any = null;

  constructor() { }

  ngOnInit() {
  }

  async browse(event: Event) {
    try {
      // This code works on Mobile Safari but not Chrome + Desktop Safari
      this.inputElement.nativeElement.dispatchEvent(event);
    }
    catch (_) {
      // This code works on Desktop Safari + Chrome but not on Mobile Safari
      // This code does not emit any error when using on Mobile Safari. It will just not working.
      //  thus, use it in catch.
      this.inputElement.nativeElement.click();
    }

    event.preventDefault();
  }

  // This should be called back after FileUploadComponent emit fileChange event by the user's of this component.
  upload(httpClient: HttpClient, protectedMiscControllerService: ProtectedMiscControllerService, fileId: number, uploadLinkUrl: string,
         onComplete: () => void = null, onFail: () => void = null) {
    // First, get file to upload
    var ie = <HTMLInputElement>this.inputElement.nativeElement;

    // Check if there is anything to upload
    if (ie.files.length == 0) {
      return;
    }

    let uploadingFile = ie.files[0];
    if (uploadingFile.size > 15 * 1024 * 1024) {
      this.transientFileName = null;
      Utils.error("File is too large and cannot be uploaded.");
      if (onFail != null) {
        onFail();
      }
      return;
    }

    // Perform upload
    httpClient.put(uploadLinkUrl, uploadingFile).subscribe(async (body) => {
      this._uploadedFile = await this.completeUpload(protectedMiscControllerService, fileId, uploadingFile.name);

      if (onComplete != null) {
        onComplete();
      }

      if (this.cvaOnChange != null) {
        this.cvaOnChange(this._uploadedFile);
      }

      if (this.cvaOnTouched != null) {
        this.cvaOnTouched();
      }
    });
  }

  uploadPublic(httpClient: HttpClient, publicMiscControllerService: PublicMiscControllerService, fileId: number, uploadLinkUrl: string,
         onComplete: () => void = null, onFail: () => void = null) {
    // First, get file to upload
    var ie = <HTMLInputElement>this.inputElement.nativeElement;

    // Check if there is anything to upload
    if (ie.files.length == 0) {
      return;
    }

    let uploadingFile = ie.files[0];
    if (uploadingFile.size > 15 * 1024 * 1024) {
      this.transientFileName = null;
      Utils.error("File is too large and cannot be uploaded.");
      if (onFail != null) {
        onFail();
      }
      return;
    }

    // Perform upload
    httpClient.put(uploadLinkUrl, uploadingFile).subscribe(async (body) => {
      this._uploadedFile = await this.completeUploadPublic(publicMiscControllerService, fileId, uploadingFile.name);

      if (onComplete != null) {
        onComplete();
      }

      if (this.cvaOnChange != null) {
        this.cvaOnChange(this._uploadedFile);
      }

      if (this.cvaOnTouched != null) {
        this.cvaOnTouched();
      }
    });
  }


  uploadWithFile(uploadingFile:File,httpClient: HttpClient, protMiscService: ProtectedMiscControllerService, fileId: number, uploadLinkUrl: string,
         onComplete: () => void = null, onFail: () => void = null) {

    // let uploadingFile = ie.files[0];
    if (uploadingFile.size > 15 * 1024 * 1024) {
      this.transientFileName = null;
      Utils.error("File is too large and cannot be uploaded.");
      if (onFail != null) {
        onFail();
      }
      return;
    }

    // Perform upload
    httpClient.put(uploadLinkUrl, uploadingFile).subscribe(async (body) => {
      this._uploadedFile = await this.completeUpload(protMiscService, fileId, uploadingFile.name);

      if (onComplete != null) {
        onComplete();
      }

      if (this.cvaOnChange != null) {
        this.cvaOnChange(this._uploadedFile);
      }

      if (this.cvaOnTouched != null) {
        this.cvaOnTouched();
      }
    });
  }

  async completeUpload(protMiscService: ProtectedMiscControllerService, fileId: number, fileName: string): Promise<FileStoreModel> {
    return WSCall.wsCall(() => {
      return protMiscService.completeUploadUsingPOST(fileId, { fileTitle: fileName, fileName: fileName })
    }, (respBody) => {
      return (respBody.data);
    });
  }

  async completeUploadPublic(publicMiscControllerService: PublicMiscControllerService, fileId: number, fileName: string): Promise<FileStoreModel> {
    return WSCall.wsCall(() => {
      return publicMiscControllerService.completeUploadUsingPOST1(fileId, { fileTitle: fileName, fileName: fileName })
    }, (respBody) => {
      return (respBody.data);
    });
  }

  onFileInputChange() {
    var ieFile: File = (this.inputElement.nativeElement).files[0];
    this._replaceFileWith(ieFile);
  }

  _replaceFileWith(ieFile: File) {
    if (ieFile != null) {
      this.transientFileName = ieFile.name;

      // Render preview
      this.renderPreview(ieFile);

      if (this.fileChange != null) {
        // Expect user to handle fileChange and make a call to upload() once ready (with upload link).
        this.fileChange.emit(ieFile);
      }
    }
    else {
      if (this.fileChange != null) {
        // if (this.cvaOnChange != null) {
        //   this.cvaOnChange(null);
        // }
        if (this.cvaOnTouched != null) {
          this.cvaOnTouched();
        }
      }
      else {
        this.fileChange.emit(null);
      }
    }
  }

  removeItem(event: Event) {
    this.uploadedFile = null;
    this.transientFileName = null;

    if (this.fileChange != null) {
      if (this.cvaOnChange != null) {
        this.cvaOnChange(null);
      }
      if (this.cvaOnTouched != null) {
        this.cvaOnTouched();
      }
      this.fileChange.emit(null);
    }
  }

  reset() {
    let didChange = (this.uploadedFile != null);

    this.uploadedFile = null;
    this.transientFileName = null;

    if (didChange && this.fileChange != null) {
      if (this.cvaOnChange != null) {
        this.cvaOnChange(null);
      }
      if (this.cvaOnTouched != null) {
        this.cvaOnTouched();
      }
      this.fileChange.emit(null);
    }
  }

  renderPreview(uploadFile: File) {
    // Only provide preview if it is of image type
    if (uploadFile.type.startsWith("image/")) {
      let reader = new FileReader();
      reader.addEventListener('load', (e) => {
        // Read data from reader and make a multipart request
        this.previewImgData = reader.result;
      })

      // Begin reading file (which triggers onLoad.listen closure above)
      reader.readAsDataURL(uploadFile);
    }
    else {
      this.previewImgData = null;
    }
  }

  ///////////////////
  // ControlValueAccessor
  ///////////////////

  writeValue(obj: any): void {
    this.reset();
    this.uploadedFile = obj;
  }

  registerOnChange(fn: any): void {
    this.cvaOnChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.cvaOnTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.enable = !isDisabled;
  }

}
