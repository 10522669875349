import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IcProfileModel } from '@proflink/prof-link-web-ic-api-ts-angular/model/icProfileModel';
import { ProblemTopicModel } from '@proflink/prof-link-web-ic-api-ts-angular/model/problemTopicModel';
import { fixCORSUrl } from '@app/utils/Utils';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FileUploadComponent } from '@public/file-upload/file-upload.component';
import { HttpClient } from '@angular/common/http';
import { FileStoreModel } from '@proflink/prof-link-web-ic-api-ts-angular/model/fileStoreModel';
import { CreateContactUsModel } from '@proflink/prof-link-web-ic-api-ts-angular/model/createContactUsModel';
import { PublicContactUsControllerService } from '@proflink/prof-link-web-ic-api-ts-angular/api/publicContactUsController.service';
import * as WSCall from '@app/utils/WSCall';
import { APIResultstring } from '@proflink/prof-link-web-ic-api-ts-angular/model/aPIResultstring';
import * as Utils from '@app/utils/Utils';
import { ContactUsControllerService } from '@proflink/prof-link-web-ic-api-ts-angular/api/contactUsController.service';
import { PublicMiscControllerService } from '@proflink/prof-link-web-ic-api-ts-angular';

@Component({
  selector: 'app-report-problem-form',
  templateUrl: './report-problem-form.component.html',
  styleUrls: ['./report-problem-form.component.scss']
})
export class ReportProblemFormComponent implements OnInit {

  constructor(
    private fb: FormBuilder,
    private ngxService: NgxUiLoaderService,
    protected _httpClient: HttpClient,
    private _publicMiscControllerService: PublicMiscControllerService,
    private _contactUsControllerService: ContactUsControllerService,
    private _publicContactUsControllerService: PublicContactUsControllerService
  ) { }

  @ViewChild('attachmentDocument')
  attachmentDocument: FileUploadComponent;

  public reportProblemForm: FormGroup;

  canSubmit: boolean = true;

  files: FileStoreModel[] = [];

  problemTopicModel: ProblemTopicModel = null;

  icProfile: IcProfileModel = null;

  @Output('submitCompleted')
  submitCompleted: EventEmitter<Boolean> = new EventEmitter();


  @Input("problemTopicModel")
  set setProblemTopicModel(problemTopicModel: ProblemTopicModel) {
    if (problemTopicModel != null) {
      this.problemTopicModel = problemTopicModel;
    }
  }

  @Input("icProfile")
  set setIcProfile(icProfile: IcProfileModel) {
    if (icProfile != null) {
      this.icProfile = icProfile;
    }
  }

  ngOnInit() {
    this.createForm();

  }

  createForm() {
    this.reportProblemForm = this.fb.group({
      topicName: [this.problemTopicModel.topicName, Validators.required],
      fullName: [null, Validators.required],
      email: [null, Validators.required],
      message: [null, Validators.required],

    });

    this.reportProblemForm.valueChanges.subscribe(() => {
      this.canSubmit = this.reportProblemForm.valid;
    });

    if (this.icProfile != null) {
      console.log(this.icProfile)

      let fullName = this.getFullName(this.icProfile);
      let email = this.icProfile.email;
      if (email != null) {
        this.reportProblemForm.controls.email.setValue(email);
      }
      this.reportProblemForm.controls.fullName.setValue(fullName);
    }
  }

  getFullName(icProfile: IcProfileModel) {

    if (icProfile == null) {
      return '';
    }
    let firstName = this.icProfile.firstnameTh != null ? this.icProfile.firstnameTh : this.icProfile.firstnameEn;
    let lastName = this.icProfile.lastnameTh != null ? this.icProfile.lastnameTh : this.icProfile.lastnameEn;
    let middleName = this.icProfile.middlenameTh != null ? this.icProfile.middlenameTh : this.icProfile.middlenameEn;

    let fullName = firstName;
    if (middleName != null || middleName != '') {
      fullName = fullName + " " + middleName;
    }
    if (lastName != null || lastName != '') {
      fullName = fullName + " " + lastName;
    }

    return fullName;

  }

  async submitData() {
    
    if (this.reportProblemForm.valid && this.canSubmit) {
      let tempFileId: number[] = [];
      this.files.forEach(x => {
        tempFileId.push(x.fileId);
      });

      let fullName = this.reportProblemForm.value.fullName;
      let fullNameSplit: string[] = fullName.split(" ");
      let firstName = fullNameSplit[0];
      let lastName = '';
      let middleName = '';
      if (fullNameSplit.length >= 3) {
        middleName = fullNameSplit[1];
        lastName = fullNameSplit[2];
      } else if (fullNameSplit.length >= 2) {
        lastName = fullNameSplit[1];
      }

      let createContactUsModel: CreateContactUsModel = {
        problemTopicId: this.problemTopicModel.problemTopicId,
        firstname: firstName,
        lastname: lastName,
        middlename: middleName,
        email: this.reportProblemForm.value.email,
        problemDetail: this.reportProblemForm.value.message,
        reportProblemFiles: tempFileId
      }

      let _this = this;

      let result: APIResultstring = null;

      if (this.icProfile != null) {
        result = await this.addReportProblemWithIcProfile(createContactUsModel);
      } else {
        result = await this.addReportProblem(createContactUsModel);
      }

      if (result.resultCode == 'SUCCESS') {
        Utils.infoWithCallBack("ส่งข้อมูลสำเร็จ", "Info", function () {
          _this.submitCompleted.emit(true);
        });
      } else {
        Utils.error("ส่งข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง");
      }
    } else {
      this.reportProblemForm.markAllAsTouched();
      Utils.error("กรอกข้อมูลไม่ครบถ้วน กรุณาตรวจสอบอีกครั้ง");
    }
  }

  onBackgroundImageChange(file: File): void {
    if (file != null) {
      this.ngxService.start();
      this._publicContactUsControllerService.getUploadLinkForContactUsUsingGET1().subscribe(res => {
        this.attachmentDocument.uploadPublic(this._httpClient, this._publicMiscControllerService, res.data.fileId,
          fixCORSUrl(res.data.uploadLink), () => {
            this.ngxService.stop();
            this.files.push(res.data);
          }, () => {
            this.ngxService.stop();
          });
      });
    }
  }

  upload() {
    this.attachmentDocument.fileInputElement.nativeElement.click();
  }

  removeFile(file: FileStoreModel) {

    let index: number = this.files.indexOf(file);
    if (index < 0) {
      return;
    }
    this.files.splice(index, 1);
  }


  async addReportProblem(createContactUsModel: CreateContactUsModel): Promise<APIResultstring> {
    return WSCall.wsCall(() => {
      return this._publicContactUsControllerService.addReportProblemUsingPOST1(createContactUsModel);
    }, (respBody) => {
      return respBody;
    });
  }

  async addReportProblemWithIcProfile(createContactUsModel: CreateContactUsModel): Promise<APIResultstring> {
    return WSCall.wsCall(() => {
      return this._contactUsControllerService.addReportProblemUsingPOST(createContactUsModel);
    }, (respBody) => {
      return respBody;
    });
  }

}
