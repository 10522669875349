<div>
  <div>
    <label class="text-title">เลขที่ทะเบียนใบอนุญาตฯ</label>
    <p>{{ profile.icCode }}</p>
  </div>
  <div>
    <label class="text-title">วันที่หมดอายุ</label>
    <p>{{ expireDate | dateThai }}</p>
  </div>
  <div>
    <label class="text-title">ใบอนุญาตที่ได้รับความเห็นชอบในปัจจุบัน </label>
    <ul *ngIf="currentLicense.length > 0">
      <li *ngFor="let item of currentLicense">
        {{
          item.licensePrivilegeType != null && item.licensePrivilegeType != ""
            ? item.licenseNameTh +
              " - ใช้สิทธิ์ " +
              item.licensePrivilegeType +
              " "
            : item.licenseNameTh
        }}
        ({{ item.effectiveDate | dateThai }} -
        {{
          item.expiredDate != null
            ? (item.expiredDate | dateThai)
            : " ปัจจุบัน"
        }})
      </li>
    </ul>
    <ul *ngIf="currentLicense.length == 0">
      -
    </ul>
  </div>
  <div>
    ประวัติใบอนุญาตที่เคยได้รับความเห็นชอบ
    <a
      class="text-title"
      style="cursor: pointer"
      (click)="isLicenseCollapsed = !isLicenseCollapsed"
      [attr.aria-expanded]="isLicenseCollapsed"
      aria-controls="collapseExample"
    >
    {{ isLicenseCollapsed ? "ซ่อน" : "แสดง" }}
    </a>
    <div #collapse="ngbCollapse" [(ngbCollapse)]="!isLicenseCollapsed">
      <ul *ngIf="historyLicense.length > 0">
        <li *ngFor="let item of historyLicense">
          {{ item.effectiveDate | dateThai }} -
          {{ item.expiredDate | dateThai }} :
          {{
            item.licensePrivilegeType != null && item.licensePrivilegeType != ""
              ? item.licenseNameTh +
                " - ใช้สิทธิ์ " +
                item.licensePrivilegeType +
                " "
              : item.licenseNameTh
          }}
        </li>
      </ul>
      <ul *ngIf="historyLicense.length == 0">
        -
      </ul>
    </div>
  </div>
  <div>
    <label class="text-title">การปฏิบัติหน้าที่ในปัจจุบัน</label>
    <ul *ngIf="currentWorking.length > 0">
      <li *ngFor="let item of currentWorking">
        {{ item.secRoleNameTh }} ({{ item.startDate | dateThai }} -
        {{
          item.endDate != null ? (item.endDate | dateThai) : "ยังปฏิบัติหน้าที่"
        }}
        )
      </li>
    </ul>
    <ul *ngIf="currentWorking.length == 0">
      -
    </ul>
  </div>
  <div>
    ประวัติการปฏิบัติหน้าที่
    <a
      class="text-title"
      style="cursor: pointer"
      (click)="isWorkingCollapsed = !isWorkingCollapsed"
      [attr.aria-expanded]="isWorkingCollapsed"
      aria-controls="collapseExample"
    >
      {{ isWorkingCollapsed ? "ซ่อน" : "แสดง" }}
    </a>
    <div
      id="col1"
      #collapse="ngbCollapse"
      [(ngbCollapse)]="!isWorkingCollapsed"
    >
      <ul *ngIf="historyWorking.length > 0">
        <li *ngFor="let item of historyWorking">
          {{ item.startDate | dateThai }} - {{ item.endDate | dateThai }} :
          {{ item.secRoleNameTh }} - {{ item.companyNameTh }}
        </li>
      </ul>
      <ul *ngIf="historyWorking.length == 0">
       -
      </ul>
    </div>
  </div>
</div>
