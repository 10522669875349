<div class="box-container">
    <div class="box">
        <div class="pl-2 m-2 flex-fill text-left d-lg-none">
            <span class="title-headline-box">คอร์สของฉัน</span>
        </div>
        <div *ngIf="sessions.length > 0">
            <div *ngFor="let session of sessions">
                <a class="pt-sm-3 px-sm-2 pb-sm-2 row no-gutters session-card" (click)="onSelectSession(session)">
                    <div class="col-4 col-xl-3 d-flex justify-content-center align-self-center">
                        <img *ngIf="session.sessionType == sessionTypeEnum.PROFLINK"
                            src="../../../assets/image/icon_prof_link.png" class="rounded cursor-pointer img-size"
                            alt="icon-prof-link">
                        <img *ngIf="session.sessionType == sessionTypeEnum.NONPROFLINK"
                            src="../../../assets/image/icon_non_prof_link_new.png" class="rounded img-size"
                            alt="icon-prof-link">
                    </div>
                    <div class="col-8 col-xl-9 p-2"
                        [ngClass]="{'cursor-pointer': session.sessionType == sessionTypeEnum.PROFLINK}">
                        <div class="title-topic text-truncate">{{session.sessionName}}</div>
                        <div class="text-truncate"><i class="pi pi-clock primng-icon-size"></i> <span
                                class="pl-1">{{displayDate(session)}}</span>
                        </div>
                        <div class="text-truncate"><i class="pi pi-map-marker primng-icon-size"></i> <span
                                class="pl-1">{{session.trainingLocationName || "-"}}</span></div>
                        <div class="text-truncate"><i class="pi pi-building primng-icon-size"></i> <span
                                class="pl-1">{{session.trainingProviderName
                                || "-"}}</span></div>
                        <small *ngIf="session.holdResult" class="bg-danger status-label">ระงับผลการอบรม
                            กรุณาส่งเอกสารยืนยันตัวตน</small>
                        <small *ngIf="session.sessionStatus == '6L'"
                            class="bg-secondary status-label">ยกเลิกการอบรม</small>
                    </div>
                </a>
            </div>
        </div>
        <div class="mt-3" *ngIf="sessions.length == 0">
            <div class="card text-center mx-3">
                <div class="card-body">
                    คุณไม่มีหลักสูตรที่ลงทะเบียนไว้
                </div>
            </div>
        </div>
    </div>
</div>