<div *ngIf="profile" class="wrapper col-sm-12 p-0 m-0" [@flyInAnimation]="'in'">
    <app-header-with-back class="header header-green" [title]="'ประวัติส่วนตัว'" path="home"
        *ngIf="!edit"></app-header-with-back>
    <div class="header header-green">
        <ul class="navigation nav navbar-light bg-light " *ngIf="edit" (click)="editSuccess(false)">
            <li class="nav-item">
                <div class="nav-link">
                    <i class="fas fa-chevron-left icon-header"></i>
                </div>
            </li>
            <li class="nav-item">
                <div class="nav-link">
                    <p class="m-0 title-header">เปลี่ยนแปลงข้อมูล</p>
                </div>
            </li>
            <li class="nav-item">
                <div class="nav-link"></div>
            </li>
        </ul>
    </div>

    <div class="box-container">
        <div class="box">
            <div *ngIf="!edit">
                <ul ngbNav #nav="ngbNav" [activeId]="activeId" class="nav-tabs nav-pills nav-justified">
                    <li [ngbNavItem]="1" (click)="onChangeTab(1)">
                        <a ngbNavLink class="text-small-responsive">ประวัติส่วนตัว</a>
                        <ng-template ngbNavContent>
                            <app-profile-information [profile]="profile"
                                (edit)="editForm($event)"></app-profile-information>
                        </ng-template>
                    </li>

                    <li [ngbNavItem]="2" (click)="onChangeTab(2)">
                        <a ngbNavLink class="text-small-responsive">ใบอนุญาตฯ และการปฏิบัติหน้าที่</a>
                        <ng-template ngbNavContent>
                            <app-profile-license [profile]="profile" [companyStaff]="companyStaff"
                                [companyAgent]="companyAgent" [secRoleStaff]="secRoleStaff"
                                [secRoleAgent]="secRoleAgent"></app-profile-license>
                        </ng-template>
                    </li>

                    <li [ngbNavItem]="3" (click)="onChangeTab(3)">
                        <a ngbNavLink class="text-small-responsive">สถานะการทำงาน</a>
                        <ng-template ngbNavContent>
                            <app-profile-company [profile]="profile" [companyStaff]="companyStaff"
                                [companyAgent]="companyAgent" [secRoleStaff]="secRoleStaff"
                                [secRoleAgent]="secRoleAgent" (edit)="editForm($event)"></app-profile-company>
                        </ng-template>
                    </li>

                    <li [ngbNavItem]="4" (click)="onChangeTab(4)">
                        <a ngbNavLink class="text-small-responsive">ข้อมูลการติดต่อ</a>
                        <ng-template ngbNavContent>
                            <app-profile-contact [profile]="profile" (edit)="editForm($event)"></app-profile-contact>
                        </ng-template>
                    </li>

                </ul>
                <div class="container">
                    <div [ngbNavOutlet]="nav" class="mt-2"></div>
                    <div class="card-footer text-muted remark-text">
                        หมายเหตุ - ข้อมูลที่ปรากฎนี้นำเสนอตามสภาพที่ได้รับ (as is basis)
                        และอาจไม่เป็นปัจจุบันจนกว่าจะมีการปรับปรุงให้เป็นปัจจุบันโดยหน่วยงานที่เกี่ยวข้อง
                    </div>
                </div>
            </div>
            <div class="container pt-3" *ngIf="edit">
                <app-profile-information-form *ngIf="edit === 'information'" [profile]="profile"
                    (editSuccess)="editSuccess($event)"></app-profile-information-form>
                <app-profile-company-form *ngIf="edit === 'company'"
                                          [profile]="profile"
                                          [companyStaff]="companyStaff"
                                          [companyAgent]="companyAgent"
                    (editSuccess)="requestMoveCompanySuccess($event)"></app-profile-company-form>
                <app-profile-contact-form *ngIf="edit === 'contact'" [profile]="profile" [callbackForm]="contactForm"
                    (verifyOTP)="verifyOTP($event)" (editSuccess)="editSuccess($event)"></app-profile-contact-form>
                <app-otp-share *ngIf="edit === 'requestOTP' && resultOTPModel"
                    (successVerifyOtp)="onVerifyOTPSuccess($event)" [resultOTP]="resultOTPModel"></app-otp-share>
            </div>
        </div>
    </div>

</div>
