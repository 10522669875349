import {Component, OnInit} from '@angular/core';
import {AppState} from "@core/app-state";
import {ApplicationConfigControllerService} from "@proflink/prof-link-web-ic-api-ts-angular/api/applicationConfigController.service";
import {ApplicationConfigModel} from "@proflink/prof-link-web-ic-api-ts-angular/model/applicationConfigModel";
import * as WSCall from "@app/utils/WSCall";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent{
  title = 'prof-link-web-ic';


}
